import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Textblock: Card Right and Left',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Card-Right-and-Left-b651fb0f5a8e42cfba80acc3855ee2b5?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock-IID-26.gif'
    },
    allowedSections: ['textblockcolumns2'],
    notion_IID: '26',
    css: `
    /* Textblock: Card Right and Left */
section.color-scheme-10-background-color .section-content,
section.color-scheme-11-background-color .section-content {
    background-color: #fff;
    z-index: 1;
    position: relative;
    max-width: 865px;
    width: 60%;
    padding: 6rem 6rem 6rem 10.5rem;
    background-image: url(https://s3.amazonaws.com/eii-lucid/assets/7309/342397/original.svg?1612376966);
    background-repeat: repeat-y;
    background-position-x: -20px;
    margin-left: 38%;
    box-shadow: 0px 10px 20px 0px #42424230;

}
@media (min-width:1024px) {
section.color-scheme-10-background-color .section-container,
section.color-scheme-11-background-color .section-container {
    max-width: 1500px;
    padding-right: 2.2rem;
}
}
section.color-scheme-11-background-color .section-content {
    margin-right: 35%;
    margin-left: auto;
}

section.color-scheme-10 .section-custom-background.show-for-mobile, section.color-scheme-11 .section-custom-background.show-for-mobile {
        max-height: 260px;
}

@media (min-width:1024px) {
section.color-scheme-10 .section-custom-background, section.color-scheme-11 .section-custom-background {
max-width: 50%;
}
section.color-scheme-11 .section-custom-background {
left: 50%;
}
}
section.color-scheme-10 .column,
section.color-scheme-11 .column {
    max-width: 100%;
    flex: 0 0 100%;
}
section.color-scheme-10-background-color.back-stack,
section.color-scheme-11-background-color.back-stack {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

section.color-scheme-10, section.color-scheme-11 {
    overflow: hidden;
}

@media (max-width: 1024px) {
section.color-scheme-10-background-color .section-content,
section.color-scheme-11-background-color .section-content {
    width: 100%;
    margin-left: auto;
}

section.color-scheme-11-background-color .section-content {
    margin-right: auto;
}

section.color-scheme-10-background-color .section-content,
section.color-scheme-11-background-color .section-content {
    width: 100%;
    background-image: none;
    background-position-x: 0;
    margin-left: auto;
background-repeat: repeat-y;
}
section.color-scheme-11-background-color .section-content  {
        margin-right: auto;
}

section.color-scheme-10-background-color.back-stack,
section.color-scheme-11-background-color.back-stack {
padding: 11rem 2rem 2rem 2rem;
}
}

@media (max-width: 700px) {

section.color-scheme-10-background-color .section-content,
section.color-scheme-11-background-color .section-content {
    padding: 2rem;
}
section.color-scheme-10-background-color.back-stack,
section.color-scheme-11-background-color.back-stack {
padding: 11rem 1rem 1rem 1rem;
}
}

    `
  },
]
