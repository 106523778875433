import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import React from 'react'
import RSSReader from '../shared/RSSReader'
import BlogCardItem from '../BlogCardItem'
import useSWR from 'swr'
import {styleCore} from './stylecore'

export default function BlogCard(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    feed,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms
  } = props
  
  const {data: items, error} = useSWR([feed, 3], RSSReader)
  const isLoading = !error && !items

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section blogcard-section stack full-width menu-section'}
      name="blogcard"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <div className="title heading-1">
                <SectionText name='title' text={title} cms={cms} />
              </div>
              <div className="subtitle heading-2">
                <SectionText name='subtitle' text={subtitle} cms={cms} />
              </div>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext">
                  <RichtextWithVariables
                    name='intro'
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row menus blog-posts blog-card-items section-richtext paragraph">
            {isLoading && (
              <div className="menu column">
                <div className="blog-post">
                  <span className="blog-post-title">Loading...</span>
                </div>
              </div>
            )}
            {error && (
              <div className="menu column">
                <div className="blog-post">
                  <span className="blog-post-title paragraph">Failed to load posts.</span>
              </div>
            </div>
            )}
            {items && items.length === 0 && (
              <div className="menu column">
                <div className="blog-post">
                  <span className="blog-post-title">No posts from source.</span>
                </div>
              </div>
            )}
            {items && items.length > 0 && (
              items.map((item) => (
                <BlogCardItem
                  key={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  description={item.description}
                  url={item.url || item.link}
                />
              ))
            )}
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables
                  name='outro'
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          {button?.url && button?.label && (
            <div className="button-container">
              <SectionButton
                name='button'
                href={button?.url}
                label={button?.label}
                cms={cms}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

BlogCard.StyleCore = styleCore
