import {BaseTextField, Select} from '@einsteinindustries/tinacms-fields'
import {InputLabel, InputLabelSuggestion} from './styles'
import {useEffect} from 'react'

function PageRouteForm(props) {
  const {pages, pageId, path, onPageChange, onPathChange, setAllowNext, handleFormChange} =
    props

  useEffect(() => {
    return () => {
      setAllowNext(true)
    }
  }, [setAllowNext])

  const getProposedRoute = (page) => {
    const name = page.name.toLowerCase()
    if (!page) {
      return null
    }

    if (page.proposed_route) {
      return page.proposed_route
    }

    if (name === 'home' || name === 'homepage') {
      return '/'
    }

    return (
      '/' +
      page.name
        .toLowerCase()
        // replace spaces with hyphens
        .replace(/\s+/g, '-')
    )
  }

  const proposedRoute = getProposedRoute(
    pages.find(({id}) => id === pageId)
  )

  const pageOptions = pages.filter(p => (p.route === null)).map(page => {
    return {
      label: page.name,
      value: page.id,
    }
  })
  if (pageOptions.length === 0) {
    setAllowNext(false)
  }

  return (
    <div>
      <h5>Route Config</h5>
      <InputLabel>Page</InputLabel>
      <Select
        input={{
          disabled: pageOptions.length === 0,
          name: 'pageId',
          className: 'route-manager-dialog-field',
          value: pageId,
          onChange: handleFormChange,
        }}
        options={pageOptions.length === 0 ? [{label: 'All pages have a main route'}] : pageOptions}
      />
      <InputLabel>
        Path
        {proposedRoute ? (
          <InputLabelSuggestion>
            Suggested Path: <code>{proposedRoute}</code>
          </InputLabelSuggestion>
        ) : null}
      </InputLabel>
      <BaseTextField
        disabled={pageOptions.length === 0}
        className="route-manager-dialog-field"
        type="text"
        name="url"
        placeholder="/my-new-route"
        value={path}
        onChange={handleFormChange}
      />
    </div>
  )
}

export default PageRouteForm
