import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Office Tour: Taller and filled in button',
      documentationURL: 'https://www.notion.so/einsteinindustries/Office-Tour-Taller-and-filled-in-button-49055e7a0cd54f16a9263da61d4e7b5d?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/office_tour-IID-156.png'
    },
    notion_IID: '156',
    allowedSections: [],
    css: `
    .layout .office-tour-section .media svg {
    border-radius: 10px;
background-color: #93b2c7;
box-shadow: 0px 8px 22px #00000059;
}

@media (min-width:1024px) {
 .office-tour-section .iframe-wrapper {
border-radius: 0 0 0 300px;
overflow: hidden;
}
}
.layout .office-tour-section .iframe-wrapper::after {

  background-color: rgba(0, 0, 0, 0);
}
.layout .office-tour-section iframe {
  width: 100%;
  min-height: 65vh;

}

.layout .office-tour-section .iframe-wrapper .office-tour-poster {
  min-height: 65vh;
}

    `
  },
  {
    display: {
      name: 'Office Tour: taller height',
      documentationURL: 'https://www.notion.so/einsteinindustries/Office-Tour-taller-height-911f0e2ed9d44686aecd8509c394dddc?pvs=4'
    },
    notion_IID: '142',
    allowedSections: [],
    css: `
    .layout .office-tour-section iframe {
  width: 100%;
  min-height: 65vh;

}
.layout .office-tour-section .iframe-wrapper .office-tour-poster {
  min-height: 65vh;
}
    `
  }
]
