import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Menu Photo Card: 3 items per row',
      documentationURL: 'https://www.notion.so/einsteinindustries/Menu-Photo-Card-3-items-per-row-0a82634ae61c41da82fab7c6915433f8?pvs=4'
    },
    allowedSections: [],
    notion_IID: '136',
    css: `
    @media (min-width:1024px) {
.menu-photo-card-section.color-scheme-6 .menus > .menu {
  flex: 0 0 calc(33% - calc(1rem * 2)) !important;
  max-width: calc(33% - calc(1rem * 2))!important;
}
}
    `
  }
]
