import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import {styleCore} from './stylecore'

export default function TextblockImageCollageB(props) {
  const {
    title,
    subtitle,
    intro,
    textblock_title,
    textblock_content,
    image,
    phrase,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className + ' section textblock-image-collage-b-section back-stack full-width custom-max-width'}
      name="textblockimagecollageb"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <div className="section-container textblock-image-collage-section-container">
        <div className="section-content right-text">
          <div className="section-heading">
            <h2>
              <span className="title heading-1">
                <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
              </span>
              <span className="subtitle heading-2">
                <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
              </span>
            </h2></div>
          <p className="section-richtext section-description paragraph"><RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/> </p>
          <div className="row">
            <div className="column images-wrapper">
              <div className="image">
                <SectionImage
                  name="image"
                  src={image?.src}
                  alt={image?.alt}
                  width={image?.width}
                  height={image?.height}
                  priority={image?.priority}
                  sizes={image?.sizes}
                  cms={cms}
                />
              </div>
            </div>
            <div className="column textblock-wrapper section-richtext paragraph">
              <h3 className="title heading-1"><RichtextWithVariables richtext={textblock_title} cms={cms} name={'textblock_title'}/></h3>
              <div><RichtextWithVariables richtext={textblock_content} cms={cms} name={'textblock_content'}/></div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

TextblockImageCollageB.StyleCore = styleCore






