//@ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {PagesArrayWithHeader} from '../../../../components/managers/PageManager/components/newWizards/NewProjectWizard'
import WizardLayout from '../../../../components/shared/WizardLayout'
import {Page, Route} from '../../../../components/shared/types'
import {
  alreadyPublishedPages,
  getAlreadyPublishedPages,
  getNewPublishPages,
  getSuggestedRedirects,
  redirectDict,
  RedirectPage,
} from '../../../../components/managers/PageManager/components/PublishPagesWizard'
import StyledTable from '@/src/components/shared/StyledTable'

interface PublishProjectWizardProps {
  showDialog: boolean
  closeDialog: () => void
  onSave: () => void
  pages: Page[]
  routes: Route[]
}

const PublishProjectWizard = ({
  pages,
  routes,
  ...other
}: PublishProjectWizardProps) => {
  const [selectedPages, setSelectedPages] = useState(
    pages
      .filter((p) => 'published' !== p.stage)
      .reduce<string[]>((acc, curr) => [...acc, curr.id], [])
  )
  const pagesFromSelected = useMemo(
    () => pages.filter((p) => selectedPages.includes(p.id)),
    [pages, selectedPages]
  )
  const [newPublishPages, setNewPublishPages] = useState(
    getNewPublishPages(pagesFromSelected, pages)
  )
  const [alreadyPublishedPages, setAlreadyPublishedPages] =
    useState<alreadyPublishedPages>(
      getAlreadyPublishedPages(routes, newPublishPages, pages)
    )
  // const [currentPage, setCurrentPage] = useState(1)
  const [redirects, setRedirects] = useState<redirectDict[]>(
    getSuggestedRedirects(
      pagesFromSelected,
      routes,
      newPublishPages,
      alreadyPublishedPages
    )
  )

  useEffect(() => {
    setNewPublishPages(getNewPublishPages(pagesFromSelected, pages))
  }, [selectedPages, pages, setNewPublishPages, pagesFromSelected])

  // we want all the current routes minus the routes from the pages to be archived
  const selectOptions = useMemo(
    () =>
      Array.from(
        new Set(
          [
            ...routes.reduce<string[]>((acc, curr) => [...acc, curr.url], []),
            ...Object.values(newPublishPages).reduce<string[]>(
              (acc, curr) => [...acc, curr.route],
              []
            ),
          ].filter((route) =>
            redirects.some((redirect) => redirect.route.url !== route)
          )
        )
      ),
    [routes, newPublishPages, redirects]
  )

  const handleChangePublishedPages = useCallback(
    (id: string, input: 'route' | 'label', value: string) => {
      const newPagesRouteDict = {...newPublishPages}
      newPagesRouteDict[id][input] = value
      setNewPublishPages({...newPagesRouteDict})
      setAlreadyPublishedPages(
        getAlreadyPublishedPages(routes, newPublishPages, pages)
      )
      setRedirects(
        getSuggestedRedirects(
          pagesFromSelected,
          routes,
          newPublishPages,
          alreadyPublishedPages
        )
      )
    },
    [newPublishPages, routes, pages, pagesFromSelected] // eslint-disable-line
  )

  return (
    <WizardLayout
      {...other}
      isNextAllowed={!!selectedPages.length}
      title="Publish Project"
    >
      <div>
        <PagesArrayWithHeader
          pages={pages.filter((p) => 'published' !== p.stage)}
          dispatchSelectedPages={setSelectedPages}
          initSelectedPages={selectedPages}
        />
      </div>
      <StyledTable>
        <thead>
          <tr>
            <td>Name</td>
            <td>Route</td>
            <td>Navigation Label</td>
          </tr>
        </thead>
        <tbody>
          {pagesFromSelected.map((page) => {
            return newPublishPages[page.id] ? (
              <tr key={page.id}>
                <td>
                  <span>{page.name}</span>
                </td>
                <td>
                  <input
                    type="text"
                    value={newPublishPages[page.id].route}
                    onChange={(e) =>
                      handleChangePublishedPages(
                        page.id,
                        'route',
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={newPublishPages[page.id].label}
                    onChange={(e) =>
                      handleChangePublishedPages(
                        page.id,
                        'label',
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
            ) : (
              <></>
            )
          })}
        </tbody>
      </StyledTable>
      <div>
        <h3>Difference between current published project and new project</h3>
        {pages.map((page) => (
          <div
            key={page.id}
            style={{display: 'flex', marginBottom: '20px'}}
          >
            <div style={{marginRight: '20px'}}>{page.name}</div>
            <div style={{fontWeight: 'bold'}}>
              {page.stage === 'published'
                ? Object.values(alreadyPublishedPages).find(
                    (p) => p.currentPage.id === page.id
                  )
                  ? 'Overwritten'
                  : 'Deleted'
                : Object.values(alreadyPublishedPages).find(
                    (p) => p.conflictPageId === page.id
                  )
                ? 'Overwrites'
                : 'New'}
            </div>
          </div>
        ))}
      </div>
      <RedirectPage
        redirects={redirects}
        selectOptions={selectOptions}
        allRoutes={routes}
        setRedirects={setRedirects}
      />
      <div>
        <div>
          <h3>Summary</h3>
          <StyledTable>
            <thead>
              <tr>
                <td>Name</td>
                <td>Route</td>
                <td>Navigation Label</td>
              </tr>
            </thead>
            <tbody>
              {pagesFromSelected.map((page) => {
                return (
                  <tr key={page.id}>
                    <td>
                      <span>{page.name}</span>
                    </td>
                    <td>
                      <span>{newPublishPages[page.id].route}</span>
                    </td>
                    <td>
                      <span>{newPublishPages[page.id].label}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </StyledTable>
          <h3>Diff Summary</h3>
          {pages.map((page) => (
            <div
              key={page.id}
              style={{display: 'flex', marginBottom: '20px'}}
            >
              <div style={{marginRight: '20px'}}>{page.name}</div>
              <div style={{fontWeight: 'bold'}}>
                {page.stage === 'published'
                  ? Object.values(alreadyPublishedPages).find(
                      (p) => p.currentPage.id === page.id
                    )
                    ? 'Overwritten'
                    : 'Deleted'
                  : Object.values(alreadyPublishedPages).find(
                      (p) => p.conflictPageId === page.id
                    )
                  ? 'Overwrites'
                  : 'New'}
              </div>
            </div>
          ))}
        </div>
        <h3 style={{marginTop: '70px'}}>Redirects summary</h3>
        <StyledTable>
          <thead>
            <tr>
              <td>Route</td>
              <td>Redirect to</td>
            </tr>
          </thead>
          <tbody>
            {redirects.length
              ? redirects.map((redirect) => (
                  <tr key={redirect.route.id}>
                    <td>
                      <span>{redirect.route.url}</span>
                    </td>
                    <td>
                      <span>{redirect.redirectTo}</span>
                    </td>
                  </tr>
                ))
              : 'No redirects'}
          </tbody>
        </StyledTable>
      </div>
    </WizardLayout>
  )
}

export default PublishProjectWizard
