import {useState, useEffect} from 'react'
import {ColorSchemeValues, computeColorSchemeValues} from '../../managers'
import {useLucidContext} from '@/src/state/ServerSideStore'

export function useColorScheme(initialSchemes: any[], color_scheme_id_override: string | null, cms: boolean) {
  const [lucidSiteStore] = useLucidContext(true)
  const [currentScheme, setCurrentScheme] = useState<ColorSchemeValues | undefined>(
    computeColorSchemeValues(initialSchemes).find((scheme) => {
      if (color_scheme_id_override) {
        return scheme.colorSchemeID === color_scheme_id_override
      }
      return scheme.active
    })
  )

  useEffect(() => {
    changeColorScheme(color_scheme_id_override ?? lucidSiteStore.schemes.find((scheme) => scheme.active)?.id)
  }, [lucidSiteStore.schemes])

  const changeColorScheme = (id?: string | null) => {
    let scheme = null
    /**
     * We will sort schemes so that we always get the same default/first scheme
     */
    const schemes = lucidSiteStore.schemes.sort((a, b) => Number(b.id) - Number(a.id))
    if (!id) {
      scheme = schemes.find((scheme) => Boolean(scheme.id)) ?? schemes[0]
    } else {
      scheme = schemes.find((scheme) => scheme.id === id) ?? schemes[0]
    }
    if (scheme) {
      setCurrentScheme(computeColorSchemeValues([scheme]).pop())
    }
  }

  return [currentScheme, changeColorScheme]
}
