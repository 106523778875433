import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Feature Card Quotes Masonry',
      documentationURL: 'https://www.notion.so/einsteinindustries/Feature-Card-Quotes-Masonry-d12b3142a97c4851bcc0390d12baf4eb?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/feature_card-IID-3'
    },
    notion_IID: '3',
    allowedSections: [],
    css: `
section[data-lucid-group="Features Card (2)"] {
    max-height: 150vh;
    overflow: hidden;
}

section[data-lucid-group="Features Card (2)"] .section-button {
    position: absolute;
    top: calc(150vh - 18rem);
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
}
@media (max-width: 1024px) {
section[data-lucid-group="Features Card (2)"] + section.wildcard-section a.button {
  width: 90%;
}
}
section[data-lucid-group="Features Card (2)"]:after {
    z-index: 1;
    content: '';
    background: linear-gradient( 
0deg
 , rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    width: 100%;
    height: 360px;
    position: absolute;
    bottom: 0;
    left: 0;
}



section[data-lucid-group="Features Card (2)"] .features-card-items { /* Masonry container */
    column-count: 4 !important;
    column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
display: block;

}

section[data-lucid-group="Features Card (2)"] .menu.column { /* Masonry bricks or child elements */
    display: inline-block !important;
    margin: 1rem 0 2rem !important;
    width: 100% !important;
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
 -moz-column-break-inside: avoid;

}

section[data-lucid-group="Features Card (2)"] .features-card-item {
    display: flex;
    flex-flow: column;
}

section[data-lucid-group="Features Card (2)"] .features-card-items {
    margin: 1rem 0 !important;
    padding: 0 !important;


}
section[data-lucid-group="Features Card (2)"] .section-container {
  padding: 0 1rem;
}
section[data-lucid-group="Features Card (2)"] .menu-card-item-subtitle {
    order: 5;
}
section[data-lucid-group="Features Card (2)"] .menu.column {
    display: inline-block !important;
    width: 100% !important;
    max-width: 100% !important;
}

section[data-lucid-group="Features Card (2)"] + section.wildcard-section {
    margin-top: calc((-5.56rem * 2) - 76px - 1.7rem);
    z-index: 1;
    background: transparent;
}


section[data-lucid-group="Features Card (2)"] + section.wildcard-section .section-container {
    background: transparent;
}

section[data-lucid-group="Features Card (2)"] + section.wildcard-section + section {
    z-index: 1;
}

@media only screen and (min-width: 500px) {
    section[data-lucid-group="Features Card (2)"] .features-card-items {
        -moz-column-count: 1 !important;
        -webkit-column-count: 1 !important;
        column-count: 1 !important;
    }
}

@media only screen and (min-width: 800px) {
    section[data-lucid-group="Features Card (2)"] .features-card-items {
        -moz-column-count: 2 !important;
        -webkit-column-count: 2 !important;
        column-count: 2 !important;
    }
}

@media only screen and (min-width: 1024px) {
    section[data-lucid-group="Features Card (2)"] .features-card-items {
        -moz-column-count: 3 !important;
        -webkit-column-count: 3 !important;
        column-count: 3 !important;
    }
}

@media only screen and (min-width: 1600px) {
    section[data-lucid-group="Features Card (2)"] .features-card-items {
        -moz-column-count: 4 !important;
        -webkit-column-count: 4 !important;
        column-count: 4 !important;
    }
}
    `
  },
  {
    display: {
      name: 'Feature Cards Flip cards',
      documentationURL: 'https://www.notion.so/einsteinindustries/Feature-Cards-Flip-cards-c222b21ae81e44b29760eb6b31099fa3?pvs=4',
    },
    notion_IID: '83',
    allowedSections: [],
    css: `
    body.home-page .features-card-section[data-lucid-group="Features Card (1)"] .features-card-items > .menu {
  min-height: 300px;
}

body.home-page section[data-lucid-group="Features Card (1)"].features-card-section .menu-card-item-border-0-1  {
      perspective: 800px;
  }
body.home-page section[data-lucid-group="Features Card (1)"].features-card-section .features-card-items .menu-card-item-border-0-1 {

      overflow: visible;
      background-color: transparent;
    }

    body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .menu-card-item-border-0-1:hover .features-card-item {
align-items: center;
display: flex;
justify-content: center;
        transform: rotateY(
    180deg
    );
        transition: all 1s ease;
        box-shadow: 0 0.9px 0.9px -18px rgb(0 0 0 / 24%), 0 1.9px 2px -18px rgb(0 0 0 / 18%), 0 3.4px 3.5px -18px rgb(0 0 0 / 16%), 0 5.4px 5.5px -18px rgb(0 0 0 / 14%), 0 8.3px 8.5px -18px rgb(0 0 0 / 12%), 0 13px 13.3px -18px rgb(0 0 0 / 10%), 0 21.5px 22px -18px rgb(0 0 0 / 8%), 0 43px 44px -18px rgb(0 0 0 / 6%);
    }

    body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .menu-card-item-border-0-1 .features-card-item {
        transform-style: preserve-3d;
        box-shadow: 0px 36px 80px rgb(0 0 0 / 6%), 0px 13.1406px 29.2013px rgb(0 0 0 / 4%), 0px 6.37951px 14.1767px rgb(0 0 0 / 3%), 0px 3.12736px 6.94968px rgb(0 0 0 / 3%), 0px 1.23656px 2.74791px rgb(0 0 0 / 2%);
        transition: all 1s ease;
        transform: rotateY(
    0deg
    );
        height: 100%;
        background-color: #2d518d;
    }

body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .menu-card-item-border-0-1:hover .features-card-item-description {
text-align: center;
    transform: rotateY(
-180deg
);
    z-index: 1;
}

body.home-page section[data-lucid-group="Features Card (1)"].features-card-section .features-card-item-description {
    backface-visibility: hidden;
    transform: rotateY(
180deg
);
color: #fff !important;

}
body.home-page section[data-lucid-group="Features Card (1)"] .menu-card-item.features-card-item:before {
    background-color: #fff;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}






body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .column:nth-of-type(odd) .menu-card-item-border-0-1 {
    z-index: 1;
}



@media (min-width: 1024px) {
body.home-page section[data-lucid-group="Features Card (1)"] h2.menu-card-item-title {
padding: 2rem 1rem;
top: 45px;

}
}
@media (max-width: 1023px) {
body.home-page section[data-lucid-group="Features Card (1)"] h2.menu-card-item-title {
    padding: 2rem 1rem;
    color: #2d518d;

}
}
body.home-page section[data-lucid-group="Features Card (1)"] .menu-card-item-subtitle {
    top: 120px;
    padding: 2rem;
}




body.home-page section[data-lucid-group="Features Card (1)"] .features-card-item-container,body.home-page section[data-lucid-group="Features Card (1)"] .menu-card-item-title, body.home-page section[data-lucid-group="Features Card (1)"] .menu-card-item-subtitle  {
    backface-visibility: hidden;
    position: absolute !important;
    left: 0;
    width: 100%;
    overflow: hidden;
}

  body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .menu-card-item-border-0-1:hover .menu-card-item-title,     body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .menu-card-item-border-0-1:hover .menu-card-item-subtitle {
opacity: 0;
  transition: all 1s ease;
}

  body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .menu-card-item-border-0-1 .menu-card-item-title,     body.home-page section[data-lucid-group="Features Card (1)"] .features-card-items .menu-card-item-border-0-1 .menu-card-item-subtitle {
opacity: 1;
  transition: all 1s ease;
}



body.home-page section[data-lucid-group="Features Card (1)"] .menu-card-item-title {

    opacity: 1 !important;
}




body.home-page section[data-lucid-group="Features Card (1)"]  .features-card-item:hover {
    z-index: 1000;
}




/*grid build */


section[data-lucid-group="Features Card (2)"] .section-content {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto 0;
    grid-template-areas:
        "heading cards cards"
        "text cards cards";
}

section[data-lucid-group="Features Card (2)"] .section-heading {
    grid-area: heading;
    margin-left: 0;
    margin-right: auto;
    padding-right: 2rem;
    padding-left: 1rem;
}
section[data-lucid-group="Features Card (2)"] .section-heading h2  {
  margin-bottom: 0;
}
section[data-lucid-group="Features Card (2)"] .section-heading h2 .title {
  text-align: left;
  margin-bottom: 1rem;
  font-size: 63px;
}
@media (max-width:1140px) {
  section[data-lucid-group="Features Card (2)"] .section-heading h2 .title {
    font-size: inherit;
}
}
section[data-lucid-group="Features Card (2)"] .section-description {
    grid-area: intro;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    padding-right: 2rem;
    padding-left: 1rem;

}

section[data-lucid-group="Features Card (2)"] .section-description .column {
  width: 100%;
  flex-basis: 100%;
  padding: 0;
}

.row.menus.features-card-items {
    grid-area: cards;
    justify-content: start;
}
@media (max-width: 1024px){
section[data-lucid-group="Features Card (2)"] .section-heading {
    width: 100%;
  }

  section[data-lucid-group="Features Card (2)"] .section-heading h2 .title {
    text-align: center;
  }

  section[data-lucid-group="Features Card (2)"] .section-description {
    grid-column-start: 1;
    grid-column-end: 4;
    padding-right: 1rem;
  }
  section[data-lucid-group="Features Card (2)"] .section-content {
      grid-template-areas:
          "heading heading heading"
          "intro intro intro"
          "cards cards cards";
              grid-template-rows: auto;
  }

}
    `
  },
  {
    display: {
      name: 'Features Card: Cards Left Column',
      documentationURL: 'https://www.notion.so/einsteinindustries/Features-Card-Cards-Left-Column-e4a557945b0446ffaee7940a4b8ace2c?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/feature_card-IID-135.png',
      description: 'Place your text in the Outro field.'
    },
    notion_IID: '135',
    allowedSections: [],
    css: `
    /* Features Card: Cards Left Column
    ==================================== */
@media (min-width:1024px) {
section[data-lucid-group="Features Card (1)"] .section-content {
    display: flex;
    flex-wrap: wrap;
  }

  section[data-lucid-group="Features Card (1)"] .section-heading {
    width: 100%;
  }

  section[data-lucid-group="Features Card (1)"] .features-card-items {
    flex-flow: column;
    padding: 0 1rem;
    flex-basis: 40%;
    justify-content: flex-start;
  }

  section[data-lucid-group="Features Card (1)"] .section-outro {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    flex: 1;
  }

  section[data-lucid-group="Features Card (1)"] .section-outro .column {
    max-width: unset;
    flex: 0 0 100%;
  }

  .features-card-section[data-lucid-group="Features Card (1)"] .features-card-items > .menu {
    max-width: 100% !important;
    width: 100% !important;
  }

  .features-card-section[data-lucid-group="Features Card (1)"] .features-card-item {
    display: flex;
  }

  .features-card-section[data-lucid-group="Features Card (1)"] .features-card-item-title {
    display: flex;
    text-align: left;
    flex: 1;
  }

  .features-card-section[data-lucid-group="Features Card (1)"] .features-card-item-subtitle {
    display: flex;
    justify-content: flex-end;
  }
}
    `
  }
]
