import {NextS3MediaStore} from '@/src/media/next-s3-media-store'

export const uploadFileToS3 = async (file: File, directory: string) => {
  const s3MediaStore = new NextS3MediaStore({
    s3Bucket: process.env.NEXT_PUBLIC_S3_BUCKET as string
  })

  try {
    await s3MediaStore.persist([{file: file, directory: directory}])
    const encodedFilename = encodeURI(encodeURI(file.name))
    return `${process.env.NEXT_PUBLIC_IMGIX_CLIENT_SOURCE_URL}/${directory}/${encodedFilename}`
  } catch (error) {
    console.error('Upload failed')
    console.error(error)
    throw error
  }
}
