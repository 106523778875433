import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Textblock Column: Slide-In',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Column-Slide-In-11f9dda65dd040a380de7b56177dac64?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-58.gif'
    },
    allowedSections: [],
    notion_IID: '58',
    css: `
    /* Animation - Textblock Columns 2 Slide-In */
.animated.effect-fade-in-up.textblock-columns-2-section .section-content .column {
    position: relative;
    padding: 20px;
    background: #fff3;
    box-shadow: 0 8px 20px 0 #0002;
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid #fff5;
    border-radius: 8px;
}
.animated.effect-fade-in-up.textblock-columns-2-section .column-1 {
    margin: 0 20px 20px 20px;
    animation: 1.5s textblock-slideFromLeft cubic-bezier(0.46, 0.04, 0, 0.99) !important;
}
.animated.effect-fade-in-up.textblock-columns-2-section .column-2 {
    margin: 0 20px 20px 20px;
    animation: 1.5s textblock-slideFromRight cubic-bezier(0.46, 0.04, 0, 0.99) !important;
}
@keyframes textblock-slideFromRight {
    from { left: 100%; opacity: 0; }
    to { left: 0%; opacity: 1; }
}
@keyframes textblock-slideFromLeft {
    from { right: 100%; opacity: 0; }
    to { right: 0%; opacity: 1; }
}
    `
  },
  {
    display: {
      name: 'Textblock Columns 2 : Split 50% width Background Image',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-2-Split-50-width-Background-Image-04ba4c445e0f4691a74943a31e304ccc?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-50.gif',
      description: 'Documentation contains important details'
    },
    allowedSections: [],
    notion_IID: '50',
    css: `
    
/* Textblock Columns 2 : Split 50% width Background Image */

/** Left Image **/
@media (min-width: 1024px) {
\t\tsection[data-lucid-group="Textblock Columns 2 (1)"] .section-custom-background {
\t\t\t\tmax-width: 50%;
\t\t\t\tleft: 0;
\t\t}
\t\tsection[data-lucid-group="Textblock Columns 2 (1)"] .column-2 img {
\t\t\t\tdisplay: none;
\t\t}

}

/*** Animation fixes ***/
section[data-lucid-group="Textblock Columns 2 (1)"].animated.effect-zoom-out .section-custom-background {
    animation: zoomOutSplitLeft 5s ease-out 1 !important;
    transform: scale(1);
}

@keyframes zoomOutSplitLeft {
\t\t100% {
\t\t    transform: scale(1);
\t\t}
\t\t
\t\t0% {
\t\t    transform: translate(-5vw,0) scale(1.2);
\t\t}
}

/* Mobile Empty Column Fix */
@media screen and (max-width: 1024px) {
\t\t.column {
\t\t    flex: 0 0 100%;
\t\t    max-width: 100%;
\t\t}
}
/*** Front sections additional hacks ***/
section[data-lucid-group="Textblock Columns 2 (1)"].front-stack {
    max-width: 1200px;
    margin: 0 auto;
}
section[data-lucid-group="Textblock Columns 2 (1)"].front-stack .row {
    max-width: 93.33333% !important;
}
    `
  },
  {
    display: {
      name: 'Textblock Columns 2: Vertically center columns',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-2-Vertically-center-columns-f9a38a8d467249178d942d7aa6488aad?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-147.png'
    },
    allowedSections: [],
    notion_IID: '147',
    css: `
    section[data-lucid-group="Textblock Columns 2 (21)"] .column .section-richtext {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
    `
  },
  {
    display: {
      name: 'Textblock Columns 2: bordered background',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-2-bordered-background-369c4398dab4424481a76f45805d8b87?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-140.png'
    },
    allowedSections: [],
    notion_IID: '140',
    css: `
    .color-scheme-4 .section-container::before {
  background-color: #fff;
  padding: 6rem;
  content: '';
  display: block;
  height: calc(100% - 6rem);
  margin: 3rem;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 6rem);

}
@media (min-width:1024px) {

section.color-scheme-4 img {
display: none;

}
}
@media (max-width:1024px) {



section.color-scheme-4 .column {
  flex-basis: calc(100% - 4rem);
  max-width: calc(100% - 4rem);
margin: 0 auto;

}

.color-scheme-4 .section-container::before {

  margin: 1rem;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);

}
}
section.color-scheme-4 .content-row {
  align-items: center;
}


@media (min-width:1024px) {

section.color-scheme-4 .section-container {
max-width: 100%;
width: calc(100% - 12rem);
}
}

@media  (min-width: 1500px)  {

.color-scheme-4 .section-container::before {
  margin: 6rem;
padding: 2rem;
  height: calc(100% - 12rem);
  width: calc(100% - 12rem);
}

}

@media (min-width:1024px) {


section.color-scheme-4  {
padding: 12rem 0;
}
}
    `
  },
  {
    display: {
      name: 'Colored Circles',
      documentationURL: 'https://www.notion.so/einsteinindustries/Colored-Circles-1c5dd3f6e8634a19bdfc0a90acd5657e?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-117.png'
    },
    allowedSections: ['textblockimage'],
    notion_IID: '117',
    css: `
    /* Colored Circles on Schemes 7 and 8 --------------- */
@media (min-width: 1024px) {
.color-scheme-7,
.color-scheme-8 {
    display: flex;
    max-width: 100vw;
    min-height: 100vh;
}
.color-scheme-7 .section-content,
.color-scheme-8 .section-content {
    position: absolute;
    display: block;
    right: auto;
    left: 0;
    top: 0;
    width: 110vh;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vh;
    margin: -5vh 0;
    background: #725279;
    border-radius: 100%;
}
.color-scheme-8 .section-content {
    right: 0;
    left: auto;
}
.color-scheme-7 .column:only-child,
.color-scheme-8 .column:only-child {
    flex: 1;
    max-width: 100%;
}
}
.color-scheme-7 .section-content .textblock-image-section .column,
.color-scheme-8 .section-content .textblock-image-section .column,
.color-scheme-7 .section-content .column.textblock,
.color-scheme-8 .section-content .column.textblock {
    padding: 0 2rem !important;
}
.color-scheme-7 .section-content .textblock-image-section .column.figure,
.color-scheme-8 .section-content .textblock-image-section .column.figure {
    display: none;
}
    `
  },
  {
    display: {
      name: 'Half-white Half-default background',
      documentationURL: 'https://www.notion.so/einsteinindustries/Half-white-Half-default-background-4e316210da1b4b0cb7f805bebb5ea316?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-103.png'
    },
    allowedSections: ['textblockimage'],
    notion_IID: '103',
    css: `
    /* Half-half Horizontal Schemes 9 and 10 ------------------- */
/* In Lucid, the color for "Background" should be left blank. 
When inserting an image into the white-hand side column, 
the image will only show on mobile.  */
.color-scheme-9::before,
.color-scheme-10::before { /* White on Left */
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0%;
    background: #fff;
    z-index: 1;
}
.color-scheme-10::before { /* White on Right */
    left: 50%;
}
.color-scheme-9 .section-custom-background {
    width: 50%;
    left: 50%;
    background-position: 75% 50% !important;
}
.color-scheme-10 .section-custom-background {
    width: 50%;
    background-position: 25% 50% !important;
}
.color-scheme-9 .column-1 img,
.color-scheme-10 .column-2 img {
    display: none;
}
@media (max-width: 1023px) {
\t\t.color-scheme-9 .column-1 img,
\t\t.color-scheme-10 .column-2 img {
\t\t    display: block;
\t\t}
}
@media (max-width: 837px) {
    .color-scheme-9::before,
    .color-scheme-10::before { 
\t\t    display: none;
\t\t}
    .color-scheme-9 .section-custom-background,
    .color-scheme-10 .section-custom-background {
        width: 100%;
        left: 0%;
        background-position: 50% 50% !important;
    }
}
/* Consultation Form */
.color-scheme-9.consultation-section .section-content{
    width: 50%;
    margin-left: 0%;
}
.color-scheme-10.consultation-section .section-content{
    width: 50%;
    margin-left: 50%;
}
.color-scheme-9.consultation-section .consultation-form-quote .section-richtext form,
.color-scheme-10.consultation-section .consultation-form-quote .section-richtext form {
    margin: auto;
}
    `
  },
  {
    display: {
      name: 'Textblock Columns: Center images and svgs',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-Center-images-and-svgs-b566b2fead554444aa25e066860913ff?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-51.gif'
    },
    allowedSections: ['textblockcolumns3', 'textblock'],
    notion_IID: '51',
    css: `
      /* Textblock Columns: Center images and svgs  */
.textblock-columns-3-section svg, .textblock-columns-3-section p svg, .textblock-columns-2-section svg, .textblock-columns-2-section p svg, .textblock-columns-3-section img, .textblock-columns-3-section p img, .textblock-columns-2-section img, .textblock-columns-2-section p img {
    margin: 1rem auto;
    display: block;
}
    `
  }
]
