import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import styled from 'styled-components'
import SchemaLocalBusiness from '../shared/SchemaLocalBusiness'

const schemaValues = {
  name: 'Practice Name',
  image: 'https://placeimg.com/200/200/tech',
  telephone: '(866) 585-2157',
  streetAddress: '6825 Flanders Dr Ste 110',
  addressLocality: 'San Diego',
  addressRegion: 'CA',
  postalCode: '92121',
  addressCountry: 'USA',
  openingHours: 'Tu,Th 16:00-20:00',
}

const Row = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;

  .city,
  .state,
  .zipcode {
    display: inline;
  }
`

const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 0px;
  padding: 0 1rem;
`

export default function Location(props) {
  const {
    title,
    subtitle,
    intro,
    // address,
    // address2,
    // city,
    // state,
    // zipcode,
    // button_directions,
    // button_call,
    // hours,
    image,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="location"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <SchemaLocalBusiness
        name={schemaValues.name}
        image={schemaValues.image}
        streetAddress={schemaValues.streetAddress}
        addressLocality={schemaValues.addressLocality}
        addressRegion={schemaValues.addressRegion}
        postalCode={schemaValues.postalCode}
        addressCountry={schemaValues.addressCountry}
        telephone={schemaValues.telephone}
        openingHours={schemaValues.openingHours}
      />
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name="intro"
        richtext={intro}
        cms={cms}
      />
      {/* <Row>
        <Column>
          <div>
            <div className="address">
              <SectionText name="address" text={address} cms={cms} />
            </div>
            <div className="address2">
              <SectionText name="address2" text={address2} cms={cms} />
            </div>
            <div className="city">
              <SectionText name="city" text={city} cms={cms} />
              ,&nbsp;
            </div>
            <div className="state">
              <SectionText name="state" text={state} cms={cms} />
              &nbsp;
            </div>
            <div className="zipcode">
              <SectionText name="zipcode" text={zipcode} cms={cms} />
            </div>
            <SectionButton
              href={button_directions?.url}
              label={button_directions?.label}
              fields={buttonFields}
              cms={cms}
            />
            <SectionButton
              href={button_call?.url}
              label={button_call?.label}
              fields={buttonFields}
              cms={cms}
            />
            <RichtextWithVariables cms={cms} name="hours" text={hours} />
          </div>
        </Column>
        <Column>
          <SectionImage
            name={name}
            src={image.src}
            alt={image.alt}
            // width={image.width}
            // height={image.height}
            cms={cms}
          />
        </Column>
      </Row> */}
      <RichtextWithVariables
        name="outro"
        richtext={outro}
        cms={cms}
      />
      {/* <SectionButton
        href={button?.url}
        label={button?.label}
        fields={buttonFields}
        cms={cms}
      /> */}
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     useEffect(() => {
//       console.log('Location data', data)
//     }, [data])

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <Location {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Location',
//     defaultItem: {
//       title: 'Location Title',
//       subtitle: 'Subtitle',
//       address: '6825 Flanders Dr',
//       city: 'San Diego',
//       state: 'CA',
//       zipcode: '92121',
//       button_directions: {
//         label: 'Directions',
//         url: 'https://www.google.com/maps/place/6825+Flanders+Dr,+San+Diego,+CA+92121/@32.9031956,-117.1776961,17z/data=!3m1!4b1!4m5!3m4!1s0x80dc0761bd7efd4b:0xa76c3d1a8aba6c92!8m2!3d32.9031956!4d-117.1755074',
//       },
//       button_call: {
//         label: 'Call',
//         url: 'tel:+1866349-3996',
//       },
//       image: {
//         src: 'https://d1n5s2tett0dwr.cloudfront.net/9dJQ-rG3dKnn9o6OiaHtp2AXPL0=/1280x/https%3A%2F%2Fwww.einsteinmedical.com%2F_cache%2Fstatic_map_bc80a649fd39cf4728782e00618e731e.png',
//         alt: 'Google map image of our location in 6825 Flanders Dr, San Diego, CA 92121',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'address',
//         label: 'Street Address',
//         component: 'text',
//       },
//       {
//         name: 'address2',
//         label: 'Apt/Suite/Other',
//         component: 'text',
//       },
//       {
//         name: 'city',
//         label: 'City',
//         component: 'text',
//       },
//       {
//         name: 'state',
//         label: 'State',
//         component: 'text',
//       },
//       {
//         name: 'zipcode',
//         label: 'Zip Code',
//         component: 'text',
//       },
//       {
//         name: 'button_directions',
//         label: 'Button Directions',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'button_call',
//         label: 'Button Call',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'image',
//         label: 'Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'location'
