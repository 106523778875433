import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {styleCore} from './stylecore'

export default function AnnouncementB(props) {
  const {
    title,
    subtitle,
    richtext,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    cms = true
  } = props
  return (
    <Section
      title={title}
      subtitle={subtitle}
      richtext={richtext}
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section announcement-b-section stack stylecore'}
      name="announcementb"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row">
            <div className="column shrink">
              <div className="section-heading">
                <span className="title heading-1">
                  <RichtextWithVariables name="title" richtext={title} cms={cms} />
                </span>
                <span className="subtitle heading-2">
                  <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms} />
                </span>
              </div>
            </div>
            <div className="column divider shrink">
              <p className="text">/</p>
            </div>
            <div className="column">
              <RichtextWithVariables name="richtext" richtext={richtext} cms={cms} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

AnnouncementB.StyleCore = styleCore
