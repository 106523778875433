import useSWR, {mutate as globalMutate} from 'swr'
import useDebounce from '@/src/utils/useDebounce'
import {SEARCH_USERS} from '@/graphql/queries'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {User} from '@/components/shared/types'
import {useEffect, useState} from 'react'

export default function useUserSearch(userSearchInput: string): [User[], boolean, () => void] {
  const searchString = userSearchInput.toLowerCase()
  const debouncedSearch = useDebounce(searchString, 500)
  const [isLoading, setIsLoading] = useState(false)

  const key = debouncedSearch ? [
    SEARCH_USERS, 
    { 
      searchUserInput: {
        query: debouncedSearch
      }
    }
  ] : null

  const {data: searchResults, isValidating} = useSWR<{data: {searchUsers: User[]}}>(
    key,
    lucidDataFetcherV2 as <T>(query: string, variable?: {}) => Promise<{data: {searchUsers: T}}>,
    {
      revalidateOnFocus: false,
      fallbackData: {data: {searchUsers: []}}
    }
  )

  const mutateSearch = () => {
    globalMutate(key)
  }

  useEffect(() => {
    if (searchString) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [searchString])

  useEffect(() => {
    if (!isValidating) {
      setIsLoading(false)
    }
  }, [isValidating])

  return [searchResults?.data?.searchUsers || [], isLoading, mutateSearch]
}
