import {useEffect, useMemo, useState} from 'react'

function getWindowDimensions(isSsr) {
  if (isSsr) {
    return {
      width: 0,
      height: 0
    }
  }
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height
  }
}

export const WindowSizeKey = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 5
}

const windowSize = [320, 640, 768, 1028, 1280, 1536]

export default function useWindowDimensions() {
  const isSsr = typeof window === 'undefined'
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(isSsr))

  useEffect(() => {
    if (isSsr) {
      return
    }
    function handleResize() {
      setWindowDimensions(getWindowDimensions(isSsr))
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSsr])


  const sizeIndex = windowSize.findIndex((size) => size > windowDimensions.width)

  return {
    dimensions: windowDimensions,
    size: sizeIndex === -1 ? windowSize.length - 1 : sizeIndex
  }
}
