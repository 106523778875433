import {Plugin, PluginStatus} from 'src/components/shared/types'
import styled from 'styled-components'
import {
  Badge,
  Button,
  Card,
  Dropdown,
  FormElement,
  Grid,
  Input,
  Loading,
  SimpleColors,
  Switch,
  SwitchEvent,
  Text
} from '@nextui-org/react'
import {AddCircle, BookSquare, Code1, Danger, ExportSquare, Image, RefreshCircle, Video} from 'iconsax-react'
import React, {Key, useEffect, useState} from 'react'
import deepEqual from '@/src/utils/deepEqual'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {ADD_PLUGIN, REMOVE_PLUGIN, UPDATE_PLUGIN} from '@/graphql/mutations'
import {useConfirmationDialog} from '@/components/shared/AlertDialogs'
import useSWR, {SWRResponse} from 'swr'
import {GET_SITE_PLUGINS} from '@/graphql/queries'
import {KeyedMutator} from 'swr/dist/types'
import Helpers from '@/src/utils/shared/helpers'

type PluginColorConfig = {
  colorName: SimpleColors,
  normal: string,
  light: string
}
type PluginConfiguration = {
  style: {
    icon: JSX.Element,
    name: string,
    description: string,
    color: PluginColorConfig
  },
  defaults: {
    domain: string,
    subdomain: string,
    path: string,
    route_path: string,
  }
}
type PluginConfigurationObject = {
  [key: string]: PluginConfiguration
}

function createDefaultPluginSet(siteDomain: string) {
  return [
    {
      name: 'Blog',
      plugin_type: 'BLOG',
      url_path: `https://${siteDomain}.einsteinapps.com/blog`,
      active: true,
      route_path: '/blog',
    },
    {
      name: 'Gallery',
      plugin_type: 'GALLERY',
      url_path: `https://${siteDomain}.einsteinapps.com/photogallery`,
      active: true,
      route_path: '/gallery',
    },
    {
      name: 'Videos',
      plugin_type: 'VIDEO',
      url_path: `https://${siteDomain}.einsteinapps.com/video4`,
      active: true,
      route_path: '/videos',
    }
  ] as Plugin[]
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
`

async function determineIfValid(plugin: Plugin) {
  try {
    // Check if the plugin.url_path redirects on request. If so, it is not valid.
    const response = await fetch('/api/plugins/verify', {
      method: 'POST',
      body: JSON.stringify(plugin),
    })
    return (await response.json())?.isOk
  } catch (e) {
    console.dir(e)
    return false
  }
}

function  getPluginTypeConfiguration(siteDomain: string, size: number, pluginName?: string) : PluginConfiguration | PluginConfigurationObject {
  const config : PluginConfigurationObject = {
    blog: {
      style: {
        icon: <BookSquare size={size} variant="Bulk"/>,
        name: 'Blog',
        description: 'A blog plugin that allows you to create and manage blog posts.',
        color: {
          colorName: 'success',
          normal: '#17C964FF',
          light: 'rgb(218, 251, 232)'
        }
      },
      defaults: {
        domain: 'einsteinapps.com',
        subdomain: siteDomain.split('.')[0],
        path: 'blog',
        route_path: '/blog',
      },
    },
    gallery: {
      style: {
        icon: <Image size={size} variant="Bulk"/>,
        name: 'Gallery',
        description: 'A gallery plugin that allows you to create and manage galleries.',
        color: {
          colorName: 'warning',
          normal: '#f5a524',
          light: 'rgba(251,236,218,0.38)'
        }
      },
      defaults: {
        domain: 'einsteinapps.com',
        subdomain: siteDomain.split('.')[0],
        path: 'photogallery',
        route_path: '/gallery',
      }
    },
    video: {
      style: {
        icon: <Video size={size} variant="Bulk"/>,
        name: 'Video',
        description: 'A video plugin that allows you to create and manage videos.',
        color: {
          colorName: 'secondary',
          normal: '#9750DD',
          light: 'rgba(151,80,221,0.11)'
        }
      },
        defaults: {
            domain: 'einsteinapps.com',
            subdomain: siteDomain.split('.')[0],
            path: 'video4',
            route_path: '/videos',
        }
    }
  }
  if (pluginName) {
    return config[pluginName as keyof typeof config]
  } else {
    return config as PluginConfigurationObject
  }
}

const dangerColors: PluginColorConfig = {
  colorName: 'error',
  normal: '#f31260',
  light: '#FDD8E5'
}


function PluginConnectionStatus({status, refreshStatus}: {status: PluginStatus, refreshStatus?: () => void})
{
  const connectionStatus = {
    checking: {
      color: 'warning',
      text: 'Checking...'
    },
    connected: {
      color: 'success',
      text: 'Connected'
    },
    disconnected: {
      color: 'error',
      text: 'Disconnected'
    }
  }
  return <div style={{
    display: 'flex',
    paddingRight: '10px',
    alignItems: 'center',
  }}>
    {
      status === 'checking' ? (<Loading style={{
        marginRight: '5px',
      }} color="warning" size="xs"/>) : (<Badge
        content="Test"
        color={connectionStatus[status].color as SimpleColors}
        shape="circle"
        variant="dot"
        style={{
          marginRight: '5px',
          outline: 'none',
        }}
      />)
    }

    <Text size={'14px'} color={connectionStatus[status].color}>
      {connectionStatus[status].text}
    </Text>
    {
      refreshStatus && <div style={{paddingLeft: 10}}>
        <Button light color={connectionStatus[status].color as SimpleColors} onPress={refreshStatus}
                disabled={status === 'checking'} rounded size="xs" style={{minWidth: '24px', padding: 0}}>
          <RefreshCircle size="24" variant="Bulk"/>
        </Button>
      </div>
    }

  </div>
}

function Plugin({plugin, siteDomain, onOpen, onClose, open, onMutate} : {plugin: Plugin, siteDomain:string, onOpen: () => void, onClose: () => void, open: boolean, onMutate: () => void}) {
  const [saving, setSaving] = useState(false)
  const [pluginConnectionStatus, setPluginConnectionStatus] = useState('checking')
  const [testPluginConnectionStatus, setTestPluginConnectionStatus] = useState('checking')
  const [pluginWIP, setPluginWIP] = useState(plugin)
  const [domainComponents, setDomainComponents] = useState({
    subdomain: (pluginWIP.url_path ?? siteDomain).split('.')[0].replace('https://', ''),
    path: (pluginWIP.url_path ? pluginWIP.url_path.split('/')[3] : pluginWIP.plugin_type.toLowerCase()),
    domain: (pluginWIP.url_path ?? siteDomain).replace('https://', '').split('/')[0],
    name: pluginWIP.name,
    id: pluginWIP.id,
    route: pluginWIP.route_path
  })
  const [confirmDelete, setConfirmDelete] = useState('none')

  const pluginConfiguration = getPluginTypeConfiguration(siteDomain, 28, pluginWIP.plugin_type.toLowerCase()) as PluginConfiguration

  async function deletePlugin() {
    await removePlugin(plugin)
    setConfirmDelete('none')
    onMutate()
  }

  async function checkPluginValidation(plugin: Plugin, stateFunction: any) {
    stateFunction('checking')
    stateFunction(await determineIfValid(plugin) ? 'connected' : 'disconnected')
  }
  useEffect(() => {
    checkPluginValidation(plugin, setPluginConnectionStatus)
  }, [plugin])

  useEffect(() => {
    setPluginWIP({
      ...pluginWIP,
      url_path: `https://${domainComponents.subdomain}.einsteinapps.com/${domainComponents.path}`,
      name: domainComponents.name,
      route_path: domainComponents.route
    })
  }, [domainComponents])

  useEffect(() => {
    checkPluginValidation(pluginWIP, setTestPluginConnectionStatus)
  }, [pluginWIP.url_path])

  useEffect(() => {
    if (confirmDelete === 'deleting') {
      deletePlugin()
    }
  }, [confirmDelete])

  const colors = pluginConnectionStatus === 'disconnected' ? dangerColors : pluginConfiguration.style.color

  const fields = [
    {
      name: 'Subdomain',
      description: 'The subdomain of where the plugin is hosted.',
      value: domainComponents.subdomain,
      locked: false,
      color: 'secondary'
    },
    {
      name: 'Path',
      description: 'The path of where the plugin is hosted.',
      value: domainComponents.path,
      locked: false,
      color: 'warning'
    },
    {
      name: 'Name',
      description: 'The name of the plugin.',
      value: domainComponents.name,
      locked: false,
      color: 'primary'
    },
    {
      name: 'Route',
      description: 'The route of the plugin.',
      value: domainComponents.route,
      color: 'success'
    },
    {
      name: 'ID',
      description: 'The ID of the plugin.',
      value: domainComponents.id,
      locked: true,
      color: 'error'
    }
  ]

  const confirmationDialog = useConfirmationDialog()

  async function savePlugin() {
    setSaving(true)
    try {
      await updatePlugin({
        ...plugin,
        ...pluginWIP,
      })
      setSaving(false)
      onClose()
    } catch (e) {
      setSaving(false)
    }
    onMutate()
  }

  function handleClick() {
    if (!open) {
      onOpen()
    } else {
      onClose()
    }
  }

  function handleCancel() {
    onClose()
    setPluginWIP(plugin)
  }

  function handleDelete() {
    if (confirmDelete === 'none') {
      setConfirmDelete('confirm')
      setTimeout(() => {
        setConfirmDelete('none')
      }, 5000)
    } else if (confirmDelete === 'confirm') {
      setConfirmDelete('deleting')
    }
  }

  function changeActive(e: SwitchEvent) {
    onOpen()
    setPluginWIP({
      ...pluginWIP,
      active: e.target.checked
    })
  }

  function refreshPluginStatus() {
    checkPluginValidation(plugin, setPluginConnectionStatus)
  }

  function refreshWIPPluginStatus() {
    checkPluginValidation(pluginWIP, setPluginConnectionStatus)
  }

  function openWIPPluginInNewTab() {
    window.open(pluginWIP.url_path, '_blank')
  }

  return <Card
    isPressable={!open}
    isHoverable={!open}
    variant="flat"
    onClick={handleClick}
    style={{
      height: (open ? '100%' : '75px'),
      transition: 'all 500ms cubic-bezier(0.000, 0.975, 0.000, 0.985);',
      transitionTimingFunction: 'cubic-bezier(0.000, 0.975, 0.000, 0.985);',
      background: `${open ? 'white' : (pluginWIP.active ? colors.light : 'white')}`,
      borderColor: (open ? '#efefef' : colors.normal),
      borderWidth: (open ? '3px' : '2px'),
      border: 'solid'
    }}
  >
    <Card.Body style={{
      overflow: 'hidden',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: colors.normal,
        padding: '0px 10px',
      }}>
        <div style={{
          display: 'flex',
        }}>
          {pluginConnectionStatus === 'disconnected' ? <Danger size={28} variant="Bulk" /> : pluginConfiguration.style.icon}&nbsp;
          <Text h4 style={{
            color: colors.normal
          }}>{plugin.plugin_type[0].toUpperCase() + plugin.plugin_type.substring(1).toLowerCase()}
            {
              plugin.plugin_type !== plugin.name.toUpperCase() && ` - ${plugin.name}`
            }
          </Text>
        </div>
        <div style={{
          display: 'flex',
        }}>
          <PluginConnectionStatus status={pluginConnectionStatus as PluginStatus} refreshStatus={refreshPluginStatus} />
          <Switch color={colors.colorName} bordered disabled={saving} onChange={changeActive} checked={pluginWIP.active} />
        </div>
      </div>
      <div style={{overflow: 'hidden', padding: '20px', paddingTop: '30px', width: '100%'}}>
        {
          pluginConnectionStatus === 'disconnected' && <Card variant="flat" style={{
            backgroundColor: colors.light,
            padding: 20,
            textAlign: 'center',
            marginBottom: 20,
          }}>
                <Text style={{color: colors.normal}}>
                    This plugin is currently redirecting or failing to resolve, which is a sign of a misconfigured plugin. Please check the plugin settings to ensure that the plugin is configured correctly. &nbsp;
                    <Button color={colors.colorName} size={'xs'} style={{fontWeight: 400, display: 'inline'}} onPress={refreshPluginStatus}>Retry</Button>
                </Text>
            </Card>
        }
        <Card variant="flat" style={{
          paddingBottom: 20,
        }}>
          <Card.Body>
            <div>
              <Text h4 style={{
                textAlign: 'center',
              }}>https://<Text color="secondary" h4 style={{display: 'inline'}}>{domainComponents.subdomain}</Text>.einsteinapps.com/<Text color="warning" h4 style={{display: 'inline'}}>{domainComponents.path}</Text></Text>
            </div>
          </Card.Body>
        </Card>
        <div style={{
          position: 'absolute',
          bottom: 555,
          left: 0,
          width: '100%',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

          }}>
            <Button.Group size="md" style={{
              boxShadow: '0px 5px 5px #00000020',
              transition: 'all 0.5s ease-in-out',
              transitionDelay: '0.2s',
              opacity: (!open ? '0%' : '100%')
            }}>
              <Button style={{backgroundColor: '#ffffff'}} onPress={refreshWIPPluginStatus}
              ><PluginConnectionStatus status={testPluginConnectionStatus as PluginStatus} /></Button>
              <Button style={{backgroundColor: '#ffffff'}} onPress={openWIPPluginInNewTab}><ExportSquare
                size="24"
                variant="Bulk"
                style={{color: '#8a8a8a'}}
              /></Button>
            </Button.Group>
          </div>
        </div>
        {
          fields.map((field, index) =>
            {
              function handleFieldChange(e: React.ChangeEvent<FormElement>) {
                setDomainComponents({
                  ...domainComponents,
                  [field.name.toLowerCase()]: e.target.value
                })
              }
              return <span key={index}>
                <div style={{display: 'flex', padding: 0, margin: 0, width: '100%'}}>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                    <Text style={{padding: 15, paddingTop: 19}}>
                      <div style={{lineHeight: '10px'}}><Text b color={field.color}>{field.name}</Text></div>
                      <div><Text small style={{lineHeight: '0px'}}>{field.description}</Text></div>
                    </Text>
                    <Input status={field.color as SimpleColors} placeholder={field.name} disabled={saving || field.locked} value={domainComponents[field.name.toLowerCase() as keyof typeof domainComponents]} onChange={handleFieldChange} size="lg"/>
                  </div>
                </div>
                {
                  index !== fields.length - 1 && <div style={{width: '100%', height: '1px', background: '#eaeaea'}}/>
                }
              </span>
            }
          )
        }
        <Button
          auto
          color={colors.colorName}
          disabled={saving || deepEqual(plugin, pluginWIP)}
          style={{
            marginTop: '10px',
            width: '100%',
          }}
          size="lg"
          onClick={savePlugin}
        >
          Save
        </Button>
        <Button
          auto
          flat
          color={colors.colorName}
          disabled={saving}
          style={{
            marginTop: '10px',
            width: '100%',
          }}
          size="lg"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
            auto
            light={confirmDelete === 'none'}
            color={'error'}
            disabled={confirmDelete === 'deleting'}
            style={{
              marginTop: '10px',
              width: '100%',
            }}
            size="lg"
            onClick={handleDelete}
        >
          {confirmDelete === 'none' && 'Remove Plugin'}
          {confirmDelete === 'confirm' && 'Are you sure you want to remove this plugin? This action cannot be undone.'}
          {confirmDelete === 'deleting' && <Loading/>}
        </Button>
      </div>
    </Card.Body>
  </Card>
}

const createNewPlugin = async (siteId: string, siteDomain: string, plugin: Plugin) => {
  await lucidDataFetcherV2(ADD_PLUGIN, {
    new_plugin_input: {
        ...plugin,
        site_id: siteId,
    }
  })
}

const updatePlugin = async (plugin: Plugin) => {
  const {id,plugin_type, ...pluginData} = plugin
    await lucidDataFetcherV2(UPDATE_PLUGIN, {
      id, 
      update_plugin_input: pluginData
    })
}

const removePlugin = async (plugin: Plugin) => {
    await lucidDataFetcherV2(REMOVE_PLUGIN, {
        id: plugin.id
    })
}


const attachDefaultPluginSet = async (siteId: string, siteDomain: string, mutate: KeyedMutator<any>) => {
  const pluginSets: Plugin[] = createDefaultPluginSet(siteDomain)
    for (const plugin of pluginSets) {
      await createNewPlugin(siteId, siteDomain, plugin)
    }
    mutate()
}
export function NoPluginPlaceholder({siteId, siteDomain, mutate} : {siteId: string, siteDomain: string, mutate: KeyedMutator<any>}) {
  const [initializing, setInitializing] = useState(false)
  return <><Card style={{minWidth: '400px'}} variant="flat">
    <Card.Body>
      <div style={{
        padding: '30px'
      }}>
        <Text style={{
          textAlign: 'center',
        }}>
          <div>
            <Code1
                size="64px"
                variant="Bulk"
                color="#0072F5"
            />
          </div>
          <Text weight={'bold'} color="primary" size="$xl" style={{
            lineHeight: '35px'
          }}>No Plugins Found</Text>
          <Text size="$sm" color={'$accents7'} style={{
            lineHeight: '10px',
            paddingTop: 5
          }}>This site does not have any plugins linked yet. Would you want to link them now? The default plugin set will be created.</Text>
        </Text>
      </div>
    </Card.Body>
  </Card>
    <Button size="lg" disabled={initializing} onPress={() => {
      setInitializing(true)
      attachDefaultPluginSet(siteId, siteDomain, mutate)
      setInitializing(false)
    }} style={{
      marginTop: 15,
      width: '100%'
    }}>{
      initializing ? <Loading size='sm'/> : 'Initialize Plugins'
    }</Button>
      </>
}

export function PluginForm({siteId, siteDomain, incoming_plugins} : {siteId: string, siteDomain: string, incoming_plugins?: Plugin[]}) {
  const [currentlyOpen, setCurrentlyOpen] = useState<number>(-1)
  const [addingNewPlugin, setAddingNewPlugin] = useState<boolean>(false)
  const {
    data: {data: {plugins}},
    mutate,
  }: SWRResponse = useSWR(
      [GET_SITE_PLUGINS, {site_id: siteId}],
      lucidDataFetcherV2,
      {
        fallbackData: {
          data: {
            plugins: incoming_plugins
          },
        },
      }
  )
  async function addNewPlugin(type: Plugin['plugin_type']) {
    setAddingNewPlugin(true)
    const newPlugin: Plugin = {
      plugin_type: type,
      name: `New ${Helpers.format.capitalize.firstLetter(type)}`,
      active: true,
      url_path: `https://subdomain.einsteinapps.com/${type.toLowerCase()}`,
      route_path: `/${type.toLowerCase()}`,
    }
    await createNewPlugin(siteId, siteDomain, newPlugin)
    mutate()
    setAddingNewPlugin(false)
  }
  if (!plugins) {
    return <Loading/>
  } else {
    return <Container>
      <Grid.Container gap={1}>
        {
          plugins.length === 0 && <NoPluginPlaceholder siteId={siteId} siteDomain={siteDomain} mutate={mutate}/>
        }
        {plugins.map((plugin: Plugin, index: number) => (
          <Grid xs={12} key={index}>
            <Plugin plugin={plugin}
                    onMutate={mutate}
                    siteDomain={siteDomain}
                    onClose={() => {setCurrentlyOpen(-1)}}
                    onOpen={() => {setCurrentlyOpen(index)}}
                    open={currentlyOpen === index}/>
          </Grid>
        ))}
        <Dropdown>
          <Dropdown.Button disabled={addingNewPlugin} size="lg" flat style={{width: '100%', marginTop: 10}} icon={<></>}> <AddCircle
            size="24"
            variant="Bulk"
          /> &nbsp; {
            addingNewPlugin ? <Loading size="sm"/> : 'Add Plugin'
          }</Dropdown.Button>
          <Dropdown.Menu
            selectedKeys={[] as Iterable<Key>}
            selectionMode='single'
            onSelectionChange={(e) => {
              addNewPlugin((e as unknown as {anchorKey: Plugin['plugin_type']}).anchorKey)
            }}>
            {Object.entries(getPluginTypeConfiguration(siteDomain, 24)).map(([name,config]) => {
              return <Dropdown.Item key={name.toUpperCase()} icon={config.style.icon} color={config.style.color.colorName}>{config.style.name}</Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Container>
    </Container>
  }
}
