import {useState} from 'react'  

export const useOverridesWindowState = (cms: boolean, lucid_page_section_id?: string) => {
  const [display, setDisplay] = useState<'visible' | 'hidden' | 'locked'>('hidden')  
  const [overridesModalIsOpened, setOverrideModalIsOpened] = useState(false)  

  const onActive = () => {
    if (display !== 'visible' && cms) {
      setDisplay(typeof lucid_page_section_id !== 'undefined' ? 'visible' : 'locked')  
    }
  }  

  const onBlur = () => {
    if (display && !overridesModalIsOpened && cms) {
      setDisplay('hidden')  
    }
  }  

  return {display, overridesModalIsOpened, onActive, onBlur, setOverrideModalIsOpened}  
}  
