import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {MEGA_MENU_BLOCK_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from './presets'

export default function MegaMenuBlock(props) {
  const {
    title,
    subtitle,
    intro,
    // items,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' mega-menu-block-section'}
      name="megamenublock"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <div className={'title color-scheme-title heading-1'}>
                <InlineTextWithVariables name="title" value={title} cms={cms}/>
              </div>
              <div className={'subtitle color-scheme-subtitle heading-2'}>
                <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms}/>
              </div>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables name="intro" richtext={intro} cms={cms}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row expanded collapse">
            <div className="column">
              <div className="mega-menu-block-items">
                <LucidInlineBlocks
                  className="single-column-menu"
                  itemProps={props}
                  name="mega_menu_block_items"
                  blocks={MEGA_MENU_BLOCK_ITEM_BLOCKS}
                />
              </div>
            </div>
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables name="outro" richtext={outro} cms={cms}/>
              </div>
            </div>
          </div>
          <div className="button-container">
            <SectionButton name='button' href={button?.url} label={button?.label} cms={cms}/>
          </div>
        </div>
      </div>
    </Section>
  )
}

MegaMenuBlock.StyleCore = styleCore
MegaMenuBlock.presets = presets
