import type {TinaCMS} from '@einsteinindustries/tinacms'

const DELAY = 5000

class LucidAlert {
  private cms: TinaCMS
  constructor(cms: TinaCMS) {
    this.cms = cms
  }
  success(message: string, time = DELAY) {
    this.cms.alerts.success(message, time)
  }
  info(message: string, time = DELAY) {
    this.cms.alerts.info(message, time)
  }
  warn(message: string, time = DELAY) {
    this.cms.alerts.warn(message, time)
  }
  error(message: string, time = DELAY) {
    this.cms.alerts.error(message, time)
  }
}

export default LucidAlert
