import {Button, Grid, Modal, Text, useTheme} from '@nextui-org/react'
import React, {useEffect, useState} from 'react'
import {CloseCircle, InfoCircle, TickCircle, Warning2} from 'iconsax-react'

type SentimentType = 'success' | 'error' | 'warning' | 'primary';

export type AlertDialogProps = {
  title: string
  message: string
  sentiment?: SentimentType
  confirmText?: string
  setIsOpenState?: boolean
  hideIcon?: boolean
}

export type ConfirmDialogProps = {
  cancelText?: string
  asConfirm?: boolean
} & AlertDialogProps

export type ConfirmDialogCustomCallback = {
  callback?: (result: boolean) => void
}

type ConfirmationDialogContextType = {
  openDialog: (options: ConfirmDialogProps) => void
}


const SentimentIcon = ({sentiment}: { sentiment: SentimentType }) => {
  switch (sentiment) {
    case 'success':
      return <TickCircle size={48} />
    case 'error':
      return <CloseCircle size={48} />
    case 'warning':
      return <Warning2 size={48} />
    default:
      return <InfoCircle size={48} />
  }
}

const BaseDialog = ({title, message, callback, sentiment, confirmText, cancelText, setIsOpenState, asConfirm, hideIcon}: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
  const [isOpen, setIsOpen] = useState(setIsOpenState)
  const {theme} = useTheme()
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  useEffect(() => {
    setIsOpen(setIsOpenState)
  }, [setIsOpenState])

  let primaryColor = '#0070f3'
  const confirm = () => {
    if (callback) callback(true)
    close()
  }
  const cancel = () => {
    if (callback) callback(false)
    close()
  }
  if (!sentiment) {
    sentiment = 'primary'
  }
  if (theme) {
    primaryColor = theme?.colors[sentiment].value
  }
  return (
    <Modal
      preventClose={true}
      aria-labelledby="modal-title"
      open={isOpen}
      onClose={close}
      style={{
        paddingBottom: 10,
        borderRadius: 30,
        boxShadow: `0 0 10px 0 ${primaryColor}1E`,
        backgroundColor: primaryColor
      }}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body
        style={{
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        {
          hideIcon ?? <Text style={{
            color: '#ffffff',
            textAlign: 'center',
          }}>
            <SentimentIcon sentiment={sentiment} />
          </Text>
        }
        <Text h3 style={{
          color: '#ffffff',
          textAlign: 'center',
        }}>
          {title}
          <Text style={{
            color: '#ffffff5A',
            textAlign: 'center',
          }}>
            {message}
          </Text>
        </Text>

      </Modal.Body>
      <Modal.Footer>
        <Grid.Container gap={0.5}>
          <Grid xs={asConfirm ? 6 : 12}>
            <Button auto style={{
              width: '100%',
              backgroundColor: 'rgba(0,0,0,0.22)',
            }} size={'lg'} color={sentiment} onPress={confirm}>
              {confirmText || 'Confirm'}
            </Button>
          </Grid>
            {asConfirm ? <Grid xs={6}><Button style={{
              width: '100%',
              backgroundColor: 'rgba(0,0,0,0.07)',
            }} color={sentiment} size={'lg'}  auto onPress={cancel}>
              {cancelText || 'Cancel'}
            </Button></Grid> : null}
        </Grid.Container>
      </Modal.Footer>
    </Modal>
  )
}

const AlertDialog = (props: AlertDialogProps & ConfirmDialogCustomCallback) => {
  return (
    <BaseDialog
      {...props}
    />
  )
}

const ConfirmDialog = (props: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
  return (
    <BaseDialog
      {...props}
      asConfirm={true}
    />
  )
}

const ContextualizedBaseDialog = (props: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
  return (
    <BaseDialog
      {...props}
    />
  )
}

const ConfirmationDialogContext = React.createContext<ConfirmationDialogContextType>({
  openDialog: (options: ConfirmDialogProps) => {}
})

const ConfirmationDialogProvider = ({children}: any) => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [dialogConfig, setDialogConfig] = React.useState<ConfirmDialogProps & ConfirmDialogCustomCallback | undefined>(undefined)

  const openDialog = (props: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
    setDialogOpen(true)
    setDialogConfig(props)
  }

  const resetDialog = () => {
    setDialogOpen(false)
    setDialogConfig(undefined)
  }

  const onConfirm = () => {
    resetDialog()
    if (dialogConfig?.callback) dialogConfig?.callback(true)
  }

  const onDismiss = () => {
    resetDialog()
    if (dialogConfig?.callback) dialogConfig?.callback(false)
  }


  return (
    <ConfirmationDialogContext.Provider value={{openDialog} as any}>
      {dialogConfig && <ContextualizedBaseDialog
          setIsOpenState={dialogOpen}
          {...dialogConfig}
          callback={(result: boolean) => result ? onConfirm() : onDismiss()}
      />}
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

const useConfirmationDialog = () => {
  const {openDialog} = React.useContext(ConfirmationDialogContext)

  const getConfirmation = (options: ConfirmDialogProps) =>
    new Promise((res) => {
      openDialog({callback: res, ...options} as ConfirmDialogProps & ConfirmDialogCustomCallback)
    })

  return {getConfirmation}
}

export {AlertDialog, ConfirmDialog, useConfirmationDialog, ConfirmationDialogProvider}
