import LucidImage from '@/src/utils/LucidImage'
import {CSSProperties, useState} from 'react'
import {InlineImage} from '@einsteinindustries/react-tinacms-inline'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import styled from 'styled-components'

interface SectionImageProps extends Omit<ImageWrapperProps, 'imageSize'> {
  name: string
  cms: boolean
  width: number
  height: number
  style?: CSSProperties
}

interface ImageWrapperProps {
  src: string
  alt: string
  link?: string
  imageSize: ImageSize | null
  className?: string
  priority: boolean | string
  sizes: string
}

interface ImageSize {
  width: number | string
  height: number | string
}

/**
 * Some other bug with Next.js.
 * Images with sizes specified to them will set a width and height of 0
 */
const ProgressiveImageFix = styled.div`
  span, div {
    position: unset!important;
    padding-top: unset!important;
  }
  img.progressive-image {
    width: 100%!important;
    height: unset!important;
    position: relative!important;
  }
`

export default function SectionImage({
  name,
  src,
  alt,
  width,
  height,
  link,
  cms,
  className,
  style,
  priority,
  sizes
}: SectionImageProps) {
  const [imageSize, setImageSize] = useState<ImageSize | null>(null)

  return (
    <ProgressiveImageFix style={style}>
      {cms ? (
        <InlineImage
          name={`${name}.src`}
          parse={(media) => {
            if (!media) {
              return ''
            }
            return constructAssetUrl(media.id)
          }}
          previewSrc={(src) => src}
          focusRing={true}
          alt={alt}
          className={className}
        >
          {({src: loaded}) => {
            return loaded ? (
              <ImageWrapper
                src={src}
                alt={alt}
                imageSize={imageSize}
                className={className}
                priority={priority}
                sizes={sizes}
              />
            ) : null
          }}
        </InlineImage>
      ) :
        <ImageWrapper
          src={src}
          alt={alt}
          link={link}
          imageSize={imageSize}
          className={className}
          priority={priority}
          sizes={sizes}
        />
      }
    </ProgressiveImageFix>
  )
}

function ImageWrapper({src, alt, imageSize, link, priority, className, sizes}: ImageWrapperProps) {
  if (src) {
    const imgComponent = (
      <LucidImage
        src={src}
        alt={alt}
        width={imageSize?.width}
        height={imageSize?.height}
        className={className}
        priority={priority === 'true' || priority === true}
        sizes={sizes}
      />
    )
    return link && link !== '' ? <a href={link}>{imgComponent}</a> : imgComponent
  }
  return null
}
