import {v4 as uuidv4} from 'uuid'
import React, {useEffect, useRef, useState} from 'react'
import {Section, SectionField, SectionItemField} from '@/components/shared/types'
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  FormElement,
  Grid,
  Input,
  Link,
  Loading,
  Modal,
  Navbar,
  Popover,
  Row,
  Spacer,
  Table,
  Text,
  Textarea
} from '@nextui-org/react'
import {
  Add,
  Box1,
  Box2,
  Calendar,
  Chart,
  CloseCircle,
  ColorSwatch,
  DocumentUpload,
  Element3,
  EyeSlash,
  Gallery,
  GalleryAdd,
  GalleryEdit,
  Image as ImageIcon,
  MessageQuestion,
  MessageText,
  MoreSquare,
  Notepad2,
  ReceiptEdit,
  Refresh2,
  Setting,
  Text as TextIcon,
  TextItalic,
  ToggleOffCircle,
  Trash,
  Video,
  Warning2,
  WeightMeter
} from 'iconsax-react'
import ShowAlertBox from '../../AlertBox'
import {sectionStatusFormat} from '@/components/managers/Toolbar/SectionManager/Utils'
import {uploadFileToS3} from '@/src/utils/uploadFileToS3'

type BaseSectionField = Pick<SectionField,
  'id' | 'name' | 'input_type' | 'default_value'>

export type BaseSection = Pick<Section, 'id' | 'status' | 'description' | 'preview_photo'> & {
  section_fields: BaseSectionField[] | SectionField[]
  name: Section['name'] | '' | 'New Section'
}

const getBlankSection = (): BaseSection => ({
  id: uuidv4(),
  name: '',
  status: 'ACTIVE',
  description: '',
  preview_photo: '',
  section_fields: [],
})

const getBlankSectionField = (): BaseSectionField => ({
  id: uuidv4(),
  name: '',
  input_type: 'TEXT',
  default_value: '',
})

interface CreateModalProps {
  showDialog: boolean
  closeDialog: () => void
  onSave: (section: Section) => void
  section?: Section,
  existingSections?: Section[]
}

function containsSpecialChars(str: string) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return specialChars.test(str)
}

export const InputTypes: InputTypeSelection = {
  TEXT: {
    name: 'Text',
    icon: <TextIcon size={'16px'}/>,
    description: 'A simple text filed',
    enabledForItemContext: true
  },
  RANGE: {
    name: 'Range',
    icon: <WeightMeter size={'16px'}/>,
    description: 'A range of numbers that the user can select',
    enabledForItemContext: true
  },
  IMAGE: {
    name: 'Image',
    icon: <ImageIcon size={'16px'}/>,
    description: 'An image to display to the user',
    enabledForItemContext: true
  },
  IMAGE_GROUP: {
    name: 'Image Group',
    icon: <Gallery size={'16px'}/>,
    description: 'A group of images used for galleries or sets',
    enabledForItemContext: true
  },
  VIDEO: {
    name: 'Video',
    icon: <Video size={'16px'}/>,
    description: 'A Video item with poster and other',
    enabledForItemContext: true
  },
  RICHTEXT: {
    name: 'Rich Text',
    icon: <TextItalic size={'16px'}/>,
    description: 'Text that is formatted with rich text',
    enabledForItemContext: true
  },
  DATE: {
    name: 'Date',
    icon: <Calendar size={'16px'}/>,
    description: 'A date that the user can select',
    enabledForItemContext: true
  },
  TEXTAREA: {
    name: 'Text Area',
    icon: <MessageText size={'16px'}/>,
    description: 'A large text area that the user can type in',
    enabledForItemContext: true
  },
  NUMBER: {
    name: 'Number',
    icon: <Chart size={'16px'}/>,
    description: 'A number that the user can type in',
    enabledForItemContext: true
  },
  COLOR: {
    name: 'Color',
    icon: <ColorSwatch size={'16px'}/>,
    description: 'A color that the user can select',
    enabledForItemContext: true
  },
  TOGGLE: {
    name: 'Toggle',
    icon: <ToggleOffCircle size={'16px'}/>,
    description: 'A toggle that is either on or off',
    enabledForItemContext: true
  },
  SELECT: {
    name: 'Select',
    icon: <ReceiptEdit size={'16px'}/>,
    description: 'A list that the user can select from',
    enabledForItemContext: true
  },
  BUTTON: {
    name: 'Button',
    icon: <MoreSquare size={'16px'}/>,
    description: 'A button a user can press',
    enabledForItemContext: true
  },
}

export type InputTypeSelection = {
  [key: string]: {
    name: string,
    icon: any,
    description: string,
    enabledForItemContext: boolean
  }
}

function objectsAreSame(x: any, y: any) {
  if (typeof x === 'undefined' || typeof y === 'undefined') {
    return false
  }
  let objectsAreSame = true
  for (const propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false
      break
    }
  }
  return objectsAreSame
}

const GeneralSection = (props: {
  setModified: React.Dispatch<React.SetStateAction<boolean>>
  section: Section | undefined,
  existingSections?: Section[],
  onChange: (section: Section | undefined) => void,
  showAlertHook: any,
  reset: Section | undefined
}) => {
  const [section, setSection] = useState<Section | undefined>(props.section)
  const [showSpinner, setShowSpinner] = useState(false)
  const [spinnerTitle, setSpinnerTitle] = useState<string | undefined>()
  const [hoveringOverPreview, setHoveringOverPreview] = useState(false)
  const [dragInOverPreview, setDragInOverPreview] = useState(false)
  const [confirmRemoveImage, setConfirmRemoveImage] = useState(false)
  const [imageFullScale, setImageFullScale] = useState(2)
  const [selected, setSelected] = React.useState(new Set([section?.status ?? 'ACTIVE']))
  const [uploadImageError, setUploadImageError] = useState('')
  const [isPristine, setIsPristine] = useState(false)

  useEffect(() => {
      handleSectionStatusChange(Array.from(selected)[0] as 'ACTIVE' | 'HIDDEN' | 'DISABLED')
  }, [selected])


  useEffect(() => {
    const isSame =
      typeof section !== 'undefined' && typeof props.reset?.name !== 'undefined'
      && section.name === props.reset.name && section.description === props.reset.description

    setIsPristine(isSame)
    props.setModified(!isSame)
  }, [section, props.reset])

  const getMeta = (url:string, cb: any) => {
    const img = new Image()
    img.onload = () => cb(null, img)
    img.onerror = (err) => cb(err)
    img.src = url
  }

  useEffect(() => {
    if (section && section.preview_photo) {
      getMeta(section.preview_photo, (err: any, img: ImageData) => {
        let fillScale = 660 / (img.width)
        if ((fillScale * img.height) < 300) {
          fillScale = 300 / (img.height)
        }
        setImageFullScale(Math.max(2, fillScale))
      })
    } else {
      setImageFullScale(2)
    }
    props.onChange(section)
  }, [section])

  useEffect(() => {
    if (dragInOverPreview) {
      const delayDebounceFn = setTimeout(() => {
        setDragInOverPreview(false)
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [dragInOverPreview])

  const inputFile = useRef<HTMLInputElement>(null)

  function handleSectionNameAndDescriptionReset() {
    setSection({
      ...props.reset,
      name: props.reset?.name ?? '',
      description: props.reset?.description ?? ''
    } as Section)
    props.showAlertHook({
      type: 'success',
      title: 'Data has been reset to default',
      message: 'The sections original name and description have been restored.'
    })
    setTimeout(() => {
      props.showAlertHook(undefined)
    }, 5000)
  }


  const handleUpload = async ({target} : {target:any}) => {
    if (target.files && target.files.length) {
      setSpinnerTitle('Uploading image...')
      setShowSpinner(true)

      const [file] = target.files
      try {
        const url = await uploadFileToS3(file, 'section-previews/' + section?.id)
        setShowSpinner(false)
        setSection({
          ...(section as Section),
          preview_photo: url
        })
      } catch (error) {
        console.error('Upload failed: ' + error)
        setUploadImageError('Upload failed: ' + error)
        setShowSpinner(false)
      }
    }
  }

  function handleSectionNameChange(e: React.ChangeEvent<FormElement>) {
    if (e.target.value.includes(' ')) {
      props.showAlertHook({
        title: 'Section name cannot contain spaces',
        message: 'Please remove the spaces from the section name',
        type: 'error'
      })
    } else if (e.target.value.length === 0) {
      props.showAlertHook({
        title: 'Section name must be given',
        message: 'Please give the section a name',
        type: 'error'
      })
    } else if (containsSpecialChars(e.target.value)) {
      props.showAlertHook({
        title: 'Section name cannot contain special characters',
        message: 'Please remove the special characters from the section name',
        type: 'error'
      })
    } else if (e.target.value.length > 32) {
      props.showAlertHook({
        title: 'Section name cannot be longer than 32 characters',
        message: 'Please shorten the section name',
        type: 'error'
      })
    } else if (e.target.value.length > 0 && e.target.value[0] === e.target.value[0].toLowerCase()) {
      props.showAlertHook({
        title: 'The section name must start with an uppercase letter',
        message: 'Please modify the section name to follow this convention',
        type: 'error'
      })
    } else if (
      props.existingSections && props.existingSections.filter((s) => s.name === e.target.value).length > 0
    ) {
      props.showAlertHook({
        title: 'A section with this name already exists',
        message: 'Please choose a different name',
        type: 'error'
      })
    } else {
      props.showAlertHook(undefined)
      if (e.target.value !== '') {
        setSection({...(section as Section), name: e.target.value as Section['name']})
      }
    }
  }

  function handleImagePreviewFileUploadButtonClick() {
      inputFile?.current?.click()
  }

  function handleImageRemove() {
    if (confirmRemoveImage) {
      setSection({...(section as Section), preview_photo: null})
      setConfirmRemoveImage(false)
    } else {
      setConfirmRemoveImage(true)
      setTimeout(() => {
        setConfirmRemoveImage(false)
      }, 3000)
    }
  }

  function handleSectionDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<FormElement>) {
    setSection({...(section as Section), description: e.target.value})
  }

  function handleSectionStatusChange(newStatus: string) {
    setSection({...(section as Section), status: newStatus as 'ACTIVE' | 'HIDDEN' | 'DISABLED'})
  }

  function handleImagePreviewFileUploadDrag(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragInOverPreview(true)
    } else if (e.type === 'dragleave') {
      setDragInOverPreview(false)
    }
  }

  function handleImagePreviewFileUploadDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setDragInOverPreview(false)
      handleUpload({
        target: {
          files: e.dataTransfer.files
        }
      })
    }
  }

  return <div onDragLeave={handleImagePreviewFileUploadDrop}>
    {uploadImageError && <Grid.Container gap={.5} justify="center">
      <Grid>
        <Badge variant={'flat'} color={'error'} size={'md'}>{uploadImageError}</Badge>
      </Grid>
    </Grid.Container>}
    <div style={{marginTop: '5px'}} onDragEnter={handleImagePreviewFileUploadDrag} onDragOver={handleImagePreviewFileUploadDrag} onDrop={handleImagePreviewFileUploadDrop}>
      {dragInOverPreview ? <><Card style={{maxWidth: '900px'}} variant="flat"  onClick={handleImagePreviewFileUploadButtonClick}>
        <Card.Body>
          <div style={{
            padding: '30px'
          }}>
            <Text style={{
              textAlign: 'center',
              color: '#4C5155',
            }}>
              <Spacer y={1}/>
              <div>
                {showSpinner ? <Loading color="currentColor" size="lg" /> : <DocumentUpload size={'64px'}/>}
              </div>
              <Text h4 color="$gray800">
                Drag and drop an image here to upload it
              </Text>
              <Spacer y={0.45}/>
            </Text>
          </div>
        </Card.Body>
      </Card><Spacer y={1}/>
        </> : typeof section?.preview_photo?.length !== 'undefined' && section?.preview_photo?.length > 0 ?
        <>
          <Card onDragEnter={handleImagePreviewFileUploadDrag} onDragOver={handleImagePreviewFileUploadDrag} onDrop={handleImagePreviewFileUploadDrop} style={{width: '100%', height: ('300px'), backgroundImage: `url(${section?.preview_photo})`, backgroundSize: 'cover', backdropFilter: 'saturate(180%) blur(10px)'}} onClick={handleImagePreviewFileUploadButtonClick} onMouseEnter={() => {setHoveringOverPreview(true)}} onMouseLeave={() => {setHoveringOverPreview(false)}} >
            <Card.Header style={{position: 'absolute', zIndex: 1, top: 5}}>
              <Col>
                <Text size={12} weight="bold" transform="uppercase" style={{
                  color: (hoveringOverPreview ? 'rgba(107,107,107,0.67)' : '#ffffffAA'),
                  transition: 'color 0.5s cubic-bezier(0.87, 0, 0.13, 1)'
                }}>
                  {showSpinner ? 'One Moment...' : 'Preview Image'}
                </Text>
              </Col>
            </Card.Header>
            <Card.Body style={{padding: 0, overflow: 'hidden', height: '500px', pointerEvents: 'none'}} >
            {showSpinner ? <Card.Body>
                <div style={{
                  padding: '60px'
                }}>
                  <Text style={{
                    textAlign: 'center',
                    color: '#4C5155',
                  }}>
                    <Spacer y={1}/>
                    <div>
                      {showSpinner ? <Loading color="currentColor" size="lg" /> : <ImageIcon size={'64px'}/>}
                    </div>
                    <Spacer y={2}/>
                  </Text>
                </div>
              </Card.Body> :
              <Card.Image onDragEnter={handleImagePreviewFileUploadDrag} onDragOver={handleImagePreviewFileUploadDrag} onDrop={handleImagePreviewFileUploadDrop}
                src={'/img/HD_transparent_picture.png'}
                width="100%"
                height={'300px'}
                objectFit={hoveringOverPreview ? 'scale-down' : 'cover'}
                style={{
                  borderRadius: '10px',
                  transition: 'all 0.5s cubic-bezier(0.87, 0, 0.13, 1)',
                  backgroundImage: `url(${section?.preview_photo})`,
                  backgroundSize: 'contain',
                  backgroundOrigin: 'content-box',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  transform: hoveringOverPreview ? 'scale(1)' : `scale(${imageFullScale})`,
                }}
                alt=""
              />
            }
            </Card.Body>

            <Card.Footer
              isBlurred
              style={{
                position: 'absolute',
                backdropFilter: 'saturate(180%) blur(10px)',
                background: '#ffffff66',
                borderTop: '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                bottom: (hoveringOverPreview ? 0 : -70),
                opacity: (hoveringOverPreview ? 1 : .5),
                zIndex: 1,
                transition: 'all 0.7s cubic-bezier(0.87, 0, 0.13, 1)',
              }}
            >
              <Row>
                <Col>
                  <Row justify="center">
                    <Button light auto rounded color="primary" icon={!showSpinner && <GalleryEdit size={'16px'}/>} disabled={showSpinner} onClick={handleImagePreviewFileUploadButtonClick}>
                      <Text
                        style={{color: 'inherit'}}
                        size={12}
                        weight="bold"
                        transform="uppercase"
                      >
                        {showSpinner ? 'Uploading...' : 'Change Image'}
                      </Text>
                    </Button>
                    <Button light={!confirmRemoveImage} auto rounded color="error" icon={!showSpinner && <Trash size={'16px'}/>} disabled={showSpinner} onClick={handleImageRemove}>
                      <Text
                        style={{color: 'inherit'}}
                        size={12}
                        weight="bold"
                        transform="uppercase"
                      >
                        {confirmRemoveImage ? 'Are you sure? Click again to confirm.' : 'Remove Image'}
                      </Text>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </>
        : <>
          <Card style={{minWidth: '400px'}} variant="flat"  onClick={handleImagePreviewFileUploadButtonClick}>
            <Card.Body>
              <div style={{
                padding: '30px'
              }}>
                <Text style={{
                  textAlign: 'center',
                  color: '#4C5155',
                }}>
                  <Spacer y={1}/>
                  <div>
                    {showSpinner ? <Loading color="currentColor" size="lg" /> : <ImageIcon size={'64px'}/>}
                  </div>
                  <Spacer y={2}/>
                </Text>
              </div>
            </Card.Body>
            <Card.Footer
              isBlurred
              style={{
                position: 'absolute',
                backdropFilter: 'saturate(180%) blur(10px)',
                backgroundColor: '#ffffff66',
                borderTop: '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                bottom: 0,
                zIndex: 1,
              }}
            >
              <Row>
                <Col>
                  <Row justify="center">
                    <Button flat auto rounded color="primary" icon={!showSpinner && <GalleryAdd size={'16px'}/>} disabled={showSpinner} onClick={handleImagePreviewFileUploadButtonClick}>
                      <Text
                        style={{color: 'inherit'}}
                        size={12}
                        weight="bold"
                        transform="uppercase"
                      >
                        {showSpinner ? 'Uploading...' : 'Add an Image'}
                      </Text>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
          <Spacer y={1}/>
        </>
      }
      <br />
      <input
        ref={inputFile} style={{display: 'none'}}
        accept="image/*"
        type="file"
        name="preview_photo"
        multiple={false}
        onChange={handleUpload}
      />
    </div>
    <Input label="Section Name" width={'32'} placeholder="Section Name" value={section?.name ?? ''} fullWidth={true}
           onChange={handleSectionNameChange}/>
    <Spacer y={0.5}/>
    <Textarea
      fullWidth={true}
      value={section?.description ?? ''}
      label="Section Description"
      placeholder="Enter a section description here"
      onChange={handleSectionDescriptionChange}
    />
    <Spacer y={0.5}/>
    <Text size={'$sm'} style={{
      marginLeft: 3,
      marginBottom: 4
    }}>Section Status</Text>
    <Dropdown>
      <Dropdown.Button size={'lg'}  flat color={section?.status === 'ACTIVE' ? 'success' : (section?.status === 'DISABLED' ? 'error' : undefined)} style={{width: '100%', textTransform: 'capitalize'}}>
        {section?.status === 'ACTIVE' ? 'Active' : (section?.status === 'DISABLED' ? 'Disabled' : 'Hidden')}
      </Dropdown.Button>
      <Dropdown.Menu
        aria-label="Single selection actions"
        color="secondary"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selected}
        onSelectionChange={(keys: any) => {
          setSelected(keys)
          return keys
        }}
      >
        <Dropdown.Item key="ACTIVE" color={'success'} icon={<Box1 size={19}/>}> Active</Dropdown.Item>
        <Dropdown.Item key="HIDDEN" color={'default'} icon={<EyeSlash size={19}/>}> Hidden</Dropdown.Item>
        <Dropdown.Item key="DISABLED" color={'error'} icon={<Box2 size={19}/>}> Disabled</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <Spacer y={1}/>
    <Button
      flat auto color={'secondary'}
      icon={<Refresh2 size="16px"/>}
      size="md"
      disabled={isPristine}
      onClick={handleSectionNameAndDescriptionReset}
    >
      Reset
    </Button>
  </div>
}
export const NothingHereYet = (props: {
  type: string,
  above?: boolean
}) => {
  return <>
    <Card style={{minWidth: '400px'}} variant="flat">
      <Card.Body>
        <div style={{
          padding: '30px'
        }}>
          <Text style={{
            textAlign: 'center',
          }}>
            <div>
              <Notepad2 size={'64px'}/>
            </div>
            <Text weight={'bold'} size="$xl" style={{
              lineHeight: '35px'
            }}>There are no {props.type}s here yet</Text>
            <Text size="$sm" color={'$accents7'} style={{
              lineHeight: '10px'
            }}>You can add a {props.type} {!!props?.above ? 'above' : 'below'}</Text>
          </Text>
        </div>
      </Card.Body>
    </Card>
    <Spacer y={1}/>
  </>
}
const ClickToEdit = (props: {
  value: string,
  onChange: (value: string) => void,
  textElementOverride?: any,
  formatAsName?: boolean
}) => {
  const [value, setValue] = useState(props.value)
  const TextElementOverride = props.textElementOverride ?? Text

  function formatDataElement() {
    if (props.formatAsName) {
      return ((value === 'null' || value === null || value === '') ? <i>Unnamed Field</i> : value)
    }
    if (value === 'null' || value === null || value === '' || value.startsWith('<') || !isNaN(parseInt(value))) {
      return <code>{value === '' ? 'null' : value}</code>
    }
    if (value.startsWith('http') && value.includes('.')) {
      return <Link target="_blank" isExternal={true} style={{margin: 0, padding: 0}} href={value}>{value}</Link>
    }
    return <TextElementOverride>{value}</TextElementOverride>
  }

  function handleValueEdit(e: React.ChangeEvent<FormElement>) {
    setValue(e.target.value)
  }

  return <Popover onClose={() => {
    props.onChange(value)
  }
  }>
    <Popover.Trigger>

      {formatDataElement()}

    </Popover.Trigger>
    <Popover.Content style={{
      backgroundColor: '#787F85'
    }}>
      <Text style={{padding: '0.25rem'}}>
        <Input value={value} autoFocus={true} onChange={handleValueEdit}/>
      </Text>
    </Popover.Content>
  </Popover>
}

const FieldsSection = (props: {
  setModified: React.Dispatch<React.SetStateAction<boolean>>
  showAlertHook: any,
  section?: Section | undefined,
  sectionItemFields?: SectionItemField[] | undefined,
  allowedInputTypes: InputTypeSelection,
  reset: Section | undefined,
  onChange: (section: Section | SectionItemField[] | undefined) => void
}) => {

  const [selectedFields, setSelectedFields] = useState<any>([])
  const [fields, setFields] = useState<SectionField[] | SectionItemField[]>(props.section?.section_fields ?? props.sectionItemFields ?? [])
  const [isPristine, setIsPristine] = useState(true)

  useEffect(() => {
    const isSame = selectedFields.length !== 0 || fields.length === (props.reset?.section_fields ?? []).length
      && objectsAreSame(fields, props.reset?.section_fields)
    setIsPristine(isSame)
    props.setModified(!isSame)
  }, [selectedFields, props.reset, fields])

  function handleFieldAndItemsChange() {
    props.onChange({...props.section, section_fields: fields} as Section)
  }

  function handleFieldAndItemsResetInitial() {
    setFields(props.reset?.section_fields ?? [])
  }

  useEffect(handleFieldAndItemsChange, [fields])
  useEffect(handleFieldAndItemsResetInitial, [props.reset])

  const FieldsTable = (props: {
    fields: SectionField[] | undefined,
    allowedInputTypes: InputTypeSelection,
    showAlertHook: any,
  }) => {

    function handleFieldDefaultValueChange(key: number, change: string) {
      setFields((prevState) => {
        const updatedFieldContent = {
          ...fields[key],
          default_value: (change === 'null' || change === '' ? null : change)
        } as SectionField
        return [...prevState].slice(0, key).concat([updatedFieldContent]).concat([...prevState].slice(key + 1))
      })
    }

    function handleFieldTypeChange(value: 'all' | Set<React.Key>, key: number) {
      const updatedFieldContent = {
        ...fields[key],
        // Ignored this error, since this is outside the project scope
        // @ts-ignore
        input_type: value.currentKey as any
      }
      setFields((prevState) => {
        return [...prevState].slice(0, key).concat([updatedFieldContent]).concat([...prevState].slice(key + 1))
      })
    }

    function handleFieldNameChange(key: number, change: string) {
      setFields((prevState) => {
        const updatedFieldContent = {
          ...fields[key],
          name: (change === 'null' || change === '' ? null : change)
        } as SectionField
        return [...prevState].slice(0, key).concat([updatedFieldContent]).concat([...prevState].slice(key + 1))
      })
    }

    return <Table
      aria-label="Example static collection table"
      style={{
        height: 'auto',
        minWidth: '100%',
      }}
      selectionMode="multiple"
      shadow={false}
      striped={true}
      bordered={false}
      onSelectionChange={(selected) => {
        setSelectedFields(selected)
      }}
      selectedKeys={selectedFields}
    >
      <Table.Header>
        <Table.Column>NAME</Table.Column>
        <Table.Column>TYPE</Table.Column>
        <Table.Column>DEFAULT</Table.Column>
      </Table.Header>
      <Table.Body>

        {fields.sort((a, b) => a.sort_order - b.sort_order).map((field, key) => {
          return <Table.Row key={key}>
            <Table.Cell>
              <ClickToEdit formatAsName value={field.name} onChange={(change) => {
                handleFieldNameChange(key, change)
              }}/>
            </Table.Cell>
            <Table.Cell>
              <Dropdown>
                <Dropdown.Button light>
                  <div><Grid.Container gap={.75}><Grid><Spacer
                    y={0.20}/>{props.allowedInputTypes[field.input_type as keyof typeof props.allowedInputTypes]?.icon ?? <MessageQuestion size={'16px'}/>}
                  </Grid><Grid>{props.allowedInputTypes[field.input_type as keyof typeof props.allowedInputTypes]?.name ?? field.input_type}</Grid></Grid.Container>
                  </div>
                </Dropdown.Button>
                <Dropdown.Menu
                  selectionMode={'single'} variant={'solid'}
                  onSelectionChange={(value) => {
                    handleFieldTypeChange(value, key)
                  }}
                  items={Object.entries(props.allowedInputTypes).map(([key, value]) => {
                    return {key: key, name: key, data: value}
                  })} aria-label="Value Types">
                  {
                    (item: any) => (
                      <Dropdown.Item
                        icon={item.data.icon}
                        description={item.data.description}
                        textValue={item.key}
                        showFullDescription={false}
                        key={item.key}>
                        {item.data.name}
                      </Dropdown.Item>
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>

            </Table.Cell>
            <Table.Cell>
              <ClickToEdit value={field.default_value ?? ''} onChange={(change) => {
                handleFieldDefaultValueChange(key, change)
              }}/>
            </Table.Cell>
          </Table.Row>
        })}
      </Table.Body>
    </Table>
  }

  function handleFieldsReset() {
    setFields(props.reset?.section_fields ?? [])
    props.showAlertHook({
      type: 'success',
      title: 'Data has been reset to default',
      message: 'The sections original fields have been restored.'
    })
    setTimeout(() => {
      props.showAlertHook(undefined)
    }, 5000)
  }

  function handleFieldAdd() {
    setFields((prevState) => {
      return [...prevState].concat([getBlankSectionField() as SectionField])
    })
  }

  function handleFieldDelete() {
    setFields((prevState) => {
      return [...prevState].filter((field, key) => !Array.from(selectedFields).includes(`${key}`))
    })
    setSelectedFields([])
  }
  let renderFieldTable = null
  if (fields.length === 0) {
    renderFieldTable = <NothingHereYet type={'field'}/>
  } else {
    renderFieldTable = <FieldsTable showAlertHook={props.showAlertHook} fields={fields}
                                    allowedInputTypes={props.allowedInputTypes}/>
  }

  let deleteOrAddButton = null
  if (Array.from(selectedFields.entries()).length > 0) {
    deleteOrAddButton = <Button flat auto color={'error'} icon={<Trash size="16px"/>} size="md" onClick={handleFieldDelete}>
      Delete
    </Button>
  } else {
    deleteOrAddButton = <Button flat auto icon={<Add size="16px"/>} size="md" onClick={handleFieldAdd}>
      Add
    </Button>
  }

  return <>
    {renderFieldTable}
    <div style={{
      paddingLeft: '15px',
      paddingRight: '15px',
      display: 'flex',
    }}>
      {deleteOrAddButton}
      <Spacer x={0.5}/>
      <Button flat auto color={'secondary'} icon={<Refresh2 size="16px"/>} size="md"
              disabled={isPristine}
              onClick={handleFieldsReset}>
        Reset
      </Button>
    </div>
  </>
}

function DirtyCloseModalButton(props: { unsavedChangesWarning: boolean, onClick: () => void }) {
  return <Button light={!props.unsavedChangesWarning} rounded color="error" onClick={props.onClick
  } auto style={{
    padding: 0,
    paddingRight: props.unsavedChangesWarning ? '10px' : '5px',
    paddingLeft: props.unsavedChangesWarning ? '10px' : '5px',
  }}>
    {props.unsavedChangesWarning ? <><Warning2 variant={'Bulk'} size={24}/>&nbsp;<strong>You may have unsaved
        changes.</strong>&nbsp; Press again to confirm&nbsp;</> :
      <CloseCircle style={{padding: 0}} size="32" variant="Bulk"/>}
  </Button>
}

const CreateModal = (
  {
    section,
    showDialog,
    closeDialog,
    onSave,
    existingSections
  }: CreateModalProps) => {

  const [currentTab, setCurrentTab] = useState(0)
  const [sectionData, setSectionData] = useState<Section | BaseSection | undefined>(
    section ?? getBlankSection() as unknown as Section
  )
  const [isLoading, setIsLoading] = useState(false)
  const [saveConfirm, setSaveConfirm] = useState(false)
  const [showDialogStateful, setShowDialogStateful] = useState(showDialog)
  const [unsavedChangesWarning, setUnsavedChangesWarning] = useState(false)
  const [isModified, setIsModified] = useState(false)
  const handleEscapeKeyPress = (e:any) => {
    if (e.key === 'Escape') {
      handleUserDialogClose(true)
    }
  }

  function handleUserDialogClose(force = false) {
    if (unsavedChangesWarning) {
      setShowDialogStateful(false)
      setTimeout(() => {
        closeDialog()
      }, force ? 0 : 400)
    } else {
      if (isModified) {
        setUnsavedChangesWarning(true)
        setTimeout(() => {
          setUnsavedChangesWarning(false)
        }, 3000)
      } else {
        closeDialog()
      }
    }
  }

  // on component mount, add an event listener that will check for the escape key being pressed and only if it's the escape key will call handleUserDialogClose
  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress, false)
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress, false)
    }
  }, [])


  const [alert, setAlert] = useState<{
    type: 'success' | 'error' | 'warning' | 'info',
    message: string,
    title: string
  } | undefined>(undefined)

  function errorCheckSection() {
    if (typeof sectionData?.section_fields !== 'undefined') {
      for (const field of sectionData.section_fields) {
        if (field.name === '') {
          setAlert({
            title: 'Field name cannot be empty',
            message: 'Please remove the field or give it a name',
            type: 'error'
          })
          return
        } else if ((field ?? '').name.includes(' ')) {
          setAlert({
            title: `Field name "${field.name}" cannot contain spaces`,
            message: 'Please remove the spaces from the field name',
            type: 'error'
          })
          return
        } else if (/[A-Z]/.test(field.name)) {
          setAlert({
            title: `Field name "${field.name}" cannot contain capital letters`,
            message: 'Please remove the capital letters from the field name',
            type: 'error'
          })
          return
        } else if (!/^[a-zA-Z0-9_]+$/.test(field.name)) {
          setAlert({
            title: `Field name "${field.name}" cannot contain special characters`,
            message: 'Only letters, numbers and underscores are allowed. Please remove the special characters from the field name',
            type: 'error'
          })
          return

        } else {
          setAlert(undefined)
        }
      }
    }
  }

  async function handleSectionSave() {
    if (saveConfirm) {
      setIsLoading(true)
      // This timeout is to allow the user to see the loading state, otherwise it's too fast
      setTimeout(async () => {
        try {
          await onSave(sectionData as Section)
          setShowDialogStateful(false)
          setTimeout(() => {
            setIsLoading(false)
            closeDialog()
            /**
             * Note on Jan. 20 2023
             *
             * Bug:
             * NextUI has a bug where the page overflow does not release when the modal closes.
             * This is a mitigation for that bug.
             *
             * Description & Information:
             * I assume that the error occurs with the inline editing function of the field naming.
             * Since we use a popup within a modal, the two conflict, causing the above CSS rule to trigger and not unset.
             *
             * Version with bug:
             * @nextui-org/react@1.0.0-beta.10
             *
             * Here is the issue on GH: https://github.com/nextui-org/nextui/issues/500
             * They marked it as resolved, but people still claim to have the issue.
             */
            document.body.style.overflow = 'unset'
          }, 500)
        } catch (e) {
          setAlert({
            title: 'Error saving section',
            message: 'An error occurred while saving the section. Please try again.',
            type: 'warning'
          })
          setIsLoading(false)
          setSaveConfirm(false)
          return
        }

      }, 500)
    } else {
      setSaveConfirm(true)
      setTimeout(() => {
        setSaveConfirm(false)
      }, 3000)
    }
  }

  useEffect(errorCheckSection, [sectionData])

  const tabs = [
    {
      name: 'General',
      component: <GeneralSection
        setModified={setIsModified}
        showAlertHook={setAlert}
        section={sectionData as Section}
        reset={section}
        existingSections={existingSections}
        onChange={(change) => {
          setSectionData(change)
        }}/>,
      icon: <Setting variant="Bulk" style={{paddingLeft: 0}}/>
    },
    {
      name: 'Fields',
      component: <FieldsSection
        setModified={setIsModified}
        section={sectionData as Section}
        reset={section}
        showAlertHook={setAlert}
        onChange={(change) => {
          setSectionData(change as Section)
        }} allowedInputTypes={InputTypes}/>,
      icon: <Element3 variant="Bulk" style={{paddingLeft: 0}}/>
    },
  ]

  const statusPill = sectionStatusFormat(section)

  let dialogTitle = sectionData?.name
  if (typeof sectionData?.name === 'undefined' || sectionData?.name.trim() === '') {
    dialogTitle = 'New Section'
  }

  return <>
    <Modal
      closeButton={false}
      preventClose={true}
      aria-labelledby="modal-title"
      open={showDialogStateful}
      onClose={() => {
        setTimeout(() => {
          closeDialog()
        }, 300)
      }}
      width='900px'
      noPadding
    >
      {ShowAlertBox(alert)}
      <Modal.Body>
        <Navbar isBordered variant="static" disableShadow>
          <Navbar.Brand>
            <div style={{
              display: 'block'
            }}>
              <div>
                <Text size="$xs" color="inherit">
                  {section ? 'Edit' : 'Create New'} Section
                </Text>
              </div>
              <div>
                <Text b size="lg" color="inherit">
                  {dialogTitle}
                </Text>
                <Badge
                  color={statusPill.color}
                  variant="flat">
                  {statusPill.text}
                </Badge>
              </div>
            </div>
          </Navbar.Brand>

          <Navbar.Content
            enableCursorHighlight
            activeColor={typeof alert !== 'undefined' && alert.type === 'error' ? 'error' : 'primary'}
            variant="highlight-rounded"
          >
            {!unsavedChangesWarning && tabs.map((tab, index) => {
              return <Navbar.Link key={index} onClick={() => {
                document.body.style.overflow = 'auto'
                setCurrentTab(index)
              }
              } isActive={currentTab === index}>
                <div style={{
                  height: '20px',
                  padding: 0,
                  margin: 0
                }}>
                  {tab.icon}
                </div>
                {tab.name}
              </Navbar.Link>
            })}
            <DirtyCloseModalButton unsavedChangesWarning={unsavedChangesWarning} onClick={() => {
              handleUserDialogClose()
            }}/>
          </Navbar.Content>
        </Navbar>
        <div style={{
          padding: '20px',
          paddingTop: 0,
          display: 'block'
        }}>
          {tabs[currentTab].component}
        </div>
      </Modal.Body>
      <Modal.Footer style={{
        padding: '20px'
      }}>
        <Button
          auto
          color={saveConfirm ? 'warning' : 'primary'}
          disabled={(typeof alert !== 'undefined' && alert.type === 'error') || isLoading || typeof sectionData === 'undefined' || typeof sectionData.name === 'undefined' || sectionData.name === '' || typeof sectionData.section_fields === 'undefined' || sectionData.section_fields.length === 0}
          size="lg"
          style={{
            width: '100%',
            margin: 0
          }}
          onClick={handleSectionSave}>
          {isLoading ? <Loading
            size="sm"/> : (saveConfirm ? 'Are you sure? Click again to confirm' : (section ? 'Save' : 'Create'))}
        </Button>
      </Modal.Footer>
    </Modal></>
}
export default CreateModal
