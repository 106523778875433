import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {PODS_PHOTO_SET_CARD_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'


const StyledInlineBlocks = styled(LucidInlineBlocks)`
  .section-item-wrapper {
    display: grid;
    justify-items: center;
    align-items: center;

    @media (min-width: 640px) {
      grid-template-columns: auto auto repeat(2, 1fr);
    }

    h3 > span {
      display: block;
    }

    .content-wrapper {
      padding-left: 1rem;
    }

    .images-wrapper {
      display: grid;
      grid-template-columns: auto auto repeat(2, 1fr);
    }
  }
`

export default function PodsPhotoSetCard(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className + ' section podsphotosetcard-section stack'}
      name="podsphotosetcard"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
     <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <span className="title heading-1">
                <InlineTextWithVariables cms={cms} name="title" value={title} />
              </span>
              <span className="subtitle heading-2">
                <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
              </span>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables
                    name="intro"
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <LucidInlineBlocks
            className="pods-items"
            itemProps={props}
            name="pods_items"
            blocks={PODS_PHOTO_SET_CARD_ITEM_BLOCKS}
          />
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext">
                <RichtextWithVariables
                  name="outro"
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          {button?.label && (
            <div className="button-container">
              <SectionButton
                href={button.url}
                label={button.label}
                cms={cms}
                name="button"
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

PodsPhotoSetCard.StyleCore = styleCore
