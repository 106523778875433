import type {Field} from '@einsteinindustries/tinacms'
import type {
  BlocksControlsProps
} from '@einsteinindustries/react-tinacms-inline/dist/src/blocks/inline-block-field-controls'
import {FocusRingOptions} from '@einsteinindustries/react-tinacms-inline/dist/src/styles'
import React, {useEffect, useState} from 'react'

type LucidInlineGroupsProps = {
  cms: boolean,
  name: string;
  fields?: Field[];
  insetControls?: boolean;
  focusRing?: boolean | FocusRingOptions;
  children?: any;
}

type LucidBlocksControlsProps = {
  cms: boolean,
  className?: string
} & BlocksControlsProps

export function LucidInlineGroups({cms, children, ...props} : LucidInlineGroupsProps) {

  const [inlineGroupComponent, setInlineGroupComponent] = useState<JSX.Element | null>(null)

  useEffect(() => {
    async function loadTina() {
      const {InlineGroup} = await import('@einsteinindustries/react-tinacms-inline')
      setInlineGroupComponent(<InlineGroup {...props}>{children}</InlineGroup>)
    }
    if (cms) {
      loadTina()
    }
  }, [])

  if (cms) return inlineGroupComponent
  return <div>{children}</div>
}

export function LucidBlocksControls({cms, ...props}: LucidBlocksControlsProps) {

  const [blockControlsComponent, setBlockControlsComponent] = useState<JSX.Element | null>(null)
  useEffect(() => {
    async function loadTina() {
      const {BlocksControls} = await import('@einsteinindustries/react-tinacms-inline')
      setBlockControlsComponent(
        <BlocksControls {...props}>
          <div style={{minWidth: '60px', minHeight: '60px'}} className={props.className ?? ''}>{props.children}</div>
        </BlocksControls>
      )
    }

    if (cms) {
      loadTina()
    }
  }, [props.customActions])

  if (cms) return blockControlsComponent

  return <div className={props.className ?? ''}>{props.children}</div>
}
