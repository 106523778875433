import React, {ChangeEvent, useContext, useEffect, useMemo, useState} from 'react'
import {
  Badge,
  Button,
  Card,
  Collapse, Dropdown,
  FormElement,
  Grid,
  Input,
  Link,
  Loading,
  Modal, SimpleColors, Spacer,
  Spinner, Table,
  Text,
  Textarea,
  Tooltip
} from '@nextui-org/react'
import {
  Add, ArrowDown2,
  ArrowDown3,
  ArrowLeft, ArrowUp2,
  Box1,
  BoxAdd, CloseCircle,
  DocumentText,
  Edit2, GlobalEdit,
  Information,
  MinusSquare,
  SearchNormal,
  Text as TextIcon,
  TextalignLeft,
  TextBlock,
  TextBold,
  TextItalic,
  TextUnderline,
  TickSquare,
  Trash,
  Warning2
} from 'iconsax-react'
import {v4 as uuidv4} from 'uuid'
import {useDebounce} from 'react-use'
import {Field, NextUIFieldProps} from '@/src/utils/shared/FormManagerFormNextUI'
import {
  configFields,
  configFieldsGrouping,
  defaultConfiguration,
  defaultTypographyGroupElements,
  fallbackFontField,
  previewText
} from '@/components/editor/typography/config'
import {
  GoogleFont,
  TypographyElement,
  TypographyFormProps,
  TypographyGroup,
  TypographyModalProps
} from '@/components/editor/typography/types'
import {StyleCoreHelpers, useStyleCoreTypography} from '@/components/shared/StyleCore'
import {NothingHereYet} from '@/components/managers/Toolbar/SectionManager/Modals/CreateModal'
import {TypekitFamily, TypekitKit} from '@/components/shared/externalTypes'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {UPDATE_SITE_BUILD_TYPEKIT_KITS} from '@/graphql/mutations'
import {LucidSiteContext} from '@/src/state/site/Store'
import {mutate} from 'swr'
import {GET_SITE_BUILD_TYPEKIT_KITS} from '@/graphql/queries'
import {useFormDirty} from '@/components/shared/useFormDirty'
import useGoogleFonts from '@/components/sections/shared/useGoogleFonts'

const TypographyFormListCreate = ({onCreate, buttonText, children, disabled, flat}: {
  onCreate: () => void,
  buttonText: string,
  children: JSX.Element,
  disabled: boolean | undefined,
  flat: boolean
}) => {
  return <Button
    disabled={disabled}
    flat={flat}
    color={'secondary'}
    style={{width: '100%'}}
    size={'lg'}
    onPress={onCreate}
    icon={children}
  >
    {buttonText}
  </Button>
}
const TypographyFormGroupListElement = ({element, onSelect}: {
  element: TypographyGroup,
  onSelect: (selected: TypographyGroup) => void
}) => {
  return <Card
    isPressable
    isHoverable
    variant="shadow"
    style={{width: '100%'}}
    onPress={() => {
      onSelect(element)
    }}
  >
    <Grid.Container gap={3} alignItems="center">
      <Grid xs={12}>
        <Box1 size={24}/>
      </Grid>
      <Grid xs={12}>
        <div>
          <Text b>{element.name} {element.isDefault && <Badge color="primary">Default</Badge>}</Text>
          {element.description &&
            <><br/><Text size={'$sm'}>{element.description}</Text></>
          }
        </div>
      </Grid>
    </Grid.Container>
  </Card>
}
const TypographyFormGroupList = ({typographyGroups, onSelect, show, typekitKits}: {
  typographyGroups: TypographyGroup[],
  onSelect: (selected: TypographyGroup) => void,
  show: boolean,
  typekitKits: string[]
}) => {
  const [showSources, setShowSources] = useState(false)
  if (!show) return null

  function createNewGroup() {
    onSelect({
      name: 'New Group',
      id: uuidv4(),
      description: '',
      isDefault: false,
      elements: [...defaultTypographyGroupElements]
    })
  }

  return <>
    <Text id="modal-title" size={24} style={{textAlign: 'center', width: '100%'}}>
      <TextIcon size={24} style={{marginRight: 5, paddingTop: 5}}/>
      StyleCore
      <Text b size={24}>
        Typography
      </Text>
    </Text>
    <Grid.Container gap={2} style={{marginBottom: 10}}>
      <Grid xs={8} sm={8}>
        <TypographyFormListCreate
          disabled={false}
          flat={false}
          onCreate={createNewGroup}
          buttonText={'Create new Typography Group'}>
          <BoxAdd/>
        </TypographyFormListCreate>
      </Grid>
      <Grid xs={4} sm={4}>
        <TypographyFormListCreate
          disabled={false}
          onCreate={() => setShowSources(!showSources)}
          flat={!showSources}
          buttonText={showSources ? 'Done' : 'Edit Font Sources'}>
          <GlobalEdit/>
        </TypographyFormListCreate>
      </Grid>
      {
        showSources && <FontSources typekitKits={typekitKits} groups={typographyGroups}/>
      }
      {
        typographyGroups.map((group, k) =>
          <Grid xs={24} sm={12} key={k}>
            <TypographyFormGroupListElement
              key={k}
              element={group}
              onSelect={onSelect}/>
          </Grid>)
      }
      {
        typographyGroups.length === 0 && <Grid xs={24} sm={12}>
          <NothingHereYet
            above
            type="typography group"
          />
        </Grid>
      }

    </Grid.Container>
  </>
}
const TypographyFormPreview = ({element}: {
  element: TypographyElement
}) => {
  const [demoType, setDemoType] = useState<'text' | 'paragraph' | 'custom'>('text')
  const [demoFontDecoration, setFontDecoration] = useState({
    bold: false,
    italic: false,
    underline: false
  })
  const [demoText, setDemoText] = useState({...previewText})

  function setFontDecorationBold() {
    setFontDecoration({...demoFontDecoration, bold: !demoFontDecoration.bold})
  }

  function setFontDecorationItalic() {
    setFontDecoration({...demoFontDecoration, italic: !demoFontDecoration.italic})
  }

  function setFontDecorationUnderline() {
    setFontDecoration({...demoFontDecoration, underline: !demoFontDecoration.underline})
  }

  function setDemoTypeText() {
    setDemoType('text')
  }

  function setDemoTypeParagraph() {
    setDemoType('paragraph')
  }

  function setDemoTypeCustom() {
    setDemoType('custom')
  }

  useGoogleFonts([[element.configuration.fonts.primary.name]])

  return <>
    <Card style={{paddingTop: 50, paddingBottom: 50, overflow: 'visible'}}>
      <div style={{position: 'absolute', display: 'flex', justifyContent: 'space-between', width: '100%', top: -25}}>
        <Card style={{width: 310, borderRadius: 999}}>
          <Button.Group rounded size={'sm'}>
            <Button flat={demoType !== 'text'} onPress={setDemoTypeText}><TextIcon size={18}
                                                                                   style={{paddingRight: 4}}/> Text</Button>
            <Button flat={demoType !== 'paragraph'} onPress={setDemoTypeParagraph}><TextalignLeft size={18}
                                                                                                  style={{paddingRight: 4}}/> Paragraph</Button>
            <Button flat={demoType !== 'custom'} onPress={setDemoTypeCustom}><DocumentText size={18}
                                                                                           style={{paddingRight: 4}}/> Custom</Button>
          </Button.Group>
        </Card>
        <Card style={{width: 163, borderRadius: 999}}>
          <Button.Group rounded size={'sm'}>
            <Button flat={!demoFontDecoration.bold} onPress={setFontDecorationBold}><TextBold size={18}/></Button>
            <Button flat={!demoFontDecoration.italic} onPress={setFontDecorationItalic}><TextItalic size={18}/></Button>
            <Button flat={!demoFontDecoration.underline} onPress={setFontDecorationUnderline}><TextUnderline size={18}/></Button>
          </Button.Group>
        </Card>
      </div>
      <Text style={{
        ...element.configuration,
        width: '100%',
        textAlign: 'center',
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: (!demoFontDecoration.bold ? (element?.configuration?.fontWeight ?? 'normal') : (Number(element?.configuration?.fontWeight ?? 400) + 300)),
        fontFamily: `'${element.configuration.fonts.primary.name}', '${element.configuration.fonts.fallback.name}', sans-serif`,
        fontStyle: (demoFontDecoration.italic ? 'italic' : 'normal'),
        textDecoration: (demoFontDecoration.underline ? 'underline' : 'none')
      }}>{demoText[demoType]}</Text>
      <hr style={{padding: 0, margin: 0}}/>
      {demoType === 'custom' &&
        <div style={{display: 'flex', position: 'absolute', bottom: 10, justifyContent: 'center', width: '100%'}}>
          <Card variant={'flat'} style={{width: 310, borderRadius: 999}}>
            <Input size={'sm'} placeholder="Enter demo text" onChange={(e) => {
              setDemoText({...demoText, custom: e.target.value})
            }}/>
          </Card>
        </div>}
    </Card>
  </>
}
const TypographyFieldFontSelection = ({fonts, onSelect}: {
  fonts: GoogleFont[],
  onSelect: (selected: GoogleFont) => void
}) => {
  const [selectedFont, setSelectedFont] = useState<GoogleFont | undefined>(undefined)
  const [fontsFiltered, setFontsFiltered] = useState<GoogleFont[]>(fonts)

  useEffect(() => {
    if (typeof selectedFont !== 'undefined')
      onSelect(selectedFont)
  }, [selectedFont])

  return <Grid.Container gap={0.2}>
    <Grid xs={24} style={{width: '100%', paddingTop: 10}}>
      <Input
        clearable
        fullWidth
        style={{width: '100%'}}
        onChange={(e) => {
          const value = e.target.value
          if (value === '') {
            setFontsFiltered(fonts)
          } else {
            setFontsFiltered(fonts.filter((font) => font.name.toLowerCase().includes(value.toLowerCase())))
          }
        }
        }
        color="primary"
        placeholder="Search Fonts"
        contentLeft={<SearchNormal/>}
      />
    </Grid>
    <Grid xs={24}>
      <div style={{
        height: '100%',
        width: '100%',
        padding: 10,
        overflowY: 'scroll'
      }}>
        <Table
          aria-label="Example static collection table"
          css={{
            height: 'auto',
            minWidth: '100%',
          }}
          selectionMode="single"
          selectedKeys={selectedFont ? [`${selectedFont.index}`] : []}
          onSelectionChange={(key) => {
            setSelectedFont(
              fontsFiltered.filter(
                (e) =>
                  // TODO: update type gymnastics - NextUI does not document the `Selection` Type properly
                  `${e.index}` === (key as unknown as {
                    currentKey: string
                  }).currentKey)[0]
            )
          }}
        >
          <Table.Header>
            <Table.Column>NAME</Table.Column>
            <Table.Column>SOURCE</Table.Column>
            <Table.Column>TYPE</Table.Column>
          </Table.Header>
          <Table.Body items={fontsFiltered}>
            {
              (font) => {
                let elements = [font.name, 'N/A', 'N/A']
                if (typeof font.category !== 'undefined') {
                  elements = [font.name, font.category[0], font.category[1]]
                }
                const color = elements[1] === 'TypeKit' ? 'primary' : 'warning'
                return <Table.Row key={font.index}>
                  <Table.Cell>
                    {elements[0]}
                  </Table.Cell>
                  <Table.Cell>
                    <Badge color={color}>
                      {elements[1]}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <Badge variant="flat">
                      {elements[2]}
                    </Badge>
                  </Table.Cell>
                </Table.Row>
              }
            }
          </Table.Body>
          <Table.Pagination
            shadow
            noMargin
            align="center"
            rowsPerPage={15}
          />
        </Table>

      </div>
    </Grid>
  </Grid.Container>
}
const TypographyFieldFont = ({
                               element,
                               onChange,
                               typekitKits
                             }: {
  element: TypographyElement,
  typekitKits: string[],
  onChange: (selected: TypographyElement) => void
}) => {
  const [selectedFont, setSelectedFont] = useState(element.configuration.fonts.primary)
  const [openFontSelection, setOpenFontSelection] = useState(false)
  const [selectedFallbackFont, setSelectedFallbackFont] = useState(element.configuration.fonts.fallback)
  const [loadingFontList, setLoadingFontList] = useState(true)
  const [menuItems, setMenuItems] = useState<GoogleFont[]>([])
  const [fetchError, setFetchError] = useState(false)
  useEffect(() => {
    if (selectedFont) {
      const font = menuItems.find((item) => item.name === selectedFont.name)
      if (font) {
        onChange({
          ...element,
          configuration: {
            ...element.configuration,
            fonts: {
              ...element.configuration.fonts,
              primary: {
                ...element.configuration.fonts.primary,
                name: font.name
              },
              fallback: {
                ...element.configuration.fonts.fallback,
                name: selectedFallbackFont.name
              }
            }
          }
        })
      }
    }
  }, [selectedFont, selectedFallbackFont])
  useEffect(() => {
    setLoadingFontList(true)

    async function fetchFonts() {
      const response = await fetch('/api/googleFontsList')
      const data = await response.json()
      if (!response.ok || data.error) {
        setFetchError(true)
        setLoadingFontList(false)
        return
      }
      const googleFonts = data.items.map((item: any, index: number) => {
        return {
          name: item.family,
          category: ['Google Fonts', item.category],
          variants: item.variants.length,
          index
        }
      })
      const typekitFontsAPI: TypekitKit[] = await Promise.all(typekitKits.map((kit) => {
        return getProjectInfoFromKit(kit)
      }).map(p => p.catch(e => null)))

      let indexOffset = 0

      const typeKitFonts = typekitFontsAPI.map((kit, index) => {
        if (kit === null) return []
        return kit.kit.families.map((family, index) => {
          return {
            name: family.slug,
            category: ['TypeKit', family.subset],
            variants: family.variations.length,
            index: googleFonts.length + indexOffset++
          }
        })
      }).flat().filter((font) => font !== null)

      setMenuItems([...typeKitFonts, ...googleFonts])
      setLoadingFontList(false)
    }

    fetchFonts()
  }, [])
  const onFallbackFontChange = (e: string) => {
    setSelectedFallbackFont({
      name: e
    })
  }
  const onPrimaryFontChange = (selected: GoogleFont) => {
    setSelectedFont(selected)
    setOpenFontSelection(false)
  }
  const fontButtonTriggerName = selectedFont.name ?? 'Select a Primary Font'
  return <TypographyFieldWrapper title={'Font'} icon={<TextIcon size={'26'} variant="Bulk"/>}>
    <Grid.Container gap={.5}>
      <Grid xs={24}>
        <Button
          flat
          disabled={loadingFontList || fetchError}
          style={{width: '100%'}}
          onPress={() => setOpenFontSelection(!openFontSelection)}>
          {loadingFontList ? <Loading/> : <>{fontButtonTriggerName}<ArrowDown3 size={16} variant="Bulk"/> </>}
        </Button>

      </Grid>
      {
        fetchError && <Grid xs={12}>
          <Card style={{padding: '15px'}} variant={'flat'}>
            <Text color={'$red600'}><Warning2/> Error while fetching fonts. </Text>
          </Card>
        </Grid>
      }
      <Grid xs={12}>
        {(!loadingFontList && openFontSelection) &&
          <TypographyFieldFontSelection fonts={menuItems} onSelect={onPrimaryFontChange}/>}
      </Grid>
      <Grid xs={12}>
        <Card variant={'bordered'} style={{padding: 10}} color={'primary'}>
          <Grid.Container gap={.5} alignItems={'center'} alignContent={'center'}>
            <Grid xs={1}>
              <Text color="primary"><Information variant={'Bulk'} size="20px"/></Text>
            </Grid>
            <Grid xs={11}>
              <Text size={'$sm'} color={'$gray600'}>
                All available fonts can be found on &nbsp;
                <Link href="https://fonts.google.com/" isExternal>
                  Google Fonts
                </Link>
              </Text>
            </Grid>
          </Grid.Container>
        </Card>
      </Grid>
      <Grid xs={12}>
        <Field field={fallbackFontField} onChange={onFallbackFontChange} initialValue={selectedFallbackFont.name}/>
      </Grid>
    </Grid.Container>
  </TypographyFieldWrapper>
}
const TypographyFieldWrapper = ({children, title, description, icon}: {
  children: React.ReactNode,
  title: string,
  description?: string,
  icon: any
}) => {
  return <Card style={{padding: 25}} variant="bordered">
    <Text h3>{icon} {title}</Text>
    {description && <Text size={'$sm'}>{description}</Text>}
    {children}
  </Card>
}

function EditElementBasics(props: {
  show: boolean,
  onChange: (e: ChangeEvent<FormElement>, field: keyof TypographyElement) => void,
  selectedElement: TypographyElement,
  onPress: () => void
}) {
  if (!props.show) return <></>

  function handleOnChangeName(e: ChangeEvent<FormElement>) {
    props.onChange(e, 'name')
  }

  function handleOnChangeSelectors(e: ChangeEvent<FormElement>) {
    props.onChange(e, 'selectors')
  }

  const appliedAtSectionLevel = props.selectedElement?.selectors?.includes('-section')
  return <>
    <Grid xs={24}>
      <Grid.Container gap={2} style={{paddingTop: '30px'}}>
        <Grid>
          <Input
            onChange={handleOnChangeName}
            initialValue={props.selectedElement.name}
            bordered
            labelPlaceholder="Name"
            color="default"/>
        </Grid>
        <Grid>
          <Textarea
            onChange={handleOnChangeSelectors}
            initialValue={props.selectedElement?.selectors}
            bordered
            labelPlaceholder="Additional Selectors"
            status={appliedAtSectionLevel ? 'error' : 'default'}
            helperText={appliedAtSectionLevel ? 'Overrides are applied at a section level' : undefined}
            helperColor="error"
            color="default"/>
        </Grid>
        <Grid>
          <Button
            auto
            disabled={appliedAtSectionLevel}
            onPress={props.onPress}>
            Done
          </Button>
        </Grid>
      </Grid.Container>
    </Grid>
  </>
}


async function getProjectInfoFromKit(kit: string) {
  const response = await fetch('/api/typekit-proxy', {
    method: 'POST',
    body: JSON.stringify({kit}),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const data = await response.json()
  if (!response.ok || data.error) {
    throw new Error('Error while fetching Typekit project info')
  }
  return data
}

function getFontFamiliesUsed(fontFamilies: TypekitFamily[], groups: TypographyGroup[]) {
  return fontFamilies.filter((family) => {
    return groups.some((group) => {
      return group.elements.some((element) => {
        return element.configuration.fonts.primary.name === family.slug || element.configuration.fonts.fallback.name === family.slug
      })
    })
  })
}

const SourceTableStatus = ({projectInfo, groups, error}: {
  projectInfo: TypekitKit | null,
  groups: TypographyGroup[],
  error: boolean
}) => {
  const fontFamilies = projectInfo?.kit?.families ?? []
  const fontFamiliesUsed = getFontFamiliesUsed(fontFamilies, groups)

  function getColor() {
    if (fontFamiliesUsed.length === fontFamilies.length) return 'success'
    if (fontFamiliesUsed.length === 0) return 'error'
    return 'warning'
  }

  function countVariations(family: TypekitFamily[]) {
    return family.map((e) => e.variations.length).reduce((partialSum, a) => partialSum + a, 0)
  }

  if (error) {
    return <Tooltip content={'Could not load any details on this Kit'} color="error">
      <Button color='error' size="xs" rounded>
        Error
      </Button>
    </Tooltip>
  }

  return <Dropdown>
    <Dropdown.Button flat color={getColor()} size="xs" rounded>
      {countVariations(fontFamiliesUsed)} / {countVariations(fontFamilies)} fonts used
    </Dropdown.Button>
    <Dropdown.Menu
      aria-label="Multiple selection actions"
      color="secondary"
      selectionMode="single"
    >
      {
        fontFamilies.map((family) => {
          const icon = fontFamiliesUsed.includes(family) ? <TickSquare variant="Bulk"/> : <CloseCircle variant="Bulk"/>
          const color = fontFamiliesUsed.includes(family) ? 'success' : 'error'
          const description = `${family.css_names.join(',')} - ${family.variations.length} Variation${family.variations.length > 1 ? 's' : ''}`
          return <Dropdown.Item
            key={family.id}
            icon={icon}
            color={color}
            showFullDescription
            description={description}
          >
            {family.name}
          </Dropdown.Item>
        })
      }
    </Dropdown.Menu>
  </Dropdown>
}

function NewSourceInput({onChange, typekitKits, show}: {
  onChange: (e: string) => void,
  typekitKits: string[],
  show: boolean
}) {
  const [addSourceValidation, setAddSourceValidation] = useState('invalid')
  const [addSourceValue, setAddSourceValue] = useState('')
  useDebounce(async () => {
    if (addSourceValue === '') {
      setAddSourceValidation('invalid')
      return
    }
    if (typekitKits.includes(addSourceValue)) {
      setAddSourceValidation('invalid')
      return
    }
    setAddSourceValidation('progress')
    try {
      await getProjectInfoFromKit(addSourceValue)
      setAddSourceValidation('valid')
    } catch (e) {
      setAddSourceValidation('invalid')
    }
  }, 1000, [addSourceValue])

  function onFieldChangeHandler(e: React.ChangeEvent<FormElement>) {
    setAddSourceValidation('progress')
    setAddSourceValue(e.target.value)
  }

  function onSubmitHandler() {
    onChange(addSourceValue)
  }

  if (!show) {
    return null
  }

  const inputProps = {
    color: 'primary' as SimpleColors,
    labelPlaceholder: addSourceValidation === 'progress' ? 'Checking...' : 'Typekit Kit ID',
    contentLeft: addSourceValidation === 'progress' ? <Loading size="xs"/> : null,
    placeholder: 'Typekit Kit ID',
    status: (addSourceValidation === 'valid' ? 'success' : 'error') as SimpleColors,
    onChange: onFieldChangeHandler
  }

  const buttonProps = {
    disabled: addSourceValidation !== 'valid',
    children: <><Add/> {addSourceValidation !== 'valid' ? 'Invalid Kit ID' : 'Add'}</>,
    onPress: onSubmitHandler
  }

  return <div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 30, display: 'flex', gap: 10}}>
    <Input {...inputProps}/>
    <Button {...buttonProps}/>
  </div>
}

const FontSources = ({typekitKits, groups}: {
  typekitKits: string[],
  groups: TypographyGroup[]
}) => {
  const makeFormDirty = useFormDirty()
  const [tableState, setTableState] = useState<{
    [key: string]: {
      isLoading: boolean,
      error: boolean,
      projectInfo: TypekitKit | null
    }
  }>({})

  const [showAddSource, setShowAddSource] = useState(false)
  const columns = [
    {name: 'PROJECT', uid: 'name'},
    {name: 'SOURCE', uid: 'role'},
    {name: 'STATUS', uid: 'status'},
    {name: 'ACTIONS', uid: 'actions'},
  ]

  const [{site_build_id}] = useContext(LucidSiteContext)

  async function fetchProjectInfo(project: string) {
    setTableState((tableState) => ({
      ...tableState,
      [project]: {
        isLoading: true,
        error: false,
        projectInfo: null
      }
    }))
    try {
      const info = await getProjectInfoFromKit(project)
      setTableState((tableState) => ({
        ...tableState,
        [project]: {
          isLoading: false,
          error: false,
          projectInfo: info
        }
      }))
    } catch (e) {
      setTableState((tableState) => ({
        ...tableState,
        [project]: {
          isLoading: false,
          error: true,
          projectInfo: null
        }
      }))
    }
  }

  async function updateTypekitKit(typekit_kits: string[]) {
    await lucidDataFetcherV2(UPDATE_SITE_BUILD_TYPEKIT_KITS, {
      site_build_id,
      typekit_kits
    })
    await mutate([GET_SITE_BUILD_TYPEKIT_KITS, {site_build_id}])
    makeFormDirty()
  }

  async function addKit(kit: string) {
    await updateTypekitKit([...typekitKits, kit])
    setShowAddSource(false)
  }

  async function removeKit(kit: string) {
    await updateTypekitKit(typekitKits.filter((k) => k !== kit))
  }

  // Using use memo here to avoid calling the API on every render
  useMemo(() => {
    typekitKits.forEach((project) => {
      fetchProjectInfo(project)
    })
  }, [typekitKits])

  const tableItems = [{project: 'google', groups}, ...typekitKits.map((project) => ({project, groups}))]
  const addSourceArrow = !showAddSource ? <ArrowDown2 size={18}/> : <ArrowUp2 size={18}/>

  function addSourceOnPressHandler() {
    setShowAddSource(!showAddSource)
  }

  return <Card variant="flat">
    <Card.Body>
      <Table
        bordered
        shadow={false}>
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column
              key={column.uid}
              hideHeader={column.uid === 'actions'}
              align={column.uid === 'actions' ? 'center' : 'start'}
            >
              {column.name}
            </Table.Column>
          )}
        </Table.Header>
        <Table.Body items={tableItems}>
          {(item) => {
            const isInUse = getFontFamiliesUsed(tableState[item.project]?.projectInfo?.kit?.families ?? [], groups).length > 0
            if (item.project === 'google') {
              return <Table.Row key='google'>
                <Table.Cell>
                  Default Fonts
                </Table.Cell>
                <Table.Cell>
                  <Text size={'$sm'}>Google Fonts</Text>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    color={'success'}
                    auto
                    rounded
                    flat
                    size="xs"
                    style={{marginTop: 5, marginBottom: 5}}>
                    Dynamically Imported
                  </Button>
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
              </Table.Row>
            }

            function onRowPressHandler() {
              setTableState((tableState) => ({
                ...tableState,
                [item.project]: {
                  isLoading: true,
                  error: false,
                  projectInfo: tableState[item.project]?.projectInfo ?? null
                }
              }))
              removeKit(item.project)
            }

            return <Table.Row key={item.project}>
              <Table.Cell>
                <Link
                  color={tableState[item.project]?.error ? 'error' : 'primary'}
                  isExternal
                  href="https://fonts.adobe.com/my_fonts#web_projects-section">
                  {tableState[item.project]?.isLoading && <Loading size="xs"/>}
                  {tableState[item.project]?.projectInfo?.kit?.name}
                  {tableState[item.project]?.error && '(Not Found)'}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Text size={'$sm'}>Typekit - ({item.project})</Text>
              </Table.Cell>
              <Table.Cell>
                {tableState[item.project]?.isLoading ? <Loading size="xs"/> :
                  <SourceTableStatus error={tableState[item.project]?.error}
                                     projectInfo={tableState[item.project]?.projectInfo} groups={groups}/>}
              </Table.Cell>
              <Table.Cell>
                <Button
                  color={'error'}
                  auto
                  rounded
                  size="xs"
                  disabled={isInUse || tableState[item.project]?.isLoading}
                  style={{marginTop: 5, marginBottom: 5}}
                  onPress={onRowPressHandler}>
                  <Trash size={16}/> {isInUse ? 'In Use' : 'Remove Source'}
                </Button>
              </Table.Cell>
            </Table.Row>
          }}

        </Table.Body>
      </Table>
      <Button flat={!showAddSource} onPress={addSourceOnPressHandler} style={{marginTop: 15}}>
        {addSourceArrow} Add Source
      </Button>
      <NewSourceInput
        show={showAddSource}
        onChange={addKit}
        typekitKits={typekitKits}
      />

    </Card.Body>
  </Card>
}

const TypographyFormElement = ({element, onChange, onBack, onDelete, show, inModal, typekitKits}: {
  element: TypographyElement | null,
  onChange: (selected: TypographyElement) => void,
  onBack: () => void,
  onDelete: () => void,
  show: boolean,
  inModal: boolean,
  typekitKits: string[]
}) => {
  const [selectedElement, setSelectedElement] = useState<TypographyElement | null>(element)
  const [showEditBasics, setShowEditBasics] = useState(false)

  useEffect(() => {
    if (selectedElement === null) return
    onChange(selectedElement)
  }, [selectedElement])

  useEffect(() => {
    setSelectedElement(element)
  }, [element])

  if (!show || element === null || selectedElement === null) return null

  return <div style={{padding: 20, width: '100%'}}>
    <Grid.Container gap={2}>
      <Grid xs={24}>
        <Grid.Container gap={2}>
          <Grid xs={24}>
            <Button style={{width: '10px', marginRight: '10px'}} onPress={onBack} icon={<ArrowLeft/>}>Back</Button>
            <Button
              style={{width: '10px', marginRight: '10px'}}
              flat
              icon={<Edit2/>}
              onPress={() => {
                setShowEditBasics(!showEditBasics)
              }}>
              Edit Name
            </Button>
            <Button style={{width: '10px'}} flat onPress={() => {
              onDelete()
              onBack()
            }} color={'error'} icon={<Trash/>}>Delete</Button>
          </Grid>
          <EditElementBasics
            selectedElement={selectedElement}
            show={showEditBasics}
            onChange={(e, name) => {
              setSelectedElement({
                ...selectedElement,
                [name]: e.target.value
              })
            }}
            onPress={() => {
              setShowEditBasics(false)
            }}
          />
          <Grid xs={24}>
            <TextBlock width={40} size={30}/>
            <Text b h3 style={{paddingLeft: 10}}>{selectedElement.name} <Text size='$sm'>Selectors: <code>{StyleCoreHelpers.selectors.getTypographyBaseSelector(selectedElement)}</code></Text></Text>
          </Grid>
        </Grid.Container>
      </Grid>
      <Grid xs={24}>
        {
          !showEditBasics && <TypographyFormPreview element={selectedElement}/>
        }
      </Grid>
      <Grid xs={24} style={{width: '100%'}}>
        <Collapse.Group shadow style={{width: '100%'}}>
          <Collapse title={'Font'} subtitle={'Change both primary and fallback fonts'}>
            <Grid.Container gap={2}>
              <Grid xs={12}>
                <TypographyFieldFont element={selectedElement} typekitKits={typekitKits} onChange={(element) => {
                  setSelectedElement(element)
                }}/>
              </Grid>
            </Grid.Container>
          </Collapse>
          {
            configFieldsGrouping.map((group, index) => {
              return <Collapse key={index} title={group.name} subtitle={group.description}>
                <Grid.Container gap={2}>
                  {
                    group.fields.map((field, index) => {
                      return <Grid key={index} xs={12} md={4} lg={3}>
                        <Field enableInheritanceVisuals={false} field={configFields[field] as NextUIFieldProps} wrapWithGrid={false} onChange={(e) => {
                          setSelectedElement({
                            ...selectedElement,
                            configuration: {
                              ...selectedElement.configuration,
                              [field]: e
                            }
                          })
                        }} initialValue={selectedElement.configuration[field as keyof typeof selectedElement.configuration]}/>
                      </Grid>
                    })
                  }
                </Grid.Container>
              </Collapse>
            })
          }
        </Collapse.Group>
      </Grid>
    </Grid.Container>
  </div>
}
export const TypographyFormElementListElement = ({element, onSelect, disabled}: {
  element: TypographyElement,
  onSelect: (selected: TypographyElement) => void,
  disabled?: boolean
}) => {
  useGoogleFonts([[element.configuration.fonts.primary.name]])
  return <Card
    isPressable={!disabled}
    isHoverable={!disabled}
    variant={disabled ? 'bordered' : 'shadow'}
    style={{width: '100%'}}
    onPress={() => {
      onSelect(element)
    }}>
    <Grid.Container gap={2}>
      <Grid xs={24}>
        <Text
          b
          style={{
            ...element.configuration,
            fontFamily: `'${element.configuration.fonts.primary.name}', '${element.configuration.fonts.fallback.name}', sans-serif`
          }}>
          {element.name}
        </Text>
      </Grid>
    </Grid.Container>
  </Card>
}

function EditGroupInfo(props: {
  show: boolean,
  onChangeName: (e: ChangeEvent<FormElement>) => void,
  groupState: TypographyGroup | null,
  onChangeDescription: (e: ChangeEvent<FormElement>) => void,
  onPressFinish: () => void
}) {
  if (!props.show || props.groupState === null) return <></>
  return <Grid xs={24}>
    <Grid.Container gap={2} style={{paddingTop: '30px'}}>
      <Grid>
        <Input
          onChange={props.onChangeName}
          initialValue={props.groupState?.name}
          bordered
          labelPlaceholder="Name"
          color="default"/>
      </Grid>
      <Grid>
        <Input
          bordered
          onChange={props.onChangeDescription}
          initialValue={props.groupState?.description}
          labelPlaceholder="Description"
          color="default"/>
      </Grid>
      <Grid>
        <Button
          auto
          onPress={props.onPressFinish}>
          Done
        </Button>
      </Grid>
    </Grid.Container>
  </Grid>
}

const TypographyFormElementList = ({group, onSelect, onChange, onBack, onDelete, show, onMakeDefault}: {
  group: TypographyGroup | null,
  onSelect: (selected: TypographyElement) => void,
  onChange: (selected: TypographyGroup) => void,
  onBack: () => void,
  onDelete: () => void,
  show: boolean,
  onMakeDefault: () => void
}) => {
  const [groupState, setGroupState] = useState<TypographyGroup | null>(group)
  const [showEditGroup, setShowEditGroup] = useState(false)

  function createNewElement() {
    const newElement = {
      name: 'New Element',
      id: uuidv4(),
      ...defaultConfiguration
    } as TypographyElement
    if (groupState === null) return
    setGroupState({
      ...groupState,
      elements: [...groupState.elements, newElement]
    } as TypographyGroup)
    onSelect(newElement)
  }

  useEffect(() => {
    setGroupState(group)
  }, [group])

  useDebounce(() => {
    if (groupState === null) return
    onChange(groupState)
  }, 150, [groupState])

  if (!show || group === null) return null

  return <div style={{padding: 20}}>
    <Grid.Container gap={1}>
      <Grid xs={24}>
        <Button
          style={{width: '10px'}}
          onPress={onBack}
          icon={<ArrowLeft/>}>
          Back
        </Button>
        <Button
          style={{width: '10px', marginLeft: '10px'}}
          onPress={group.isDefault ? undefined : onMakeDefault}
          flat={!group.isDefault}
          shadow={group.isDefault}
          icon={group.isDefault ? <TickSquare/> : <MinusSquare/>}>
          {group.isDefault ? 'Default' : 'Make Default'}
        </Button>
        <Button
          style={{width: '10px', marginLeft: '10px'}}
          flat
          icon={<Edit2/>}
          onPress={() => {
            setShowEditGroup(!showEditGroup)
          }}>
          Edit Group
        </Button>
        <Button
          style={{width: '10px', marginLeft: '10px'}}
          flat
          onPress={() => {
            onDelete()
            onBack()
          }}
          color={'error'}
          icon={<Trash/>}
          disabled={group.isDefault}>
          Delete
        </Button>
      </Grid>
      <EditGroupInfo
        show={showEditGroup}
        onChangeName={(e) => {
          if (groupState === null) return
          setGroupState({
            ...groupState,
            name: e.target.value
          })
        }}
        groupState={groupState}
        onChangeDescription={(e) => {
          if (groupState === null) return
          setGroupState({
            ...groupState,
            description: e.target.value
          })
        }}
        onPressFinish={() => {
          setShowEditGroup(false)
        }}
      />
      <Grid xs={24}>
        <Text h2>
          {groupState?.name}
          {<Text size={'$sm'}>{groupState?.description}</Text>}
          {group.isDefault && <Text><Badge color="primary">Default</Badge></Text>}
        </Text>
      </Grid>
      {
        groupState?.elements.map((element, k) =>
          <Grid sm={12} key={k}>
            <TypographyFormElementListElement element={element} onSelect={onSelect}/>
          </Grid>
        )
      }
      <Grid xs={24} sm={12}>
        <TypographyFormListCreate
          disabled={false}
          flat={false}
          onCreate={createNewElement}
          buttonText={'Create new'}>
          <Add/>
        </TypographyFormListCreate>
      </Grid>
    </Grid.Container>
  </div>
}
const TypographyForm = ({typographyGroups, onChange, inModal, typekitKits}: TypographyFormProps) => {
  const [groups, setGroups] = useState<TypographyGroup[]>(typographyGroups)
  const [selectedGroup, setSelectedGroup] = useState<TypographyGroup | null>(null)
  const [selectedElement, setSelectedElement] = useState<TypographyElement | null>(null)
  const [issuingGQLRequest, setIssuingGQLRequest] = useState('inactive')
  useEffect(() => {
    if (selectedGroup === null) return
    let found = false
    const newGroups = groups.map((group) => {
      if (group.id === selectedGroup?.id) {
        found = true
        return selectedGroup
      } else {
        return group
      }
    })

    if (!found) {
      setGroups([...groups, selectedGroup])
    } else {
      setGroups(newGroups)
    }

  }, [selectedGroup])

  useEffect(() => {
    if (selectedGroup === null) return
    if (selectedElement === null) return

    const element = selectedGroup?.elements.findIndex((element) => element.id === selectedElement?.id)
    let elements = selectedGroup.elements

    if (element === undefined || element === -1) {
      elements = [...selectedGroup.elements, selectedElement] as TypographyElement[]
    } else {
      elements = selectedGroup.elements.map((element) => {
        if (element.id === selectedElement?.id) {
          return selectedElement
        } else {
          return element
        }
      })
    }

    setSelectedGroup({
      ...selectedGroup,
      elements: elements as TypographyElement[]
    })

  }, [selectedElement])

  const handlers = {
    group: {
      onBack: () => setSelectedGroup(null),
      onSelect: setSelectedElement,
      onChange: setSelectedGroup,
      onDelete: () => {
        const groupsNew = groups.filter((g) => g.id !== selectedGroup?.id)
        setGroups(groupsNew)
        setSelectedGroup(null)
      },
      onMakeDefault: () => {
        const groupsNew = groups.map((g) => {
          if (g.id === selectedGroup?.id) {
            return {
              ...g,
              isDefault: true
            }
          } else {
            return {
              ...g,
              isDefault: false
            }
          }
        })
        setGroups(groupsNew)
        setSelectedGroup(groupsNew.filter((g) => g.id === selectedGroup?.id)[0])
      }
    },
    element: {
      onBack: () => setSelectedElement(null),
      onChange: setSelectedElement,
      onDelete: () => {
        if (selectedGroup === null) return
        const elements = selectedGroup.elements.filter((e) => e.id !== selectedElement?.id)
        setSelectedGroup({
          ...selectedGroup,
          elements
        })
      }
    }
  }

  return <>
    <TypographyFormGroupList
      show={selectedGroup === null}
      typographyGroups={groups}
      onSelect={setSelectedGroup}
      typekitKits={typekitKits}
    />
    <TypographyFormElementList
      group={selectedGroup}
      show={selectedGroup !== null && selectedElement === null}
      {...handlers.group}
    />
    <TypographyFormElement
      show={selectedGroup !== null && selectedElement !== null}
      element={selectedElement}
      {...handlers.element}
      inModal={inModal}
      typekitKits={typekitKits}
    />
    <Button size="lg" disabled={issuingGQLRequest !== 'inactive'} style={{
      width: '100%',
    }} onPress={() => {
      setIssuingGQLRequest('issued')
      onChange(groups)
      setIssuingGQLRequest('cooldown')
      setTimeout(() => {
        setIssuingGQLRequest('inactive')
      }, 1500)
    }}>
      {
        issuingGQLRequest === 'issued' && <Spinner/>
      }
      {
        issuingGQLRequest === 'cooldown' && 'Changes saved'
      }
      {
        issuingGQLRequest === 'inactive' && 'Save changes'
      }
    </Button>
  </>

}
export const TypographyModal = ({isOpen = false, ...props}: TypographyModalProps) => {
  const [siteTypographyGroups, updateTypographyGroups, used, typekitKits] = useStyleCoreTypography(true)

  const TypographyModalPrepared = () => {
    return <TypographyForm
      typekitKits={typekitKits}
      typographyGroups={siteTypographyGroups}
      inModal={props.inModal}
      onChange={updateTypographyGroups}/>
  }
  if (!props.inModal) {
    return <div style={{padding: 15, overflow: 'scroll'}}>
      <TypographyModalPrepared/>
    </div>
  }
  return <Modal
    closeButton
    aria-labelledby="modal-title"
    open={isOpen}
    onClose={() => {
      props.onClose()
    }}
    width={'1200px'}
  >
    <Modal.Body>
      <TypographyModalPrepared/>
    </Modal.Body>
  </Modal>
}
