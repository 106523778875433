import {Checkbox} from '@nextui-org/react'
import styled from 'styled-components'
import {useContext, useEffect} from 'react'
import {LucidSiteContext} from '@/src/state/site/Store'

const StyledSpan = styled.span`
  width: 90px;
  display: inline-block;
  padding: 6px;
`

export default function RevalidateOnFocusToggle() {
  const [{revalidateOnFocus}, lucidSiteDispatch] = useContext(LucidSiteContext)

  const disableRevalidateOnFocus = localStorage.getItem('revalidateOnFocus') === 'false'

  useEffect(() => {
    if (disableRevalidateOnFocus && revalidateOnFocus) {
      lucidSiteDispatch({type: 'UPDATE', payload: {revalidateOnFocus: false}})
    }
  }, [])

  return (
    <span>
      <Checkbox
        defaultSelected={revalidateOnFocus}
        size="xl"
        onChange={(revalidate) => {
          localStorage.setItem('revalidateOnFocus', String(revalidate))
          lucidSiteDispatch({
            type: 'UPDATE',
            payload: {revalidateOnFocus: revalidate}
          })
        }}
      />
      <StyledSpan>Revalidate On Focus</StyledSpan>
    </span>
  )
}
