import type {MediaList} from '@einsteinindustries/tinacms-core'
import type {ParsedUrlQuery} from 'querystring'
import {FieldOption} from '@/components/shared/externalTypes'
import {StyleCoreComponentName} from '@/components/shared/StyleCore'

export type PageBuildStatus =
  | 'PROPOSED'
  | 'IN_PROGRESS'
  | 'READY_FOR_REVIEW'
  | 'APPROVED'
  | 'PUBLISHING'
  | 'PUBLISHED'
  | 'PUBLISHED_MODIFIED'

export enum SectionStatusType {
  ACTIVE,
  HIDDEN,
  DISABLED
}

interface Base {
  id: string
  created_at: string
  updated_at: string
}

export interface PageBuild extends Base {
  locked: boolean
  body_sections: PageSection[]
  description: string
  page: Page
  page_id: string
  status: PageBuildStatus
  assigned_to_user?: User
  assigned_to_user_id?: string
  source_page_build?: PageBuild
  source_page_build_id?: string
  published_date?: string
  last_deployment_date?: string
}

export interface Page extends Base {
  canonical_url: any
  contact_formname: any
  ppc_phone_number: any
  consultation_phone_number: any
  bar_color_scheme: any
  twitter_username: any
  image: any
  description: any
  archived: boolean
  name: string
  site: {
    name: string
    id: string
  }
  site_id: string
  page_builds: PageBuild[]
  menu_items: any[]
  route: Route
  attributes?: {
    page_tags: string[]
    exclude_from_sitemap: boolean
  }
  live_page_build_id?: string
  live_page_build?: PageBuild
  dev_page_build_id?: string
  dev_page_build?: PageBuild
}

export interface Role {
  id: string
  description: string
  name: string
}

interface SiteUser {
  site: Site
  site_id: string
  user: User
  user_id: string
}

export interface User extends Base {
  assigned_page_builds: PageBuild[]
  attributes: { preferred_name: string }
  site_users: SiteUser[]
  email: string
  name: string
  role?: Role
  role_id?: string
}

export interface NewUser {
  name: string
  email: string
  role_id?: string
}

export type SessionUser = {
  email: string
  user: {
    name: string
  }
  session_id: string
}

export enum RouteType {
  PAGE,
  ROUTE,
  EXTERNAL
}

export interface Route {
  id: string
  route_type: keyof typeof RouteType
  url: string
  created_at: string
  updated_at: string
  site_build_id: string
  page_id: string
  target_route_id: string
  page: Page
  site_build: SiteBuild
  target_route: Route
  route_aliases: Route[]
}

export enum ProxyConfigVersion {
  dev,
  live
}

export type BuildVersion = keyof typeof ProxyConfigVersion

export enum ProxyConfigStatus {
  PENDING,
  DEPLOYING,
  DEPLOYED
}

export interface ProxyConfig {
  id: string
  proxy_config_id: string
  last_pipeline_execution_id?: string
  site_id: string
  created_at: string
  updated_at: string
  config_status: keyof typeof ProxyConfigStatus
  site_build_version: BuildVersion
  page_build_version: BuildVersion
}

export interface Plugin {
  id?: string,
  name: string,
  plugin_type: 'BLOG' | 'VIDEO' | 'GALLERY',
  url_path: string,
  active: boolean,
  route_path: string,
}

export interface SiteBuild {
  id: string
  name: string
  description?: string
  stage: string
  created_at: string
  updated_at: string
  published_date: Date
  site_id: string
  site?: Site
  menus?: Menu[]
  page_sections: PageSection[]
  header_sections: PageSection[]
  footer_sections: PageSection[]
}

export interface Site {
  pages: Page[]
  active: boolean
  client_account: number
  client_site: boolean
  created_at: string
  updated_at: string
  live_site_build_id: string
  dev_site_build_id: string
  live_site_build?: SiteBuild
  dev_site_build?: SiteBuild
  domain: string
  proxy_configs: ProxyConfig[]
  id: string
  launch_date: Date
  name: string
  contents: Contents[]
  plugins: Plugin[]
}

export const SectionFieldInput = [
  'TEXT',
  'RANGE',
  'IMAGE',
  'RICHTEXT',
  'DATE',
  'TEXTAREA',
  'NUMBER',
  'COLOR',
  'TOGGLE',
  'SELECT',
  'BUTTON',
  'IMAGE_GROUP',
  'VIDEO',
  'QUOTE_GROUP'
] as const

export type SectionFieldInputType = typeof SectionFieldInput[number]

export interface Contents {
  created_at: string
  id: string
  name: string
  page_section: PageSection
  page_section_id: string
  scope: 'PAGE' | 'SITE'
  site_id: string
  updated_at: string
  value: string
  value_history: { value: string }[]
}

export interface SectionField {
  id: string
  created_at: string
  input_type: SectionFieldInputType
  name: string
  section: Section
  section_id: string
  sort_order: number
  updated_at: string
  default_value?: string
}

export type Section = {
  id: string
  archived: boolean
  attributes: { render_on_page: boolean }
  created_at: string
  name: keyof typeof import('@/components/sections').default
  status: 'ACTIVE' | 'HIDDEN' | 'DISABLED'
  page_sections: any[]
  preview_photo?: string | null
  section_fields: SectionField[]
  updated_at: string
  description?: string,
}

export type SectionItemField = SectionField;

export type FilterState = { filterStatus: string; filterAssignee: string }

export interface MenuItem {
  id: string
  label: string
  menu_id: string | null
  site_build_id?: string
  parent_menu_item_id?: string | null
  route?: Route
  route_id?: string
  sort_order: number
  updated_at: string
  created_at?: string
  group_menu_items: MenuItem[]
}

export interface Menu {
  id: string
  archived: boolean
  name: string
  site_build_id: string
  source_menu_id: string
  menu_items: MenuItem[]
  created_at: string
  updated_at: string
}

export enum PageSectionType {
  HEADER,
  BODY,
  FOOTER
}

export interface PageSection {
  id: string
  page_section_type: PageSectionType
  sort_order: number
  color_scheme_id: string | null
  section: Section
  contents: Contents[]
  key?: string
}

export interface PageDefinition {
  domain: string
  site_id: string
  page_id: string
  slug: string
  proxy_config_id: string
  site_build_version: BuildVersion
  page_build_version: BuildVersion
}

export interface PublishedPagePath extends ParsedUrlQuery {
  proxy_config_id: string
  slug: string
}

export type MediaListResponse = MediaList & {
  limit: number
  offset: number
  totalCount: number
}

export type ActiveSiteBuild = {
  site_id?: string
  version: 'live' | 'dev'
  build_id?: string
}

export type SectionName = 'header_blocks' | 'body_blocks' | 'footer_blocks'

export type BlockContents = {
  [label: string]: string | number | null | boolean | BlockContents[] | BlockContents | string[]
}

export type PageTemplate = {
  name: string
  sections: {
    name: string
    sort_order: number
  }[]
}

export type SiteMeta = {
  styles?: {
    fonts: string,
    typekit_kits: string,
    css: string,
  }
}
export type PluginStatus = 'checking' | 'disconnected' | 'connected'

export type Preset = {
  display: {
    name: string
    description?: string
    image?: string
    documentationURL?: string
  },
  allowedSections: (StyleCoreComponentName | 'all')[],
  css: string,
  // This has no effect on the preset and is not used in the code,
  // but it is used to link to the presets documentation from Notion and image from S3
  notion_IID?: string
}

export type PresetList = Preset[]
