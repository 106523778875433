import {useContext, useEffect, useMemo, useReducer, useState} from 'react'
import useSWR, {SWRResponse} from 'swr'
import {useRouter} from 'next/router'
import {applyFiltering, genericSearch, handleGraphqlMutation, isCopyAllowed, pageSort,} from '../utils'
import {PROJECTS, sortFields} from '../constants'
import ListItem from './utils/ListItem'
import GroupCard from './utils/GroupCard'
import PageDialog from './PageDialog'
import NewPageWizard from './newWizards/NewPageWizard'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {ALL_PAGES, ALL_SECTIONS} from '@/graphql/queries'
import {
  ADD_PAGE,
  ADD_ROUTE,
  ARCHIVE_PAGE_V2,
  ASSIGN_PAGE_BUILD_TO_USER,
  CLONE_PAGE_BUILD,
  SET_DEV_PAGE_BUILD,
  SET_LIVE_PAGE_BUILD,
  SET_PAGE_BUILD_STATUS,
  UPDATE_PAGE_BUILD,
  UPDATE_PAGE_V2,
} from '@/graphql/mutations'
import NewProjectWizard from './newWizards/NewProjectWizard'
import {useCMS} from '@einsteinindustries/tinacms'
import NewSyndicatedPagesWizard from './newWizards/NewSyndicatedPagesWizard'
import AssignUserGroup from './AssignUserGroup'
import PublishPagesWizard from './PublishPagesWizard'
import {BuildVersion, FilterState, Page, PageBuild} from '@/components/shared/types'
import dialogReducer, {
  DialogsName,
  getAction,
  getDialogFromName,
  initialDialogsState,
} from '../../../../components/managers/PageManager/dialogReducer'
import PublishProjectWizard from '../../../../components/managers/PageManager/components/PublishProjectWizard'
import LucidAlert from '../../../../components/shared/LucidAlert'
import ActionsGroupDialog from '../../../../components/managers/PageManager/components/ActionsGroupDialog'
import RejectDialog from '../../../../components/managers/PageManager/components/RejectDialog'
import {
  closeAssignUserGroupDialog,
  closeEditDialog,
  closePublishProjectWizard,
  closePublishWizard,
  closeRejectDialog,
  openEditDialog,
  openPublishWizard,
} from '@/components/managers/PageManager/dialogUtilityFunctions'
import HeaderBar from '@/components/managers/PageManager/components/HeaderBar'
import {Button, Grid, Spacer} from '@nextui-org/react'
import PageTemplates from './utils/PageTemplates'
import {LucidSiteContext} from '@/src/state/site/Store'

interface PageComponentProps {
  close: () => void
}

// Props come from Tina
const PageComponent = ({
  close: closeSidePanel
}: PageComponentProps) => {
  // Checkbox next to each item
  const [selectedPages, setSelectedPages] = useState<string[]>([])
  // Filter State from Header
  const [searchInput, setSearchInput] = useState('')
  const [filterState, setFilterState] = useState<FilterState | null>(null)
  const [currentSort, setCurrentSort] = useState<keyof PageBuild>('status')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [statefulCurrentTab, setStatefulCurrentTab] = useState(0)

  const [{site_build_id}] = useContext(LucidSiteContext)

  // Display grouped item Card
  const [groupBy, setGroupBy] = useState<keyof Page | 'none'>('none')
  // keep track of opened dialogs
  const [dialogState, dialogDispatch] = useReducer(
    dialogReducer,
    initialDialogsState
  )
  // fetch data
  // TODO: type the return values

  const {asPath} = useRouter()
  const [, , siteBuildVersion, siteIdFromUrl, pageIdFromUrl, pageBuildVersion] = asPath.split('/') as ['', 'edit', BuildVersion, string, string, BuildVersion]

  const {data: rawPages, mutate: mutatePagesV2}: SWRResponse = useSWR(
      [ALL_PAGES, {site_id: siteIdFromUrl}],
      lucidDataFetcherV2
  )

  const {data: {data: {sections}}}: SWRResponse = useSWR(
    ALL_SECTIONS,
    lucidDataFetcherV2,
    {fallbackData: {data: {sections: []}}}
  )

  // alert system
  const cms = useCMS()
  const lucidAlert = new LucidAlert(cms)
  // useful for edit dialog
  let isSelectedPageEdited = false

  const router = useRouter()

  /*
   *
   * Get all live page id, dev page id, page builds
   *
   * */
  const [livePageBuilds, devPageBuilds, allPageBuilds] = useMemo(() => {
    return rawPages?.data.pages.reduce((acc: any[][], curr: any) => {
      const {live_page_build, dev_page_build, page_builds, ...pageData} = curr
      if (live_page_build) {
        acc[0].push({...live_page_build, version: 'live', page: pageData})
        acc[2].push({...live_page_build, version: 'live', page: pageData})
      }
      if (dev_page_build) {
        acc[1].push({...dev_page_build, version: 'dev', page: pageData})
        acc[2].push({...dev_page_build, version: 'dev', page: pageData})
      }
      return acc
    }, [[], [], []]) || [[], [], []]
  }, [rawPages])

  // Add an empty function for scope setting
  let honorStatefulCurrentTab = () => {}
  useEffect(() => {
    honorStatefulCurrentTab()
  }, [statefulCurrentTab])

  // The tab component itself
  const LiveDevAllTabs = () => {
    let tabs
    const [changeTab, setChangeTab] = useState(PROJECTS[statefulCurrentTab])
    const renderTabs = () => {
      tabs = PROJECTS.map((tab, key) => {
        return (
          <Grid key={key}>
            <Button flat={key !== statefulCurrentTab} onClick={() => {
              setChangeTab(tab)
              setStatefulCurrentTab(key)
            }} color="primary">{tab}</Button>
          </Grid>
        )
      })
    }
    useEffect(() => {
      renderTabs()
    }, [changeTab])
    renderTabs()
    return <div>
      <Spacer y={.5}></Spacer>
      <Grid.Container gap={.5} justify="center" alignContent="center">
        {tabs}
      </Grid.Container>
      <Spacer y={.5}></Spacer>
    </div>
  }

  if (!rawPages) return <div>loading...</div>

  const {pages}: { pages: Page[] } = rawPages.data as { pages: Page[] }
  /*
   *  Dialog utility functions
   * */
  const isDialogOpen = (name: DialogsName) =>
    getDialogFromName(dialogState, name).isOpen

  // function overload for typescript
  function getData(name: 'ActionsGroupDialog'): keyof typeof dispatchGroupAction
  function getData(name: DialogsName): any
  function getData(name: DialogsName) {
    return getDialogFromName(dialogState, name).data
  }

  const handleCloseEditDialog = async () => {
    if (isSelectedPageEdited && getData('EditDialog')) {
      await handleUpdatePage(getData('EditDialog'))
    }
    closeEditDialog(dialogDispatch)
    isSelectedPageEdited = false
  }

  const dispatchGroupAction = {
    lock: (pagesId: string[]) =>
      pagesId.forEach((id) =>
        toggleLock(pageBuilds.find((p) => p.id === id) as PageBuild)
      ),
    delete: (pagesId: string[]) => pagesId.forEach((id) => handleArchive(id)),
    copy: (pagesId: string[]) =>
      pagesId.forEach((id) =>
        createCopy(pageBuilds.find((p) => p.id === id) as PageBuild)
      ),
  }

  /**
   *  Actions & API calls
   */

  const createNewPage = async (name: string, description: string, route: string, template: string) => {
    await handleCreatePage(name, description, route, template)
    dialogDispatch(getAction('CreateDialog', 'Hide'))
  }

  const handleCreatePage = async (name: string, description: string, route: string, template: string, siteId = siteIdFromUrl) => {
    const variables = {
      newPage: {
        name,
        site_id: siteId,
      },
    }
    const message = {
      error: `Something went wrong while creating the page ${name}`,
      success: `The page ${name} was created successfully.`,
    }
    const {data: {addPage: page}} = await handleGraphqlMutation(
      ADD_PAGE,
      variables,
      lucidAlert,
      message,
      mutatePagesV2
    ) as {data: {addPage: Page}}
    if (page) {
      const routeVariables = {
        newRoute: {
          site_build_id,
          page_id: page.id,
          url: route,
          route_type: 'PAGE'
        }
      }
      const routeMessage = {
        error: `Something went wrong while creating the route ${route} for ${name}`,
        success: `The route ${route} for ${name} was created successfully.`
      }
      await handleGraphqlMutation(
        ADD_ROUTE,
        routeVariables,
        lucidAlert,
        routeMessage
      )

      if (template && template !== 'none') {
        const templateSections = PageTemplates.find(t => t.name === template)?.sections.map((s: {name: string, sort_order: number}) => ({
          sort_order: s.sort_order,
          section_id: sections.find((s2: {name: string}) => s.name === s2.name)?.id
        }))

        const variables = {
          id: page.page_builds[0].id,
          updatePageBuildData: {
            description: description,
            body_sections: templateSections
          }
        }
        const message = {
          error: `Something went wrong while setting the ${template} template for ${name}`,
          success: `The ${template} template for ${name} was set successfully.`,
        }
        await handleGraphqlMutation(
          UPDATE_PAGE_BUILD,
          variables,
          lucidAlert,
          message,
          mutatePagesV2
        )
      }
      const variables = {
        page_build_id: page.page_builds[0].id,
        page_id: page.id,
      }
      const message = {
        error: `Something went wrong while setting the ${pageBuildVersion} page build for ${name}`,
        success: `The ${pageBuildVersion} page build for ${name} was set successfully.`,
      }

      const mutation = pageBuildVersion === 'live' ? SET_LIVE_PAGE_BUILD : SET_DEV_PAGE_BUILD
      await handleGraphqlMutation(
        mutation,
        variables,
        lucidAlert,
        message,
        mutatePagesV2
      )
    }
  }

  const handleArchive = async (id: string) => {
    const variables = {
      id,
    }
    const message = {
      error: 'Something went wrong while archiving the page',
      success: 'The page was archived successfully.',
    }
    await handleGraphqlMutation(
      ARCHIVE_PAGE_V2,
      variables,
      lucidAlert,
      message,
      mutatePagesV2
    )
  }

  const toggleLock = async (page: PageBuild) => {
    page = {...page, locked: !page.locked}
    await handleUpdatePage(page)
  }

  const handlePublishPage = async (pageBuilds: PageBuild[]) => {
    // TODO: Promise.all
    for await (const pageBuild of pageBuilds) {
      const variables = {
        page_build_id: pageBuild.id,
        page_id: pageBuild.page_id,
      }
      const message = {
        success: `The page ${
          pageBuild.page!.name
        } was successfully set to live`,
        error: `Something went wrong while setting ${
          pageBuild.page!.name
        } to live`,
      }
      await handleGraphqlMutation(
        SET_LIVE_PAGE_BUILD,
        variables,
        lucidAlert,
        message,
        mutatePagesV2
      )
    }
    closePublishWizard(dialogDispatch)
    closeAssignUserGroupDialog(dialogDispatch)
  }

  const handlePublishProject = async () => {
    //TODO: Publish project Place Holder
  }

  // TODO: only dealing with users at the moment
  //  missing: lock, name, description...
  const handleUpdatePage = async ({
    id,
    status,
    locked,
    page,
    assigned_to_user,
    page_id,
  }: PageBuild) => {
    let message = {
      error: `Something went wrong while assigning a user to the page ${page.name}`,
      success: `The page ${page?.name} was assigned to ${assigned_to_user?.name} successfully.`,
    }
    await handleGraphqlMutation(
      ASSIGN_PAGE_BUILD_TO_USER,
      {page_build_id: id, user_id: assigned_to_user?.id},
      lucidAlert,
      message,
      mutatePagesV2
    )

    if (status === 'PROPOSED') {
      message = {
        error: 'Something went wrong while setting the page status',
        success: `The page ${page.name} was assigned to ${assigned_to_user?.name} successfully.`,
      }
      await handleGraphqlMutation(
        SET_PAGE_BUILD_STATUS,
        {page_build_id: id, status: 'IN_PROGRESS'},
        lucidAlert,
        message,
        mutatePagesV2
      )
    }
  }

  const handleSelect = (pageId: string) => {
    if (selectedPages.includes(pageId)) {
      setSelectedPages([...selectedPages.filter((id) => id !== pageId)])
    } else {
      setSelectedPages([...selectedPages, pageId])
    }
  }

  const handleGroupSelectAll = (pagesId: string[]) => {
    if (selectedPages.some((page) => pagesId.includes(page))) {
      setSelectedPages([
        ...selectedPages.filter((page) => !pagesId.includes(page)),
      ])
    } else {
      setSelectedPages([...selectedPages, ...pagesId])
    }
  }

  const createCopy = async (pageBuild: PageBuild) => {
    if (isCopyAllowed(pageBuild, pageBuilds)) {
      const variables = {
        source_page_build_id: pageBuild.id,
      }
      const message = {
        error: `Something went wrong while copying the page ${pageBuild.page.name}`,
        success: `The page ${pageBuild.page.name} was copied successfully.`,
      }
      await handleGraphqlMutation(
        CLONE_PAGE_BUILD,
        variables,
        lucidAlert,
        message,
        mutatePagesV2
      )
    } else {
      lucidAlert.warn('This page cannot be copied')
    }
  }

  /**
   * Tags
   */

  const updateTags = async (pageId: string, newPageTags: string[]) => {
    const variables = {
      id: pageId,
      updatePageData: {
        attributes: {
          page_tags: newPageTags,
        },
      },
    }
    const message = {
      error: `The tag(s) ${newPageTags} could not be added`,
      success: `The tag(s) ${newPageTags} was (were) added successfully`,
    }
    await handleGraphqlMutation(
      UPDATE_PAGE_V2,
      variables,
      lucidAlert,
      message,
      mutatePagesV2
    )
  }

  /**
   * Display logic
   * */
  // filter page builds from tab
  let pageBuilds: (PageBuild & {version: BuildVersion})[] = []

  honorStatefulCurrentTab = () => {
      switch (statefulCurrentTab) {
        case 0:
          pageBuilds = livePageBuilds
          break
        case 1:
          pageBuilds = devPageBuilds
          break
        case 2:
          pageBuilds = allPageBuilds
          break
        case 3:
          pageBuilds = []
          break
        default:
          pageBuilds = allPageBuilds
      }
  }

  honorStatefulCurrentTab()

  const filteredPages = pageBuilds.filter((pageBuild: PageBuild) =>
    applyFiltering(pageBuild, filterState)
  )

  const searchedPages = filteredPages.filter((pageBuild: PageBuild) =>
    genericSearch(pageBuild, searchInput)
  )

  const handleToggleDirectionSort = () =>
    sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC')

  const sortedPageBuilds = searchedPages.sort((a: PageBuild, b: PageBuild) =>
    pageSort(a, b, currentSort, sortDirection)
  )

  let groupByKeys: string[] = []
  let groupByValues: (PageBuild & {version: BuildVersion})[][] = []
  const handleGroupBy = () => {
    const res: { [key: string]: any } = {}
    sortedPageBuilds.forEach((pageBuild: PageBuild) => {
      // @ts-ignore
      const groupByName: string = pageBuild[groupBy]?.name ?? pageBuild[groupBy]

      res[groupByName]
        ? res[groupByName].push(pageBuild)
        : (res[groupByName] = [pageBuild])
    })
    groupByKeys = Object.keys(res)
    groupByValues = Object.values(res)
  }

  if (groupBy !== 'none') handleGroupBy()
  return (
    <div
      style={{
        overflow: 'scroll',
      }}
    >
      {
        <>
          <LiveDevAllTabs></LiveDevAllTabs>
          <HeaderBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pageBuilds={allPageBuilds}
              setFilterState={setFilterState}
              sortFields={sortFields}
              currentSort={currentSort}
              setCurrentSort={setCurrentSort}
              sortDirection={sortDirection}
              handleToggleDirectionSort={handleToggleDirectionSort}
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              selectAll={() => selectedPages.length
                  ? setSelectedPages([])
                  : setSelectedPages(
                      sortedPageBuilds.reduce(
                          (acc: string[], curr: PageBuild) => [...acc, curr.id],
                          []
                      )
                  )}
              selectedPages={selectedPages}
              dispatchAction={dialogDispatch}
              cms={cms}
            /></>
      }
      <div
        style={
          groupBy !== 'none'
            ? {backgroundColor: '#eeedf0', padding: '35px 0'}
            : {}
        }
      >
        {sortedPageBuilds.length ? (
          groupBy === 'none' ? (
            sortedPageBuilds.map((pageBuild: PageBuild & {version: BuildVersion}) => (
              // TODO: perf improvement: isChecked is On*n
              <ListItem
                key={pageBuild.id}
                pageBuild={pageBuild}
                isChecked={selectedPages.includes(pageBuild.id)}
                onChange={handleSelect}
                siteBuildVersion={siteBuildVersion}
                siteId={siteIdFromUrl}
                closeSidePanel={closeSidePanel}
                actions={{
                  trash: () => handleArchive(pageBuild.page_id),
                  edit: () => openEditDialog({...pageBuild}, dialogDispatch),
                  copy: () => createCopy(pageBuild),
                  unlock: () => toggleLock(pageBuild),
                }}
              />
            ))
          ) : (
            groupByValues.map((group: PageBuild[], index) => (
              <GroupCard
                closeSidePanel={closeSidePanel}
                pageBuildVersion={pageBuildVersion}
                siteId={siteIdFromUrl}
                siteBuildVersion={siteBuildVersion}
                key={groupByKeys[index]}
                title={groupByKeys[index]}
                pages={group}
                copyAction={createCopy}
                unlockAction={toggleLock}
                trashAction={handleArchive}
                defaultState={!(groupByKeys[index] === 'in_progress')}
                onSelectAll={handleGroupSelectAll}
                onSelect={handleSelect}
                selectedPages={selectedPages}
                dispatchAction={dialogDispatch}
              />
            ))
          )
        ) : (
          <div>No page to be shown</div>
        )}
      </div>{' '}
      {/* Modals */}
      {isDialogOpen('EditDialog') && (
        <PageDialog
          pageBuild={getData('EditDialog')}
          showDialog={isDialogOpen('EditDialog')}
          closeDialog={handleCloseEditDialog}
          onPublish={(pageBuilds) =>
            openPublishWizard(pageBuilds, dialogDispatch)
          }
          onEdit={() => {
            isSelectedPageEdited = true
          }}
          updateTags={updateTags}
        />
      )}
      {isDialogOpen('CreateDialog') && (
        <NewPageWizard
          showDialog={isDialogOpen('CreateDialog')}
          closeDialog={() => dialogDispatch(getAction('CreateDialog', 'Hide'))}
          onSave={(name: string, description: string, route: string, template: string) => createNewPage(name, description, route, template)}
        />
      )}
      {isDialogOpen('SyndicatedDialog') && (
        <NewSyndicatedPagesWizard
          showDialog={isDialogOpen('SyndicatedDialog')}
          closeDialog={() =>
            dialogDispatch(getAction('SyndicatedDialog', 'Hide'))
          }
          onSave={() => {}}
        />
      )}
      {isDialogOpen('ProjectDialog') && (
        <NewProjectWizard
          onSave={() => {}}
          closeDialog={() => dialogDispatch(getAction('ProjectDialog', 'Hide'))}
          showDialog={isDialogOpen('ProjectDialog')}
          pages={pageBuilds}
        />
      )}
      {!!getData('PublishDialog')?.length && isDialogOpen('PublishDialog') && (
        <PublishPagesWizard
          pageBuilds={getData('PublishDialog')}
          showDialog={isDialogOpen('PublishDialog')}
          closeDialog={() => closePublishWizard(dialogDispatch)}
          onSave={(pageBuilds) => handlePublishPage(pageBuilds)}
        />
      )}
      {isDialogOpen('PublishProjectDialog') && (
        <PublishProjectWizard
          pages={pages}
          routes={[]}
          showDialog={isDialogOpen('PublishProjectDialog')}
          closeDialog={() => closePublishProjectWizard(dialogDispatch)}
          onSave={handlePublishProject}
        />
      )}
      {isDialogOpen('AssignUserGroupDialog') && (
        <AssignUserGroup
          pages={
            getData('AssignUserGroupDialog') === 'all'
              ? pageBuilds
              : pageBuilds.filter(
                  (page) => page.status === getData('AssignUserGroupDialog')
                )
          }
          selectedPagesProps={selectedPages.filter((page) =>
            pageBuilds.some(
              (p) =>
                page === p.id &&
                (p.status === getData('AssignUserGroupDialog') ||
                  getData('AssignUserGroupDialog') === 'all')
            )
          )}
          showDialog={isDialogOpen('AssignUserGroupDialog')}
          action={getData('AssignUserGroupDialog')}
          dispatchAction={dialogDispatch}
        />
      )}
      {isDialogOpen('ActionsGroupDialog') && (
        <ActionsGroupDialog
          pages={pageBuilds}
          showDialog={isDialogOpen('ActionsGroupDialog')}
          closeDialog={() =>
            dialogDispatch(getAction('ActionsGroupDialog', 'Hide'))
          }
          action={getData('ActionsGroupDialog')}
          selectedPagesProps={selectedPages}
          onSave={(pages) =>
            dispatchGroupAction[getData('ActionsGroupDialog')](pages)
          }
        />
      )}
      {isDialogOpen('RejectDialog') && (
        <RejectDialog
          showDialog={true}
          closeDialog={() => closeRejectDialog(dialogDispatch)}
          onSave={() =>
            dialogDispatch(getAction('AssignUserGroupDialog', 'Hide'))
          }
          payload={getData('RejectDialog')}
        />
      )}
    </div>
  )
}

export default PageComponent
