import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Closer: Single Column Large Image',
      documentationURL: 'https://www.notion.so/einsteinindustries/Closer-Single-Column-Large-Image-b3e15d25e29649c2b124996360fa93e7?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/closer-IID-24.png'
    },
    notion_IID: '24',
    allowedSections: [],
    css: `
.closer-image {
    text-align: center;
    width: 100%;
}
@media (min-width:1024px) {
\t\tsection.closer-section {
\t\t    padding: 10rem 0;
\t\t}
}
section.closer-section .row {
    flex-flow: column;
}
.closer-image img {
    max-width: 100%;
    width: 100%;
}
section.closer-section .section-container {
    max-width: 660px;
}
    `
  },
  {
    display: {
      name: 'Closer: Split image',
      documentationURL: 'https://www.notion.so/einsteinindustries/Closer-Split-image-21cc5e5b91174bc9ad8a0ba9b065f8a0?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/closer-IID-124.png'
    },
    notion_IID: '124',
    allowedSections: [],
    css: `
    .column.closer-image {
  flex-basis: 50%;
}
.closer-image img {
max-width: 100%
}

.closer-section .section-container {
  max-width: 1400px;
}

.column.closer-description {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width:1024px) {

.closer-description img {
  max-width: 50%;
  margin-left: 4rem !important;
}
}

.closer-description .section-heading span {
  text-align: center !important;

}
.closer-description h2 {
margin-bottom: 2rem;

}
section.hero-inner-section + section.intro-section {
padding-top: 5rem;

}
    `
  }
]
