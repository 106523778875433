import {Dispatch, useState} from 'react'
import ListItem from './ListItem'
import {BuildVersion, PageBuild} from '../../../../shared/types'
import styled from 'styled-components'
import {DialogReducerAction} from '../../dialogReducer'
import {openEditDialog} from '../../dialogUtilityFunctions'

const WrapperDiv = styled.div`
  width: 95%;
  height: 100%;
  margin: 0 auto 35px auto;
  background-color: white;
  /* border-radius: 25px; */
  padding: 24px 0;
`

const CardHeaderDiv = styled.div`
  font-size: 1rem;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 0 24px;
  /* border-bottom: 1px solid #e2e1e6; */
`

const DisplayButton = styled.button`
  font-size: 1em;
  padding: 10px;
  /* border-radius: 5000px; */
  border: none;
  width: 100px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
`

interface GroupCardProps {
  title: string
  pages: PageBuild[]
  trashAction: (id: string) => void
  copyAction: (page: PageBuild) => void
  unlockAction: (page: PageBuild) => void
  defaultState: boolean
  selectedPages: string[]
  onSelect: (id: string) => void
  onSelectAll: (ids: string[]) => void
  dispatchAction: Dispatch<DialogReducerAction>
  pageBuildVersion: BuildVersion
  siteBuildVersion: BuildVersion
  siteId: string
  closeSidePanel: () => void
}

const GroupCard = (
  {
    title,
    pages,
    trashAction,
    copyAction,
    unlockAction,
    defaultState,
    selectedPages,
    onSelect,
    onSelectAll,
    dispatchAction,
    siteId,
    pageBuildVersion,
    siteBuildVersion,
    closeSidePanel
  }: GroupCardProps) => {
  const [isHidden, setIsHidden] = useState(defaultState)
  return (
    <WrapperDiv>
      <CardHeaderDiv>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            fontSize: '1.25rem',
            fontWeight: 700,
          }}
        >
          <div>
            {title
              .split('_')
              .map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1)
              })
              .join(' ')}{' '}
            ({pages.length})
          </div>
          <button onClick={() => setIsHidden(!isHidden)}>
            {isHidden ? 'Show' : 'Hide'}
          </button>
        </div>
        <div style={{display: 'flex'}}>
          <button
            onClick={() =>
              onSelectAll(
                pages.reduce<string[]>((acc, curr) => [...acc, curr.id], [])
              )
            }
          >
            Select all
          </button>
        </div>
      </CardHeaderDiv>
      {!isHidden &&
        pages.map((page: PageBuild) => (
          <ListItem
            closeSidePanel={closeSidePanel}
            key={page.id}
            pageBuild={{...page, version: pageBuildVersion}}
            siteBuildVersion={siteBuildVersion}
            siteId={siteId}
            onChange={onSelect}
            isChecked={selectedPages.includes(page.id)}
            actions={{
              trash: () => trashAction(page.id),
              edit: () => openEditDialog(page, dispatchAction),
              copy: () => copyAction(page),
              unlock: () => unlockAction(page),
            }}
          />
        ))}
    </WrapperDiv>
  )
}

export default GroupCard
