import {ColorSchemeValues} from '@/components/managers'

function backgroundImage(backgroundImage: string | undefined) {
    if (backgroundImage && backgroundImage !== 'transparent') {
        return `url(${backgroundImage})`
    }
    return 'none'
}

function createColorSchemeCSS(scheme: ColorSchemeValues): string {
    let CSS = ''

    CSS += `
    body {
      color: ${scheme.text};
      background-color: ${scheme?.background_color};
    }
  
    .colorscheme-background-color {
      background-color: ${scheme.background_color};
    }

    .title {
      color: ${scheme?.title};
    }

    h2 .subtitle {
      color: ${scheme?.subtitle};
    }
  
    a {
      color: ${scheme?.link};
    }
    
    a:hover {
      color: ${scheme?.link_hover};
    }
    
    .textblock-image-collage-section-container .phrase {
      color: ${scheme?.Collage?.accent_text};
      opacity: ${scheme?.Collage?.accent_text_opacity};
    }
    
    .textblock-image-Collage-section-container .image:before {
      background-color: ${scheme?.Collage?.box_layer_1_background_color};
      opacity: ${scheme?.Collage?.box_layer_1_opacity};
    }
    
    .textblock-image-Collage-section-container .image:after {
      background-color: ${scheme?.Collage?.box_layer_2_background_color};
      opacity: ${scheme?.Collage?.box_layer_2_background_color};
    }
    
    .title-image-Collage-section-container .columns {
      color: ${scheme?.Collage?.accent_text};
      opacity: ${scheme?.Collage?.accent_text_opacity};
    }
    
    .title-image-collage-section-container .columns:before {
      background-color: ${scheme?.Collage?.box_layer_1_background_color};
      opacity: ${scheme?.Collage?.box_layer_1_opacity};
    }  
    
    .title-image-collage-section-container .columns:after {
      background-color: ${scheme?.Collage?.box_layer_2_background_color};
      opacity: ${scheme?.Collage?.box_layer_2_opacity};
    }
    
    .title-image-collage-section-container .title-wrapper:before {
      background-color: ${scheme?.Collage?.box_layer_1_background_color};
      opacity: ${scheme?.Collage?.box_layer_1_opacity};
    }
    
    .title-and-image-collage-section-container .title-wrapper {
      color: ${scheme?.Collage?.accent_text};
      opacity: ${scheme?.Collage?.accent_text_opacity};
    }
    
    .title-and-image-collage-section-container .title-wrapper:before {
      background-color: ${scheme?.Collage?.box_layer_2_background_color};
      opacity: ${scheme?.Collage?.box_layer_2_opacity};
    }
    
    .title-and-image-collage-section-container .columns:before {
        background-color: ${scheme?.Collage?.box_layer_1_background_color};
        opacity: ${scheme?.Collage?.box_layer_1_opacity};
      }
    
    .title-and-image-collage-section-container .columns:before:after {
      background-color: ${scheme?.Collage?.box_layer_2_background_color};
      opacity: ${scheme?.Collage?.box_layer_2_opacity};
    }
    
    .timeline-wrapper:after, .timeline-item-container:before {
      background-color: ${scheme?.Collage?.box_layer_1_background_color};
    }
    
    .timeline-wrapper:before {
      background-color: ${scheme?.button_background};
    }
    
    .section-content {
      position: relative;
      width: calc(100% - 2rem);
      margin: 0 auto;
      padding: 2rem;
      z-index: 1;
    }
    
    .navigation-inner {
      background-color: ${scheme.background_color};
    }
    
    .section-background {
      all: unset;
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-image: ${backgroundImage(scheme.background_image)};
      background-repeat: ${(scheme.background_image_repeat ? 'repeat' : 'no-repeat')};
      background-size: cover;
      opacity: ${Number(scheme.background_image_opacity) / 100};
    }
    
    .section-button {
      color: ${scheme.button_text};
      background-color: ${scheme.button_background};
      text-decoration: none;
    }
 
    .section-button:hover {
      background-color: ${scheme.button_hover};
    }

    .section-button-secondary {
      ${scheme['Button Secondary'] && `
        background-color: ${scheme['Button Secondary'].button_background};
        color: ${scheme['Button Secondary'].button_text};
      `}
    }
  
    .section-button-secondary:hover {
      ${scheme['Button Secondary'] && `
          background-color: ${scheme['Button Secondary'].button_hover};
      `}
    }
    `

    if (scheme.title) {
        // title
        CSS += `
        table {
          border-color: ${scheme.title};
        }
        thead {
          background-color: ${scheme.title};
        }
        tr:nth-child(even) {
          background-color: ${scheme.title};
        }
        .heading-1,
        .heading-2,
        .title,
        h1,
        h2 {
          color: ${scheme.title};
          border-bottom-color: ${scheme.title};
        }
        .heading-1 a,
        .title a,
        .heading-2 a {
          color: ${scheme.title};
        }
        .heading-1 a,
        .title a,
        .heading-2 a {
          border-bottom-color: ${scheme.title};
        }
        
        .section-richtext scheme.title,
        .location-info .large-label {
           color: ${scheme.title};
        }
        .color-scheme-title,
        .color-scheme-title a {
              color: ${scheme.title} !important;
        }
        
        .color-scheme-title a,
        .color-scheme-title a:hover {
          border-bottom-color: ${scheme.title} !important;
        }
        `
    }
    if (scheme.subtitle) {
        CSS += `
        .heading-3,
        .heading-4,
        .heading-5,
        .subtitle,
        h3,
        h4,
        h5 {
          color: ${scheme.title};
          border-bottom-color: ${scheme.title};
        }
        .subtitle a,
        .heading-3 a,
        .heading-4 a,
        .heading-5 a {
          color: ${scheme.subtitle};
        }
        .subtitle a,
        .heading-3 a,
        .heading-4 a,
        .heading-5 a {
          border-bottom-color: ${scheme.subtitle};
        }
        
        .color-scheme-subtitle,
        .color-scheme-subtitle a {
          color: ${scheme.subtitle} !important;
        }
        .color-scheme-subtitle a,
        .color-scheme-subtitle a:hover {
          border-bottom-color: ${scheme.subtitle} !important;
        }
        `
    }
    if (scheme.text) {
        CSS += `
        .paragraph {
          color: ${scheme.text};
        }
        input:not(.active)::placeholder {
          color: ${scheme.text};
        }
        .timeline-wrapper:after,
        .timeline-item-container:before {
          background-color: ${scheme.text};
        }
        .timeline-item-container:after {
          border-color: ${scheme.text};
        }
        .phone-number p {
          color: ${scheme.text};
        }
        .section-richtext {
           color: ${scheme.text};
        }
        .menus p {
          color: ${scheme.text};
        }
        .blog-post-date {
          color: ${scheme.text};
        }
        .quote {
            color: ${scheme.text};
        }
        .small {
            color: ${scheme.text};
        }
        .label {
            color: ${scheme.text};
        }
        `
    }
    if (scheme.link) {
        CSS += `
        .color-scheme-link {
            color: ${scheme.link};
        }
        .color-scheme-link,
        .color-scheme-link:hover {
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: ${scheme.link};
        }
        .link {
          color: ${scheme.link};
        }
        .link,
        .link:hover {
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: ${scheme.link};
        }
        .section-richtext li a,
        .section-richtext li a:hover,
        .section-richtext dt a,
        .section-richtext dt a:hover,
        .section-richtext dd a,
        .section-richtext dd a:hover,
        .all-pages li a,
        .all-pages li a:hover,
        .all-pages li li a,
        .all-pages li li a:hover,
        a .menu-item-title,
        {
          border-bottom-color: ${scheme.link};
        }
        .multi-column-menu .all-pages li {
          border-bottom-color: ${scheme.link}4D;
        }
        .multi-column-menu .all-pages li li:first-child {
          border-top-color: ${scheme.link}4D;
        }
         overlay-menu-icons .menu-icon {
          border-bottom-color: ${scheme.link}4D;
          border-top-color: ${scheme.link}4D;
        }
        a .menu-item-title,
        .slider-page-group a,
        .mega-menu-block-page-list-item,
        .mega-menu-block-item .button-text {
          color: ${scheme.link};
        }
        .social-account a,
        .mega-menu-block-item .button,
        .mega-menu-block-item .button:hover {
          border-color: ${scheme.link};
        }
        .nav-items nav .nav-menu-icon span,
        .nav-items nav .nav-menu-icon span:before,
        .nav-items nav .nav-menu-icon span:after,
        .mega-menu-block-item .button:hover {
          background-color: ${scheme.link};
        }
        .nav-items nav a {
          border-bottom-color: ${scheme.link};
        }
        `
    }
    if (scheme.headings) {
        CSS += `
        .section-richtext h1,
        .section-richtext h2,
        .section-richtext h3,
        .section-richtext h4,
        .phone-number h2 {
          color: ${scheme.headings};
        }
                `
            }
            if (scheme.link_hover) {
                CSS += `
        .color-scheme-link:hover {
            color: ${scheme.link_hover};
        }
        .link:hover {
          color: ${scheme.link_hover};
        }
        .social-account a:hover {
          border-color: ${scheme.link_hover};
        }
        a.menu-block-item-container:hover {
          background-color: ${scheme.link_hover};
        }
        .gallery-page .link:hover h2 .subtitle {
          color: ${scheme.link_hover};
        }
        -text a:hover,
        .slider-page-group a:hover,
        .mega-menu-block-page-list-item:hover,
        .mega-menu-block-item a:hover .button-text {
          color: ${scheme.link_hover};
        }
        .nav-items nav .nav-menu-icon span:hover,
        .nav-items nav .nav-menu-icon span:hover:before,
        .nav-items nav .nav-menu-icon span:hover:after {
          background-color: ${scheme.link_hover};
        }
        .nav-items nav a:hover,
        a:hover .menu-item-title {
          border-bottom-color: ${scheme.link_hover};
        }
        `
    }
    if (scheme.button_text) {
        CSS += `
        .color-scheme-button-text {
          color: ${scheme.button_text};
        }
        .color-scheme-button-text {
          border-bottom-color: ${scheme.button_text};
        }
        .button,
        .section-button {
          color: ${scheme.button_text};
        }
        .button-text {
          border-bottom-color: ${scheme.button_text};
        }
        a.button:hover {
          border-bottom-color: ${scheme.button_text};
        }
         .tns-controls button {
          color: ${scheme.button_text};
        }
        .syndicated-page .back-stack .button .button-text {
          color: ${scheme.button_text};
        }
         .page-group a.active,
         .slider-page-group a.active,
         .slider-page-group .slide-menu__submenu--active a.slide-menu__backlink {
          color: ${scheme.button_text};
        }
         .page-group a.active:hover,
         .slider-page-group a.active:hover,
         .slider-page-group .slide-menu__submenu--active a.slide-menu__backlink:hover {
          color: ${scheme.button_text};
        }
         .has-icon .timeline-item-image svg {
          fill: ${scheme.button_text};
        }
        
        .pagination li.next span:after {
          border-left-color: ${scheme.button_text};
        }
        `
    }
    if (scheme.button_background) {
        CSS += `
        .color-scheme-button {
           background-color: ${scheme.button_background};
        }
        .button,
        .section-button 
        {
          background-color: ${scheme.button_background};
        }
        .tns-controls button,
        .tns-nav button {
          background-color: ${scheme.button_background};
        }
        .syndicated-page .back-stack .section-button {
          background-color: ${scheme.button_background};
        }
        .page-group a.active,
        .slider-page-group a.active,
        .slider-page-group .slide-menu__submenu--active a.slide-menu__backlink {
          background-color: ${scheme.button_background};
        }
        .timeline-wrapper:before {
          background-color: ${scheme.button_background};
        }
        .timeline-item-image {
          background-color: ${scheme.button_background};
        }
        
         .pagination li a {
          background-color: ${scheme.button_background};
        }
        `
    }
    if (scheme.button_hover) {
        CSS += `
        .color-scheme-button:hover {
          background-color: ${scheme.button_hover};
        }
        .button:hover,
        .section-button:hover {
          background-color: ${scheme.button_hover};
        }
        .tns-controls button:hover,
        .tns-nav button:hover,
        .tns-nav button.tns-nav-active {
          background-color: ${scheme.button_hover};
        }
        .syndicated-page .back-stack .button:hover, 
        .syndicated-page .back-stack .section-button:hover {
          background-color: ${scheme.button_hover};
        }
        .page-group a.active:hover,
        .slider-page-group a.active:hover,
        .slider-page-group .slide-menu__submenu--active a.slide-menu__backlink:hover {
          background-color: ${scheme.button_hover};
        }
        
        .pagination li a:hover {
           background-color: ${scheme.button_hover};
        }
        `
    }
    if (scheme.background_color) {
        CSS += `
        .color-scheme-background-color {
              background-color: ${scheme.background_color};
        }
        @media screen and (max-width: 1024px) {
            .hero-preface-content {
              background-color: ${scheme.background_color};
            }
            .hero-preface-content:before {
              background: linear-gradient(to bottom, transparent 0%, ${scheme.background_color} 100%);
            }
        }      
        `
    }
    if (scheme.text) {
        CSS += `
        .divider {
            color: ${scheme.text};
        }
        .quote {
            color: ${scheme.text};
        }
        .citation {
            color: ${scheme.text};
        }
        .color-scheme-border {
          border-color: ${scheme.text}1A;
        }
        .blog-index-post a:hover {
          border-color: ${scheme.text}1A;
        }
        `
    }
    if (scheme.title && scheme.subtitle) {
        CSS += `
        .title {
            color: ${scheme.title};
        }
        
        .subtitle {
            color: ${scheme.subtitle};
        }
        
        `
    }

    if (scheme.link) {
        CSS += `
        .section-richtext a {
          color: ${scheme.link};
        }
        
        .pods-item-content h2 a {
          border-bottom-color: ${scheme.link};
        }
        
        .menus a h3 {
          color: ${scheme.link};
          z-index: 2;
        }
        
        .paragraph a {
            border-bottom-color: ${scheme.link};
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }
        `
    }

    if (scheme.link_hover) {
        CSS += ` 
        .section-richtext a:hover {
          color: ${scheme.link_hover};
        }
        
        .paragraph a:hover {
            border-bottom-color: ${scheme.link_hover};
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }
        
        .pods-item-content h2 a:hover {
          border-bottom-color: ${scheme.link_hover};
        }
        
        .menus a:hover h3 {
          color: ${scheme.link_hover};
        }
        `
    }

    if (scheme.svg_icon) {
        CSS += `
        .color-scheme-svg svg {
          fill: ${scheme.svg_icon};
        }
        .color-scheme-svg .brand-color-stroke {
          stroke: ${scheme.svg_icon};
        }
        .icon-item svg {
          fill: ${scheme.svg_icon};
        }
        .page-group-link::before {
          border-top-color: ${scheme.svg_icon};
        }
        .section-richtext svg {
          fill: ${scheme.svg_icon};
        }
        .section-richtext .brand-color-stroke {
          stroke: ${scheme.svg_icon};
        }
        .navigation svg {
          stroke: ${scheme.svg_icon};
        }
        .section-heading svg {
           fill: ${scheme.svg_ornament ?? scheme.svg_icon};
        }
        `
    }

    if (scheme.blockquote_border) {
        CSS += `
        .section-richtext .blockquote-border-1-1 {
          border-left-color: ${scheme.blockquote_border};
        }
        .section-richtext .blockquote-border-2-1 {
          border-top-color: ${scheme.blockquote_border};
          border-bottom-color: ${scheme.blockquote_border};
        }
        `
    }

    if (scheme.button_text) {
        CSS += `
        .section-richtext .button span {
          color: ${scheme.button_text};
        }
        .pagination li.next span:after {
          border-color: transparent transparent transparent ${scheme.button_text};
        }
        .pagination li.prev span:after {
          border-color: transparent ${scheme.button_text} transparent transparent;
        }
        `
    }

    if (scheme.button_hover) {
        CSS += `
        .section-richtext .button:hover,
        .pagination li a:hover {
          background-color: ${scheme.button_hover};
        }
        `
    }

    if (scheme['Menu Item']?.title && scheme['Menu Item']?.subtitle) {
        CSS += `
        .menu-item-title {
          color: ${scheme['Menu Item'].title};
        }
        .menu-item-subtitle {
          color: ${scheme['Menu Item'].subtitle};
        }
        `
    }

    if (scheme['Block Item']) {
        CSS += `
        .menu-block-item-title,
        a .menu-block-item-title,
        a:hover .menu-block-item-title {
          color: ${scheme['Block Item'].title};
        }
        a .menu-block-item-title,
        a:hover .menu-block-item-title {
          border-bottom-color: ${scheme['Block Item'].title};
        }
        .menu-block-item-subtitle,
        a .menu-block-item-subtitle,
        a:hover .menu-block-item-subtitle,
        a.menu-block-item-subtitle:hover {
          color: ${scheme['Block Item'].subtitle};
        }
        a .menu-item-subtitle.menu-block-item-subtitle,
        a:hover .menu-item-subtitle.menu-block-item-subtitle {
          border-bottom-color: ${scheme['Block Item'].subtitle};
        }
        .dimmer,
        .mega-menu-block-item {
          background-color: ${scheme['Block Item'].background_color};
        }
        `
    }

    if (scheme['Block Item']) {
        CSS += `
        .dimmer,
        .mega-menu-block-item {
          background-color: ${scheme['Block Item'].background_color};
        }
        `
    }

    if (scheme.Card) {
        CSS += `
        .menu-card-item-title {
          color: ${scheme.Card.title};
        }
        .menu-card-item-subtitle {
          color: ${scheme.Card.subtitle};
        }
        a .menu-card-item-title,
        a .menu-card-item-subtitle,
        .features-card-item-title a,
        .features-card-item-subtitle a,
        .features-card-item-description a,
        nav.menu-card-item a {
          color: ${scheme.Card.link};
        }
        a:hover .menu-card-item-title,
        a:hover .menu-card-item-subtitle,
        .features-card-item-title a:hover,
        .features-card-item-subtitle a:hover,
        .features-card-item-description a:hover,
        nav.menu-card-item a:hover {
          color: ${scheme.Card.link_hover};
        }
        a:hover .menu-card-item-title,
        a:hover .menu-card-item-subtitle,
        nav.menu-card-item a:hover {
          border-bottom-color: ${scheme.Card.link_hover};
        }
        .menus .menu-icon-card-item-description,
        .menus .menu-card-item-description {
          color: ${scheme.Card.text} !important;
        }
         .nav-items svg {
          stroke: ${scheme.Card.svg_icon};
        }
        .menu-icon-card svg {
          fill: ${scheme.Card.svg_icon};
        }
        .menu-card-item-border-0-1,
        .menu-card-item-border-1-1,
        .menu-card-item-border-2-1,
        .contact-card-border-0-1,
        .contact-card-border-1-1 {
          background-color: ${scheme.Card.background_color};
        }
       
        .menu-card-item-border-0-2,
        .menu-card-item-border-1-2 {
          background-image: ${backgroundImage(scheme.Card.background_image)};
        }
        .menu-card-item-border-2-1 {
          background-image: ${backgroundImage(scheme.Card.background_image)};
        }
        @media screen and (max-width: 640px) {
            .menu-card-item-border-0-2,
            .menu-card-item-border-1-2 {
              background-image: ${backgroundImage(scheme.Card.background_image)};
            }
        }
        .menu-card-item-border-0-2,
        .menu-card-item-border-1-2 {
           background-repeat: ${scheme.Card.background_image_repeat};
        }
        @media screen and (max-width: 640px) {
         .menu-card-item-border-0-2,
         .menu-card-item-border-1-2 {
           background-repeat: ${scheme.Card.background_image_repeat};
         }
        }
        .menu-card-item-border-0-2,
        .menu-card-item-border-1-2 {
          opacity: ${scheme.Card.background_image_opacity};
        }
        @media screen and (max-width: 640px) {
         .menu-card-item-border-0-2,
         .menu-card-item-border-1-2 {
           opacity: ${scheme.Card.background_image_opacity};
         }
        }
        `
    }

    if (scheme.Card?.background_color_hover) {
        const BG_opacity = scheme.Card?.background_opacity ?? 1
        CSS += `
        a.menu-card-item-border-0-1:hover,
        a.menu-card-item-border-1-1:hover,
        a.menu-card-item-border-2-1:hover {
          background-color: ${scheme.Card?.background_color_hover}${(BG_opacity / 255).toString(16)};
        }
        `
    }

    if (scheme.background_color) {
        CSS += `
        .photo-set-collage-section .after > :first-child::before {
          background-color: ${scheme.background_color};
        }
        `
    }

    return CSS
}

export default createColorSchemeCSS
