import {CopySuccess, Danger, Information, Warning2} from 'iconsax-react'
import {Card, Grid, Text} from '@nextui-org/react'
import React from 'react'

export type AlertProps = {
    type: 'success' | 'error' | 'warning' | 'info',
    message: string,
    title: string,
    buttons?: any
}

const ShowAlertBox = (information: AlertProps | undefined) => {
    if (typeof information === 'undefined') return <></>
    const alertTypeMap = {
        success: {
            icon: <CopySuccess size='32'/>,
            color: 'success',
        },
        error: {
            icon: <Danger size='32'/>,
            color: 'error',
        },
        warning: {
            icon: <Warning2 size='32'/>,
            color: 'warning',
        },
        info: {
            icon: <Information size='32'/>,
            color: 'primary'
        }
    }
    return <Card variant='flat' color={`$${alertTypeMap[information.type].color}Light`} style={{
        padding: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        marginBottom: '10px'
    }}>
        <Card.Body>
            <Grid.Container gap={1}>
                <Grid alignContent={'center'}>
                    <Text color={alertTypeMap[information.type].color} style={{
                        marginTop: '7px'
                    }}>
                        {alertTypeMap[information.type].icon}
                    </Text>
                </Grid>
                <Grid>
                    <Text color={alertTypeMap[information.type].color} h5 style={{
                        margin: 0,
                    }}>
                        {information.title}
                    </Text>
                    <Text color={alertTypeMap[information.type].color} size='$sm'>
                        {information.message}
                    </Text>
                    {typeof information.buttons !== 'undefined' ? <div style={{
                        paddingTop: '10px'
                    }}>
                        {information.buttons}
                    </div> : <></>}
                </Grid>

            </Grid.Container>

        </Card.Body>
    </Card>
}

export default ShowAlertBox
