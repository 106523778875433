import NextImage, {ImageProps} from 'next/image'

const LucidImage = (props: ImageProps & {className?: string}) => {
    const src = props.src as string | undefined

    if (typeof src === 'undefined') return null

    const extraProps: Partial<ImageProps> = {
        unoptimized: true,
        layout: 'responsive',
        className: 'progressive-image'
    }

    if (!props.width || !props.height) {
        extraProps['layout'] = 'fill'
        extraProps['objectFit'] = 'contain'
    }

    if (!props.priority) {
        extraProps['loading'] = 'lazy'
    }

    if (src.includes('.imgix.net')) {
        extraProps['unoptimized'] = false
    }
    return NextImage({...props, ...extraProps})
}

export default LucidImage
