import type {Page, Site} from '@/components/shared/types'
import {BuildVersion, ProxyConfigStatus} from '@/components/shared/types'
import {buttonModifier, DefaultRoundButton, DefaultRoundLink} from '@/src/styles/home'
import styled from 'styled-components'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {SITE_QUERY} from '@/graphql/queries'
import {SITE_BUILD_NEEDS_SYNC, UPSERT_PROXY_CONFIG} from '@/graphql/mutations'
import useSWR, {mutate as globalMutate, SWRResponse} from 'swr'
import {useState} from 'react'
import {useRouter} from 'next/router'


interface ProxyConfigurationButtonProps {
  site: Site,
  page: Page
}

const ProxySync = styled.div<{ isLoading: boolean }>`
  ${buttonModifier};

  button, a {
    width: auto;
    height: 34px;
    margin: 0 0 4px;
  }

  button {
    width: ${props => props.isLoading ? '210px' : 'auto'};
    margin-left: 4px;
  }

  a {
    width: 130px;
  }
`

const ProxyConfigButton = ({site, page}: ProxyConfigurationButtonProps) => {
  const router = useRouter()
  const {asPath} = router
  const [, , siteBuildVersion, siteId, pageBuildId, pageBuildVersion] = asPath.split(
    '/'
  ) as ['', 'edit', BuildVersion, string, string, BuildVersion]

  const proxyConfig = site.proxy_configs.find(pc =>
    pc.site_build_version === siteBuildVersion && pc.page_build_version === pageBuildVersion
  )
  const isDeploying = proxyConfig?.config_status === ProxyConfigStatus[ProxyConfigStatus.DEPLOYING]

  let fetchKey: [string, Record<string, any>] | null = null
  if (proxyConfig) {
    fetchKey = [SITE_BUILD_NEEDS_SYNC, {
      site_build_version: siteBuildVersion,
      page_build_version: pageBuildVersion,
      site_id: site.id
    }]
  }
  const {data: {data: {siteBuildNeedsProxySync}}, error, mutate, isValidating}: SWRResponse = useSWR<{
    data: { siteBuildNeedsProxySync: boolean }
  }>(
    fetchKey,
    lucidDataFetcherV2 as (query: string, variable?: {}) => Promise<{ data: { siteBuildNeedsProxySync: boolean } }>,
    {
      revalidateOnFocus: false,
      fallbackData: {
        data: {
          siteBuildNeedsProxySync: false
        }
      }
    }
  )

  const [isLoading, setIsLoading] = useState(isDeploying)

  async function syncWithProxy() {
    if (isLoading || isValidating || (proxyConfig && !siteBuildNeedsProxySync)) return

    setIsLoading(true)
    await lucidDataFetcherV2(UPSERT_PROXY_CONFIG, {
      site_id: site.id,
      site_build_version: siteBuildVersion,
      page_build_version: pageBuildVersion
    })
    await globalMutate([SITE_QUERY, {id: site.id}])
  }

  let pageUrl = `https://www.${site.domain}${page.route.url}`
  if (proxyConfig) {
    // NOTE:
    //  Initially, A site is only available via http, once a cert is generated,
    //  proxy API will automatically redirect to a secured connection
    pageUrl = `http://${proxyConfig.proxy_config_id}.${process.env.NEXT_PUBLIC_DOMAIN}${page.route.url}`
  }

  let className = 'proxy-sync'
  let label = 'Create Proxy Config'
  if (proxyConfig) {
    label = 'Sync Proxy Config'
  }
  if (isValidating || isLoading || (proxyConfig && !siteBuildNeedsProxySync)) {
    className += ' button-disabled'
  }

  if (isLoading) {
    className += ' button-loading'
  }

  return (
    <ProxySync isLoading={isLoading} style={{display: 'flex'}}>
      {!isDeploying && proxyConfig &&
        <DefaultRoundLink style={{paddingTop: '6px'}} target='_blank' href={pageUrl}>Visit Page</DefaultRoundLink>
      }
      <DefaultRoundButton onClick={syncWithProxy} className={className}>{label}</DefaultRoundButton>
    </ProxySync>
  )
}

export default ProxyConfigButton
