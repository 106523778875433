import {useRef} from 'react'
import {Button} from '@einsteinindustries/tinacms-styles'
import {BaseTextField} from '@einsteinindustries/tinacms-fields'
import {
  DialogOverlay,
  DialogContent,
  InputLabel,
  FormButtonBar,
} from './styles'
import {REMOVE_ROUTE, UPDATE_ROUTE} from '@/src/graphql/mutations'
import {ConfirmDialog} from '../../shared/AlertDialogs'
import {useState} from 'react'

function UpdateRouteDialog(props) {
  const {isOpen, close, updateRoute, removeRoute, route} = props

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleDeleteRoute = () => {
    setShowDeleteDialog(true)
  }

  const handleDialogClose = (confirmed) => {
    if (confirmed) {
      removeRoute(REMOVE_ROUTE, {id: route.id})
      close()
    }
    setShowDeleteDialog(false)
  }

  const urlRef = useRef()

  return (
    <>
      <ConfirmDialog
        setIsOpenState={showDeleteDialog}
        close={() => setShowDeleteDialog(false)}
        callback={handleDialogClose}
        onDismiss={() => setShowDeleteDialog(false)}
        title="Delete Route"
        message={`Are you sure you want to delete the route: "${route.url}"?`}
      />
      <DialogOverlay
        isOpen={isOpen}
        onDismiss={close}
      >
        <DialogContent
          aria-label="Edit Route"
          style={{overflow: 'auto'}}
        >
          <h3>Edit Route</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault()

              const variables = {
                id: route.id,
                updateRouteData: {
                  url: urlRef.current.value,
                },
              }

              updateRoute(UPDATE_ROUTE, variables)
              close()
            }}
          >
            <InputLabel>Path</InputLabel>
            <BaseTextField
              style={{marginBottom: 'var(--tina-padding-big)'}}
              type="text"
              defaultValue={route.url}
              ref={urlRef}
            />
            <FormButtonBar>
              {/* TODO: Should this be a toggle for the "active" attribute? */}
              <Button
                type="button"
                margin={true}
                style={{color: 'var(--tina-color-error)', marginRight: 'auto'}}
                onClick={() => handleDeleteRoute()}
              >
                Delete Route
              </Button>
              <Button
                type="button"
                onClick={close}
                margin={true}
              >
                <span>Cancel</span>
              </Button>
              <Button
                type="submit"
                primary
                margin={true}
              >
                <span>Save</span>
              </Button>
            </FormButtonBar>
          </form>
        </DialogContent>
      </DialogOverlay>
    </>
  )
}

export default UpdateRouteDialog
