import React from 'react'

type Props = {
    title: string
    src: string
    poster: string
    size: number
  }

const SectionVideoLooping: React.FC<Props> = ({title, src, poster, size} : Props) => {
  if (!src) {
    return <p>Sorry, no video source was provided.</p>
  }

  return (
    <video className="video" playsInline autoPlay loop muted preload="auto" onError={(e) => {
      e.currentTarget.style.display = 'none'
      console.error('Video failed to load')
    }}>
      <source src={src} type="video/mp4"></source>
      Your browser does not support HTML5 video.
    </video>
  )
}

export default SectionVideoLooping
