import {useCMS} from '@einsteinindustries/tinacms'
import {markBlocksAsChanged} from '@/components/UI/ConfirmPageSave'
import {SectionName} from '@/components/shared/types'

/**
 * TODO:
 * This workaround, which builds markBlocksAsChanged workaround,
 * sets the form to dirty. This functionality should be built into TinaCMS,
 * and should be exposed here.
 *
 * This hook makes the ease of finding the form simpler and more accessible.
 * @param target
 */
export function useFormDirty(target: SectionName = 'body_blocks') {
  const cms = useCMS()
  const mainFormName = 'Page Setup'
  return () => {
    const form = cms.forms.all().find((e) => e.label === mainFormName)
    if (typeof form !== 'undefined') {
      markBlocksAsChanged(form, form.finalForm.getState().values, target)
    }
  }
}
