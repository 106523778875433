import type {NextUIFieldProps} from '@/src/utils/shared/FormManagerFormNextUI'
import type {FormState} from '@/components/shared/externalTypes'

export const styleCore = {
  config: {
    grid: {
      content: {
        itemsPerRow: {
          default: 3,
          content: 'number',
          min: 1,
          max: 10,
          step: 1,
          description: 'The number of items per row',
          listener: (formState: FormState, fieldState: string, field: NextUIFieldProps) => {
            if (!formState?.grid?.enableSizing) {
              return {
                ...field,
                locked: true,
                message: {
                  state: 'error',
                  message: 'You must enable sizing to use this field'
                }
              }
            }
            if (Number(fieldState) > 4) {
              return {
                ...field,
                message: {
                  state: 'warning',
                  message: 'Any layout size above 4 is not recommended'
                }
              }
            }
            return field
          },
          postProcessing: (formState: FormState, fieldState: string) => {
            return `${Math.round(((1 / Number(fieldState))) * 10000) / 100}%`
          }
        },
        enableSizing: {
          default: false,
          content: 'toggle',
          description: 'Enable the column sizing options',
        },
        padding: {
          default: 10,
          content: 'number',
          min: 0,
          unit: 'px',
          description: 'The padding around items',
        }
      }
    }
  },
  target: {
    componentName: 'menuicon',
  },
  css: `
    {target} .column {
       flex: {grid.enableSizing ? 0 : 1} {grid.enableSizing ? 0 : 1} {grid.itemsPerRow} !important;
       max-width: {grid.itemsPerRow} !important;
       padding: {grid.padding} !important;
    }
  `
}
