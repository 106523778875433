import {StyleCoreCSSComponent} from './CMSSection'
import {SectionMeta, StyleCoreRenderedMap, StyleCoreTarget} from '@/components/shared/StyleCore'

type StyleManagerProps = {
  cms: boolean,
  meta: SectionMeta,
  styleCoreTarget: StyleCoreTarget,
  prefixCss: string | undefined,
  prerenderedStyles: StyleCoreRenderedMap
}

// Simple preprocessing to prefix all rules with our randomly generated class name
export const preprocessCss = (css_overrides: string | undefined, cn: string) => {
  return css_overrides?.split('}').filter(rule => rule !== '').map(rule => `.${cn} ${rule}}`).join('')
}

const StyleManager = ({cms, meta, styleCoreTarget, prefixCss, prerenderedStyles}: StyleManagerProps) => {
  if (cms) {
    return <>
      <StyleCoreCSSComponent target={styleCoreTarget} cms={cms}/>
      <style jsx global>{`${prefixCss ?? ''}`}</style>
    </>
  } else {
    const mapElement = prerenderedStyles.get(styleCoreTarget?.identifier ?? '')
    let styles = meta?.style?.css
    if (typeof mapElement !== 'undefined') {
      styles = mapElement.css
    }
    return <>
      <style jsx global>{`${prefixCss ?? ''} ${styles ?? ''}`}</style>
    </>
  }
}

export default StyleManager
