import {Media} from '@einsteinindustries/tinacms-core'

export const objectToMedia = (
  item: { [k: string]: any },
  readUrl: string
): Media => {
  const originPath = item.attributes?.origin_path || item.Key

  const previewable = ['jpg', 'jpeg', 'png', 'webp', 'svg', 'ico', 'avif']
  const directory = originPath.substring(originPath.indexOf('/') + 1, originPath.lastIndexOf('/'))
  const filenameOnly = originPath.substring(originPath.lastIndexOf('/') + 1)
  const extension = originPath.substring(originPath.lastIndexOf('.') + 1)

  const mediaItem: Media = {
    id: item.id || item.Key,
    filename: filenameOnly,
    directory,
    type: 'file',
    metaData: item,
  }
  mediaItem.metaData.sourceUrl = readUrl
  if (!readUrl.endsWith('/')) {
    mediaItem.metaData.sourceUrl += '/'
  }
  if (directory !== '/') {
    mediaItem.metaData.sourceUrl += directory + '/'
  }
  mediaItem.metaData.sourceUrl += filenameOnly

  if (previewable.includes(extension.toLowerCase())) {
    mediaItem.previewSrc = mediaItem.metaData.sourceUrl + '?w=400&h=400&fit=crop&crop=entropy&auto=format,enhance'
  }
  return mediaItem
}

export const getFileContents = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (readEvent) => {
      resolve(readEvent.target?.result as ArrayBuffer)
    }

    reader.readAsDataURL(file)
  })
}
