import React, {useState} from 'react'
import {PageBuild, User} from '@/components/shared/types'
import {updatePagesWorkflow} from './AssignUserGroup'
import DialogLayout from '@/components/shared/DialogLayout'

interface RejectDialogProps {
  showDialog: boolean
  closeDialog: () => void
  payload: [PageBuild[], User]
  onSave: () => void
}

const RejectDialog = ({
  showDialog,
  closeDialog,
  payload,
  onSave,
}: RejectDialogProps) => {
  const [comment, setComment] = useState('')
  const [pages] = payload

  const handleSave = async () => {
    await updatePagesWorkflow(pages, undefined, 'IN_PROGRESS')
    closeDialog()
    onSave()
  }

  return (
    <DialogLayout
      title={`Reject Dialog - ${comment ? '' : 'Comment required'}`}
      showDialog={showDialog}
      closeDialog={closeDialog}
      onSave={handleSave}
      isSaveAllowed={!!comment}
    >
      <div style={{margin: '0 1rem'}}>
        <label
          htmlFor="story"
          style={{display: 'block'}}
        >
          Reasons for reject:
        </label>
        <br />
        <textarea
          id="story"
          rows={7}
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
    </DialogLayout>
  )
}

export default RejectDialog
