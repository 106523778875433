import styled, {css} from 'styled-components'
import {MenuButton,} from '@reach/menu-button'
import React, {ChangeEvent, Dispatch, Key, SetStateAction, useState} from 'react'
import {Button, Dropdown, FormElement, Input, Modal, Spacer, Text} from '@nextui-org/react'
import {onMenuChangeAction, ToolbarProps} from '../../utils/types'

const buttonStyle = css`
  background: linear-gradient(0deg, #efefef 0%, #fff 100%);
  border: 1px solid #979797;
  padding-left: 10px;
  padding-right: 10px;
`

export const StyledButton = styled.button`
  ${buttonStyle}
`
export const StyledMenuButton = styled(MenuButton)`
  ${buttonStyle}
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #979797;
  position: sticky;
`

const StyledSearch = styled.input`
  border: none;
  border-bottom: 1px solid grey;
`

export default function Toolbar({onMenuChange}: ToolbarProps) {

  const [search, setSearch] = useState<string | undefined>()
  const [currentModal, setCurrentModal] = useState<string | undefined>()

  function onSearch({target}: React.ChangeEvent<HTMLInputElement>) {
    setSearch(target.value)
  }

  function onSelectionChanged(keys: any): any {
    setCurrentModal(keys.currentKey)
  }

  return (
    <Wrapper>
      <StyledSearch
        type="text"
        onChange={onSearch}
        value={''}
        placeholder="Search ..."
      />

      <Button.Group flat bordered>
        <Button
          size='sm'
          disabled={true}>
          Sort & Filter
        </Button>
        <Dropdown>
          <Dropdown.Button size='sm'>
            <span aria-hidden>Add</span>
          </Dropdown.Button>
          <Dropdown.Menu
            selectedKeys={[] as Iterable<Key>}
            selectionMode='single'
            onSelectionChange={onSelectionChanged}>
            <Dropdown.Item key={'group'}>Group</Dropdown.Item>
            <Dropdown.Item key={'link'}>Link</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
      <CreateGroupModal
        display={currentModal === 'group'}
        onMenuChange={onMenuChange}
        setCurrentModal={setCurrentModal}
      />
      <CreateLinkModal
        display={currentModal === 'link'}
        onMenuChange={onMenuChange}
        setCurrentModal={setCurrentModal}
      />
    </Wrapper>
  )
}

function CreateLinkModal(
  {display, onMenuChange, setCurrentModal}:
    {
      display: boolean,
      onMenuChange: onMenuChangeAction,
      setCurrentModal: Dispatch<SetStateAction<string | undefined>>
    }) {

  const [name, setName] = useState('')
  const [link, setLink] = useState('https://')
  const [hasError, setHasError] = useState(true)
  function onCancel() {
    setCurrentModal(undefined)
  }

  function onConfirm() {
    onMenuChange({type: 'addLink', payload: {name, link}})
  }

  function onNameInputChanged({target}: ChangeEvent<FormElement>) {
    setName(target.value)
  }

  function onLinkInputChanged({target}: ChangeEvent<FormElement>) {
    if (target.value.trim() === 'https://' || target.value.indexOf('https://') !== 0) {
      setHasError(true)
      setLink('https://')
    } else {
      setHasError(false)
      setLink(target.value)
    }
  }

  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="Create External Link"
        open={display}
        onClose={onCancel}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            <Text b size={18}>
              Menu Group
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Spacer y={1} />
          <Input
            aria-label='name'
            labelPlaceholder='Name'
            onChange={onNameInputChanged}
          />
          <Spacer y={1} />
          <Input
            bordered={hasError}
            color={hasError ? 'error' : 'default'}
            aria-label='url'
            labelPlaceholder='URL'
            value={link}
            onChange={onLinkInputChanged}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={onCancel}>
            Cancel
          </Button>
          <Button disabled={name.trim() === '' || hasError} auto onPress={onConfirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function CreateGroupModal(
  {display, onMenuChange, setCurrentModal}:
    {
      display: boolean,
      onMenuChange: onMenuChangeAction,
      setCurrentModal: Dispatch<SetStateAction<string | undefined>>
    }) {

  const [name, setName] = useState('')
  function onCancel() {
    setCurrentModal(undefined)
  }

  function onConfirm() {
    onMenuChange({type: 'addGroup', payload: {name}})
  }

  function onInputChanged({target}: ChangeEvent<FormElement>) {
    setName(target.value)
  }

  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="Create group"
        open={display}
        onClose={onCancel}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            <Text b size={18}>
              Menu Group
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
            aria-label='new menu group name'
            placeholder='New Menu Group Name'
            onChange={onInputChanged}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={onCancel}>
            Cancel
          </Button>
          <Button disabled={name.trim() === ''} auto onPress={onConfirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
