import {toast} from 'react-toastify'
import {UPDATE_COLOR_SCHEME} from '@/graphql/queries'
import {
  ADD_COLOR_SCHEME,
  REMOVE_COLOR_SCHEME,
  SET_ACTIVE_COLOR_SCHEME,
  UPDATE_COLOR_SCHEME_COMPONENT
} from '@/graphql/mutations'
import {lucidDataFetcherV2, serverSideLucidFetcher} from '@/src/graphql/fetchers'

export interface ColorSchemeValues {
  rootColorSchemeId: string
  scheme_name: string
  name: string
  active: boolean
  colorSchemeID: string
  background_image?: string
  background_image_opacity: number
  background_image_repeat: boolean
  title: string
  subtitle: string
  headings: string
  text: string
  link: string
  link_hover: string
  button_background: string
  button_hover: string
  button_text: string
  svg_icon: string
  svg_ornament: string
  blockquote_border: string
  background_color: string
  underlay_color: string
  'Menu Item': MenuItem
  'Button Secondary': ButtonSecondary
  Card: Card
  'Block Item': BlockItem
  Collage: Collage
}

interface MenuItem {
  colorSchemeID: string
  title: string
  subtitle: string
}

interface ButtonSecondary {
  colorSchemeID: string
  button_hover: string
  button_text: string
  button_background: string
}

interface Card {
  colorSchemeID: string
  background_image: string
  background_image_opacity: number
  background_image_repeat: boolean
  background_opacity: number
  link: string
  link_hover: string
  title: string
  subtitle: string
  text: string
  svg_icon: string
  background_color: string
  background_color_hover: string
}

interface BlockItem {
  colorSchemeID: string
  title: string
  subtitle: string
  background_color: string
}

interface Collage {
  colorSchemeID: string
  box_layer_1_background_color: string
  box_layer_2_background_color: string
  accent_text: string
  box_layer_1_opacity: number
  box_layer_2_opacity: number
  accent_text_opacity: number
}

type ColorSchemeComponents = Collage | BlockItem | Card | ButtonSecondary | MenuItem

type ColorSchemeValuesValue = ColorSchemeValues | ColorSchemeValues[keyof ColorSchemeValues]

export interface ColorSchemeLayoutItem {
  label: string
  name: string
  description?: string
  component: 'color' | 'toggle' | 'group' | 'number' | 'text' | 'group-list' | 'image'
}

export interface ColorSchemeLayoutColor extends ColorSchemeLayoutItem {
  itemProps: any
  defaultItem: any
}

export type ColorSchemeLayoutParent = ColorSchemeLayoutItem

export type ColorSchemeLayoutText = ColorSchemeLayoutItem

export interface ColorSchemeLayoutToggle extends ColorSchemeLayoutItem {
  toggleLabels: {
    true: string,
    false: string
  }
}

export interface ColorSchemeLayoutNumber extends ColorSchemeLayoutItem {
  step: number
}

export interface ColorSchemeLayoutGroup extends ColorSchemeLayoutItem {
  fields: ColorSchemeLayoutItem[]
}

export type ColorSchemeLayoutElement = (ColorSchemeLayoutParent | ColorSchemeLayoutItem | ColorSchemeLayoutColor | ColorSchemeLayoutText | ColorSchemeLayoutToggle | ColorSchemeLayoutNumber | ColorSchemeLayoutGroup)

export interface ColorSchemeComponentContentResponse {
  hex?: string,
  URL?: string,
  range?: number,
  checkbox?: boolean,
  SVG?: string
}

export interface ColorSchemeComponentResponse {
  id: string,
  label: string,
  type: 'RANGE' | 'HEX' | 'URL' | 'CHECKBOX' | 'SVG',
  component_content: ColorSchemeComponentContentResponse,
  component_attributes?: {
    min?: number,
    max?: number,
  }
}

export interface ColorSchemeResponse {
  id: string,
  name: string,
  active: boolean,
  parent_color_scheme_id?: string,
  site_build_id: string,
  components: ColorSchemeComponentResponse[],
  children_color_schemes: ColorSchemeResponse[]
}

const computeInitialValues = (incomingColorSchemes: ColorSchemeResponse, appendIDs = true) => {
  const addition: ColorSchemeValues | any = {}
  if (appendIDs) addition.colorSchemeID = incomingColorSchemes.id
  for (const additionElement of incomingColorSchemes.components) {
    const componentType = reformatComponentTypeKey(additionElement.type)
      addition[additionElement.label as keyof ColorSchemeValues] = additionElement.component_content[componentType as keyof ColorSchemeComponentContentResponse] as ColorSchemeValuesValue
  }

  if (typeof incomingColorSchemes.children_color_schemes !== 'undefined') {
    for (const childColorScheme of incomingColorSchemes.children_color_schemes) {
        addition[childColorScheme.name as keyof ColorSchemeValues] = computeInitialValues(childColorScheme, appendIDs)
    }
  }
  return addition
}

const stripColorSchemeIDs = (colorScheme: ColorSchemeValues | ColorSchemeComponents) => {
  const addition: ColorSchemeValues | any = {}
  for (const key in colorScheme) {
    if (key === 'colorSchemeID') continue
    if (key === 'rootColorSchemeId') continue
    const currentElementValue = (colorScheme as ColorSchemeValues)[key as keyof ColorSchemeValues]
    if (typeof currentElementValue === 'object') {
      addition[key] = stripColorSchemeIDs(currentElementValue)
      continue
    }
    addition[key] = currentElementValue
  }
  return addition
}

export const computeColorSchemeValues = (colorSchemes: ColorSchemeResponse[]) => {
  return colorSchemes.map((scheme) => {
    return {
      rootColorSchemeId: scheme.id,
      scheme_name: scheme.name,
      name: scheme.name,
      active: scheme.active,
      ...computeInitialValues(scheme)
    }
  }) as ColorSchemeValues[]
}
export const getFormConfig = (colorSchemes: ColorSchemeResponse[], onSubmit: (values: { schemes: ColorSchemeValues[], activeScheme: string }) => void) => {
  return {
    id: 'color-schemes',
    label: 'Schemes',
    initialValues: {
      'schemes': computeColorSchemeValues(colorSchemes),
      'activeScheme': (colorSchemes.find((scheme: ColorSchemeResponse) => scheme.active) ?? colorSchemes[0])?.id
    },
    fields: [
      {
        name: 'schemes',
        label: 'Color Schemes',
        component: 'group-list',
        itemProps: ({id: key, name: label}: Record<string, any>) => {
          return {key, label}
        },
        defaultItem: {
          ...computeInitialValues(colorSchemes[0], false),
          name: 'New Color Scheme'
        },
        fields: [
          {
            label: 'Name',
            name: 'scheme_name',
            component: 'text',
          },
          ...computeInternalStructure(colorSchemes[0])
        ],
        onItemDuplication: (name: string, index: number, newItem: ColorSchemeValues, mutator: (name: string, index: number,newItem: ColorSchemeValues) => void ) => {
          mutator(name, index, stripColorSchemeIDs(newItem))
        }
      },
      {
        component: 'select',
        name: 'activeScheme',
        label: 'Site Color Scheme',
        description: 'Select an active site color scheme to be used on the site.',
        options: colorSchemes.sort(a => a.active ? -1 : 1).map((scheme) => ({label: scheme.name, value: scheme.id})),
      },
    ],
    onSubmit,
  }
}

const computeInternalStructure = (incomingColorScheme: ColorSchemeResponse) => {
  if (typeof incomingColorScheme === 'undefined') return []
  const addition: any[] = []
  const remapping = {
    'RANGE': 'number',
    'HEX': 'color',
    'URL': 'text',
    'CHECKBOX': 'toggle',
    'SVG': 'image'
  }
  for (const additionElement of incomingColorScheme.components) {
    addition.push({
      name: additionElement.label,
      component: remapping[additionElement.type] as ColorSchemeLayoutElement['component'],
      label: additionElement.label.replaceAll('_', ' ').toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    })
  }

  if (typeof incomingColorScheme.children_color_schemes !== 'undefined') {
    for (const childColorScheme of incomingColorScheme.children_color_schemes) {
      addition.push({
        name: childColorScheme.name,
        label: childColorScheme.name.replaceAll('_', '').toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        component: 'group',
        fields: computeInternalStructure(childColorScheme)
      })
    }
  }
  return addition
}

function isParentColorScheme(input: ColorSchemeValues | ColorSchemeValuesValue): input is ColorSchemeValues {
  return typeof input === 'object' && 'colorSchemeID' in input
}

export const computeChanges = (values: ColorSchemeValues[], activeSchemeValue: string, previous: ColorSchemeResponse[], siteBuildId: string): [any[], any[], any[], any[], any, boolean] => {
  const schemes: { color_scheme_id: string, name: string }[] = []
  const components: any[] = []
  const newSchemes: ColorSchemeValues[] = []
  let activeScheme: string | undefined = undefined
  let removableSchemes: any[] = []
  let hasError = false

  const processColorScheme = (
    colorScheme: ColorSchemeValues | ColorSchemeValuesValue,
    initialColorScheme?: ColorSchemeResponse,
    originalChildrenColorScheme?: ColorSchemeResponse['children_color_schemes'][number],
  ) => {
    const currentColorSchemeID = isParentColorScheme(colorScheme) ? colorScheme.colorSchemeID : undefined

    if (typeof currentColorSchemeID === 'undefined' && !initialColorScheme) {
      newSchemes.push(Object.assign({site_build_id: siteBuildId}, createNewColorScheme(colorScheme as ColorSchemeValues)))
      return
    }
    if (!initialColorScheme) {
      initialColorScheme = previous.find((scheme) => scheme.id === currentColorSchemeID)
    }
    if (!initialColorScheme || !isParentColorScheme(colorScheme)) return
    for (const key in colorScheme as ColorSchemeValues) {
      if (key === 'colorSchemeID') continue
      if (key === 'scheme_name' && initialColorScheme.name !== colorScheme.scheme_name) {
        schemes.push({color_scheme_id: currentColorSchemeID as string, name: colorScheme.scheme_name})
        continue
      }
      const currentElementValue = colorScheme[key as keyof ColorSchemeValues]
      if (typeof currentElementValue === 'object') {
        const existingChildrenColorSchemes = initialColorScheme.children_color_schemes.find(c => c.name === key)
        processColorScheme(currentElementValue, initialColorScheme, existingChildrenColorSchemes)
        continue
      }
      let originalComponent = originalChildrenColorScheme?.components?.find(c => c.label === key)
      if (!originalComponent && !originalChildrenColorScheme) {
        originalComponent = initialColorScheme?.components?.find(c => c.label === key)
      }
      if (!originalComponent) continue
      const accessKey = reformatComponentTypeKey(originalComponent.type) as keyof typeof originalComponent.component_content
      if (originalComponent.component_content[accessKey] !== currentElementValue) {
        components.push({
          color_scheme_id: originalChildrenColorScheme?.id ?? initialColorScheme.id,
          component_id: originalComponent.id,
          component_content: {
            [accessKey]: currentElementValue === '' ? 'transparent' : currentElementValue
          }
        })
      }
    }
  }
  for (const colorScheme of values) {
    if (verifyColorSchemeHasName(colorScheme.scheme_name)) {
      processColorScheme(colorScheme)
    } else {
      toast('Color scheme name is required', {type: 'error'})
      hasError = true
    }
  }
  if (activeSchemeValue) {
    const activeSchemeID = activeSchemeValue
    if (previous.find(scheme => scheme.active)?.id !== activeSchemeID) {
      activeScheme = activeSchemeID
    }
  }
  if (values.length < previous.length) {
    removableSchemes = removableSchemes.concat(findRemovableColorSchemes(previous, values))
  }
  return [schemes, components, newSchemes, removableSchemes, activeScheme, hasError]
}

const verifyColorSchemeHasName = (name: string|undefined) => {
  if (typeof name === 'undefined') {
    return false
  }
  return name.length > 0
}
const findRemovableColorSchemes = (colorSchemes: ColorSchemeResponse[], colorSchemeValues: ColorSchemeValues[]) => {
    const deletableColorSchemes: ColorSchemeResponse[] = []
    for (const colorScheme of colorSchemes) {
        if (colorSchemeValues.find(c => c.colorSchemeID === colorScheme.id)) continue
        deletableColorSchemes.push(colorScheme)
    }
    return deletableColorSchemes
}

const createNewColorScheme = (colorScheme: ColorSchemeValues): any => {
  return {
    name: colorScheme.name === 'New Color Scheme' ? colorScheme.scheme_name : colorScheme.name,
    components: Object.entries(colorScheme)
        .filter(([key, value]) => key !== 'name' && key !== 'scheme_name' && typeof value !== 'object')
        .map(([key, value]) => {
          return {
            label: key,
            type: findComponentType(value).toUpperCase(),
            component_content: {
              [reformatComponentTypeKey(findComponentType(value))]: value
            }
          }
        }),
    children_color_schemes: Object.entries(colorScheme)
        .filter(([, value]) => typeof value === 'object')
        .map(([key, value]) => {
          if (typeof value === 'object') {
            return createNewColorScheme(Object.assign(value, {name: key}))
          }
        })
  }
}

const findComponentType = (componentContent: any) => {
  if (typeof componentContent === 'number') return 'RANGE'
  if (typeof componentContent === 'boolean') return 'CHECKBOX'
  if (String(componentContent).startsWith('http')) return 'URL'
  if (typeof componentContent === 'string' && String(componentContent).startsWith('#')) return 'HEX'
  return 'SVG'
}

const reformatComponentTypeKey = (key: string): string => {
  const uc = key.toUpperCase()
  if (['URL', 'SVG'].includes(uc)) {
    return uc
  }
  return key.toLowerCase()
}


export async function updateColorScheme(updatedScheme: any): Promise<boolean> {
  try {
    await lucidDataFetcherV2(UPDATE_COLOR_SCHEME, updatedScheme)
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function updateColorSchemeComponent(updatedComponent: any): Promise<boolean> {
  try {
    await lucidDataFetcherV2(UPDATE_COLOR_SCHEME_COMPONENT, updatedComponent)
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function addColorScheme(newScheme: any): Promise<boolean> {
  try {
    await lucidDataFetcherV2(ADD_COLOR_SCHEME, {color_scheme_content: newScheme})
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function removeColorScheme(color_scheme_id: number): Promise<boolean> {
  try {
    await lucidDataFetcherV2(REMOVE_COLOR_SCHEME, {color_scheme_id})
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function setActiveColorScheme(color_scheme_id: number): Promise<boolean> {
  try {
    await serverSideLucidFetcher(SET_ACTIVE_COLOR_SCHEME, {color_scheme_id})
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
