// import dynamic from 'next/dynamic'
import SectionContactForm from '../shared/SectionContactForm'
import Section from '../shared/Section'
import {SectionQuoteFormWrapper} from '../shared/SectionQuoteFormWrapper'
import {styleCore} from './stylecore'


export default function ContactCard(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    author,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props


  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section stack contact-card-section'}
      name="contactcard"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <SectionQuoteFormWrapper
          cms={cms}
          title={title}
          subtitle={subtitle}
          intro={intro}
          quote={quote}
          author={author}
          className={'contact-form'}>
        <SectionContactForm {...props} />
      </SectionQuoteFormWrapper>
    </Section>
  )
}

ContactCard.StyleCore = styleCore
