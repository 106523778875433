import {MenuItem} from '@/components/shared/types'
import {
  addLinkRoute,
  addMenu,
  addMenuGroup,
  addMenuItem,
  deleteMenu,
  deleteMenuItem,
  updateMenuItem
} from '@/components/managers/MenuManager/features/menu/menuOperations'
import {DnDPayload, SortableListItem} from '@/components/managers/MenuManager/utils/types'

export function menuItemsFromPayload(payload: DnDPayload, selectedMenuItems: MenuItem[]): [MenuItem[], MenuItem[]] {
  let newMenuItems: (MenuItem & { is_new?: boolean })[] = [...selectedMenuItems]
  let originalMenuItems: (MenuItem & {is_new?: boolean})[] = [...selectedMenuItems]
  const parentMenuId = payload.newItems[0]?.parent_menu_item_id ?? payload.originalItems[0]?.parent_menu_item_id
  if (parentMenuId) {
    const group = newMenuItems.find(g => g.id === parentMenuId)
    if (group) {
      newMenuItems = newMenuItems.map(item => {
        if (item.id === group.id) {
          return {...item, group_menu_items: [...payload.newItems]}
        }
        return item
      })
      originalMenuItems = originalMenuItems.map(item => {
        if (item.id === group.id) {
          return {...item, group_menu_items: payload.originalItems.map(item => {
              return {...item, id: item.id?.split('-').pop() as string, group_menu_items: []}
          })}
        }
        return item
      })
    }
  } else {
    newMenuItems = [...payload.newItems]
  }
  return [newMenuItems, originalMenuItems]
}

export async function storeMenuItems(
  newMenuItems: (MenuItem & {is_new?: boolean, original_sort_order?: number})[],
  originalMenuItems: MenuItem[],
  childrenUpdated = false
) {

  const processedIndexes: number[] = []
  for (const newItem of newMenuItems) {

    let originalItem: MenuItem | undefined
    if (newItem.id) {
      originalItem = originalMenuItems.find((item, i) => {
        if (item.id === newItem.id) {
          processedIndexes.push(i)
          return true
        }
        return false
      })
    }

    if (originalItem) {
      if (typeof newItem.original_sort_order !== undefined && newItem.original_sort_order !== newItem.sort_order) {
        await updateMenuItem(newItem.id, newItem.sort_order)
      }
    } else if (newItem.is_new) {
      await addMenuItem(newItem)
    }
    if (childrenUpdated) {
      await storeMenuItems(newItem.group_menu_items ?? [], originalItem?.group_menu_items ?? [])
    }
  }
  for (const [idx, og] of originalMenuItems.entries()) {
    if (processedIndexes.indexOf(idx) === -1) {
      const id = og.id?.split('-').pop()
      if (id) {
        await deleteMenuItem(id)
      }
    }
  }
}

export async function deleteToolbarItem(
  selectedItem: SortableListItem
) {
  if (typeof selectedItem.id !== 'undefined' && selectedItem.id !== null) {
    await deleteMenuItem(selectedItem.id.split('-').pop() as string)
  }
}

export async function handleDeleteMenu(
  menu_id?: string
): Promise<boolean> {
  if (menu_id) {
    return await deleteMenu(menu_id)
  }
  return false
}


export async function handleNewMenu(
  name: string,
  site_build_id: string
): Promise<boolean> {
  await addMenu(name, site_build_id)
  return true
}

export async function handleNewMenuGroup(
  name: string,
  site_build_id: string
): Promise<boolean> {
  await addMenuGroup(name, site_build_id)
  return true
}

export async function handleNewLink(
  name: string,
  url: string,
  site_build_id: string
): Promise<boolean> {
  try {
    const {id} = await addLinkRoute(name, url, site_build_id)
    await addMenuItem({
      route_id: id,
      sort_order: 0,
      label: name,
      site_build_id: site_build_id,
    })

  } catch (e) {
    console.error(e)
    return false
  }
  return true
}
