import PagesFilterBox from './utils/PagesFilterBox'
import {useEffect, useState} from 'react'
import {FilterState, Page, PageBuild} from '@/components/shared/types'

const INITIALIZE_FILTERS_VALUES = {
  status: 'Status Filtering',
  user: 'Assignee Filtering',
}

const INITIAL_FILTER_STATE = 'all'

interface PagesFilterProps {
  pageBuilds: PageBuild[]
  onChange: (filter: FilterState) => void
  filters?: string[]
  initialFilterState?: FilterState
}

const PagesFilter = ({
  pageBuilds,
  onChange,
  filters = ['status', 'user'],
  initialFilterState = {
    filterStatus: INITIAL_FILTER_STATE,
    filterAssignee: INITIAL_FILTER_STATE,
  },
}: PagesFilterProps) => {
  const [filterStatus, setFilterStatus] = useState(
    initialFilterState?.filterStatus
  )
  const [filterAssignee, setFilterAssignees] = useState(
    initialFilterState?.filterAssignee
  )

  useEffect(() => {
    onChange({filterStatus, filterAssignee})
  }, [onChange, filterStatus, filterAssignee])

  return (
    <div style={{display: 'flex'}}>
      {filters.includes('status') && (
        <PagesFilterBox
          pages={pageBuilds}
          initializedStatusFilterValue={INITIALIZE_FILTERS_VALUES.status}
          filterStatusValue={filterStatus}
          onChange={setFilterStatus}
          fieldToBeFiltered="status"
        />
      )}
    </div>
  )
}

export default PagesFilter
