import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Mega Menu Block: fix link click area and line height',
      documentationURL: 'https://www.notion.so/einsteinindustries/Mega-Menu-Block-fix-link-click-area-and-line-height-7588513dcad0446992a879ec8f35607e?pvs=4'
    },
    allowedSections: [],
    notion_IID: '80',
    css: `
    .mega-menu-block-section .mega-menu-block-page-list a {
    padding: .5rem 0 !important;
    line-height: 1.2;
}
    `
  },
  {
    display: {
      name: 'Mega Menu Block: Flip Cards',
      documentationURL: 'https://www.notion.so/einsteinindustries/Mega-Menu-Block-Flip-Cards-3574fcf6a7ff499f8517e90f2ec4319d?pvs=4'
    },
    allowedSections: [],
    notion_IID: '81',
    css: `
    
.mega-menu-block-section .mega-menu-block-item-container {
    padding: 2rem;
    text-align: left;
    width: 100%;
}
.mega-menu-block-page-list a:first-child, .mega-menu-block-page-list a:first-child:hover {
    order: 100;
    margin-top: 2rem;
}

.mega-menu-block-section .mega-menu-block-page-list {
    align-items: flex-start;
    transition: none;
        top: 0 !important;
  }

  .mega-menu-block-page-list a:nth-child(n+8) {
    display: none;
  }

  span.mega-menu-block-item-title.menu-block-item-title {
      color: #000;
  }


  .mega-menu-block-item  {
      perspective: 800px;
  }
  .mega-menu-block-section .mega-menu-block-items .mega-menu-block-item {

      overflow: visible;
      background-color: transparent;
    }


      .mega-menu-block-items .mega-menu-block-item:hover .mega-menu-block-item-aspect-ratio {
          transform: rotateY(180deg);
          transition: all 1s ease;
      }

      .mega-menu-block-items .mega-menu-block-item .mega-menu-block-item-aspect-ratio {
    transform-style: preserve-3d;
    box-shadow:
      0 0.9px 0.9px -18px rgba(0, 0, 0, 0.24),
      0 1.9px 2px -18px rgba(0, 0, 0, 0.182),
      0 3.4px 3.5px -18px rgba(0, 0, 0, 0.156),
      0 5.4px 5.5px -18px rgba(0, 0, 0, 0.137),
      0 8.3px 8.5px -18px rgba(0, 0, 0, 0.12),
      0 13px 13.3px -18px rgba(0, 0, 0, 0.103),
      0 21.5px 22px -18px rgba(0, 0, 0, 0.084),
      0 43px 44px -18px rgba(0, 0, 0, 0.058)
    ;
    transition: all 1s ease;
        transform: rotateY(
    0deg
     );

}

.mega-menu-block-item-container, .mega-menu-block-item-image {
    backface-visibility: hidden;
    position: absolute !important;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
}

.mega-menu-block-item-image {

    opacity: 1 !important;
}
.mega-menu-block-item-container {
    transform: rotateY(180deg);
}


.mega-menu-block-section .mega-menu-block-page-list a {
    font-weight: 400;
    padding: .5rem 0 !important;
    line-height: 1.2;
}


.mega-menu-block-section .mega-menu-block-items .mega-menu-block-item .mega-menu-block-item-aspect-ratio {
    position: relative;
    max-height: 433px;
}

.mega-menu-block-section .mega-menu-block-items .mega-menu-block-item {
    overflow: visible;
    background-color: transparent;
    flex-basis: 33%;
    padding: 1rem;
    max-width: 33%;
  }

  .mega-menu-block-item:hover {
    z-index: 1000;
}
    `
  },
  {
    display: {
      name: 'mega menu block: move label to bottom and animate',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/mega_menu_block-IID-161.png',
      documentationURL: 'https://www.notion.so/einsteinindustries/mega-menu-block-move-label-to-bottom-and-animate-95724eaa4ea94d109e0671016d944ff5?pvs=4'
    },
    allowedSections: [],
    notion_IID: '161',
    css: `
    .menu-block-section .menu-block-item-container {

  align-items: end;
}

.menu-block-section a.menu-block-item-container:hover span {
  transform: scale(1.04);
  transition: all 0.5s;

}


.menu-block-section .dimmer, .color-scheme-4 .mega-menu-block-item {

  background: linear-gradient(180deg, rgba(0,0,0,0) 60%, rgba(0, 0, 0, 0.8) 100%);
opacity: 1 !important
}

 .menu-block-section .menu-block-item-container span {

  transition: all 0.5s;
  padding-bottom: 1rem;
}


.menu-block-section a.menu-block-item-container:hover {
  background-color: transparent;
  opacity: 1;
}`
  }
]
