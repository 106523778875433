import React, {SetStateAction} from 'react'
import {Dispatcher} from '@/src/state/site/Store'
import {Form} from '@einsteinindustries/tinacms'
import {AnyField} from '@einsteinindustries/tinacms-forms'
import {ActiveSiteBuild, BlockContents, SectionName} from '../shared/types'
import {StyleCoreRenderedMap} from '../shared/StyleCore'
import {Button, Modal, Text} from '@nextui-org/react'
import {mutate as globalMutate} from 'swr'
import {PAGE_BUILD, SITE_QUERY} from '@/src/graphql/queries'
import deepEqual from '@/src/utils/deepEqual'

interface HandleConfirmProps {
  setConfirmModal: (value: SetStateAction<boolean>) => void,
  updateBuildSections: (activeSiteBuild: any, pageFormValues: any, styleCoreRenderedMap: any) => Promise<boolean>,
  activeSiteBuild: ActiveSiteBuild & {build_id: string},
  pageFormValues: any,
  styleCoreRenderedMap: StyleCoreRenderedMap,
  setSiteSectionsSaving: React.Dispatch<React.SetStateAction<boolean>>,
  site_id: string
  pageBuild_id: string,
  lucidSiteDispatch: Dispatcher
}

interface HandleRevertProps {
  setConfirmModal: (value: SetStateAction<boolean>) => void,
  pageForm: Form<any, AnyField>,
  pageFormValues: any,
  headerBlocks: BlockContents[],
  bodyBlocks: BlockContents[],
  footerBlocks: BlockContents[],
  lucidSiteDispatch: Dispatcher
}

interface HandleDismissProps {
  pageForm: Form<any, AnyField>,
  pageFormValues: any,
  setConfirmModal: (value: SetStateAction<boolean>) => void,
  lucidSiteDispatch: Dispatcher
}

interface ConfirmPageSaveProps {
  isOpen: boolean
  onDismiss: React.MouseEventHandler<HTMLButtonElement>
  onRevert: React.MouseEventHandler<HTMLButtonElement>
  onConfirm: React.MouseEventHandler<HTMLButtonElement>
}

export const markBlocksAsChanged = (pageForm: Form<any, AnyField>, pageFormValues: any, sectionName: SectionName) => {
  pageForm.finalForm.change(sectionName, Object.assign([], pageFormValues[sectionName]))
}

export const handleConfirm = async ({
  setConfirmModal,
  updateBuildSections,
  activeSiteBuild,
  pageFormValues,
  styleCoreRenderedMap,
  setSiteSectionsSaving,
  site_id,
  pageBuild_id,
  lucidSiteDispatch
}: HandleConfirmProps) => {
  setSiteSectionsSaving(true)
  setConfirmModal(false)
  await updateBuildSections(activeSiteBuild, pageFormValues, styleCoreRenderedMap)
  await globalMutate([SITE_QUERY, {id: site_id}])
  // Saving body sections
  // TODO: save body sections somewhere more appropriate
  // preferably using the same code when there is no header/footer change
  await globalMutate([PAGE_BUILD, {id: pageBuild_id}])
  setSiteSectionsSaving(false)
  lucidSiteDispatch({type: 'UPDATE', payload: {isSaving: false}})
}

export const handleRevert = ({
  setConfirmModal,
  pageForm,
  pageFormValues,
  headerBlocks,
  bodyBlocks,
  footerBlocks,
  lucidSiteDispatch
}: HandleRevertProps) => {
  setConfirmModal(false)
  pageFormValues.header_blocks = headerBlocks
  pageFormValues.footer_blocks = footerBlocks
  pageForm.reset()
  if (!deepEqual(pageFormValues.body_blocks, bodyBlocks)) {
    markBlocksAsChanged(pageForm, pageFormValues, 'body_blocks')
  }
  lucidSiteDispatch({type: 'UPDATE', payload: {isSaving: false}})
}

export const handleDismiss = ({
  pageForm,
  pageFormValues,
  setConfirmModal,
  lucidSiteDispatch
}: HandleDismissProps) => {
  markBlocksAsChanged(pageForm, pageFormValues, 'header_blocks')
  markBlocksAsChanged(pageForm, pageFormValues, 'footer_blocks')
  setConfirmModal(false)
  lucidSiteDispatch({type: 'UPDATE', payload: {isSaving: false}})
}

export const ConfirmPageSave: React.FC<ConfirmPageSaveProps> = ({
  isOpen,
  onDismiss,
  onRevert,
  onConfirm
}) => {
  return (
    <Modal
      area-aria-labelledby='confirm-page-save-modal'
      open={isOpen}
      className='modal'
      width="500px"
      preventClose={true}
    >
      <Modal.Header>
        <Text id="modal-title" b size={18}>
          Update Header/Footer?
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Text id="modal-body">You have modified a header or footer section that will be updated across the whole site. Press 'Save' to confirm, 'Revert' to undo, or 'Dismiss' to cancel.</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button auto flat color="warning" onClick={onDismiss}>
          Dismiss
        </Button>
        <Button auto flat color="error" onClick={onRevert}>
          Revert
        </Button>
        <Button auto color="success" onClick={onConfirm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
