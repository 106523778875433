import {PresetList} from '@/components/shared/types'
export const presets: PresetList = [
  {
    display: {
      name: 'Hero: Unzoom Video',
      documentationURL: 'https://www.notion.so/einsteinindustries/Hero-Unzoom-Video-c7855ce4a28e4c7d9801e0b46d33202f?pvs=4',
    },
    notion_IID: '67',
    allowedSections: ['hero'],
    css: `
    /* Hero: Unzoom Video */
    .hero-video video {
      width: 100vw !important;
      height: 100vh !important;
      object-fit: cover !important;
    }
    `
  },
  {
    display: {
      name: 'Hero: right align subtitle to hero width',
      documentationURL: 'https://www.notion.so/einsteinindustries/Hero-right-align-subtitle-to-hero-width-55da88f7960547b1ac9a1d4acbca9bf5?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/hero-IID-159.png'
    },
    notion_IID: '159',
    allowedSections: [],
    css: `
    section[class*="hero"] p.p-tagline {
      width: fit-content;
      margin: 0 auto;
    }
    `
  },
  {
    display: {
      name: 'Fixed hero video',
      documentationURL: 'https://www.notion.so/einsteinindustries/Fixed-hero-video-981311adc3144badb95fde9c2665845c?pvs=4',
      description: 'May need to be coupled with @Max width / box for entire body with gutters on sides'
    },
    notion_IID: '92',
    allowedSections: ['herotagline'],
    css: `
    .hero-video {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 1500px;
}
.home-page .layout .header-unstack {
    z-index: 0;
}
.hero-video video {
    max-width: 1500px;
}
    `
  }
]
