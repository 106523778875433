import {DocumentCode} from 'iconsax-react'
import styled from 'styled-components'

const StyledSourceIcon = styled(DocumentCode)`
  width: 40px;
  height: 28px;
  color: #a2a2a2;
  padding-top: 5px;
`

export const SourceMenuView: React.FC<{onClick: () => void}> = ({onClick}) => {
  return (
    <button
      onClick={onClick}
      className="wysiwyg-menu-item"
      title="Source"
    >
      <StyledSourceIcon fillOpacity={0} />
    </button>
  )
}
