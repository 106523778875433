import {Button, Card, Link, Loading, Text} from '@nextui-org/react'
import {Forbidden2, Refresh, RefreshCircle} from 'iconsax-react'
import {useState} from 'react'

const PageError = ({title,subtitle,refresh, error}: { title: string, subtitle: string, refresh: boolean, error?: string | object }) => {
  const [refreshing, setRefreshing] = useState(false)
  const [showError, setShowError] = useState(false)
  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }}><>
      <Card variant={'flat'} style={{minWidth: '600px', padding: '35px', paddingBottom: ((error && JSON.stringify(error) !== '{}') ? '5px' : '35px'), backgroundColor: '$red50', borderRadius: '50px'}}>
        <Card.Body>
          <Text color={'error'} size={'$sm'}><Forbidden2 variant={'Bulk'} size={50} /></Text>
          <Text color={'error'} size={'$sm'}>Server Error</Text>
          <Text color={'error'} h3 style={{
            marginBottom: 0
          }}>{title}</Text>
          <Text color={'error'}>{subtitle}</Text>
          <Text style={{
            paddingTop: '15px'
          }} size={'$sm'} color={'$gray400'}>This is to notify you of an issue communicating with the servers. This can happen either because of an error, or because you have lost connection to the server.</Text>
          {refresh ? <Button disabled={refreshing} size={'lg'} color={'error'} style={{marginTop: '30px', paddingBottom: 0, marginBottom: 0}} onClick={() => {
            setRefreshing(true)
            window.location.reload()
          }}>{refreshing ? <Loading color="currentColor" size="sm" /> : <><RefreshCircle size='24px'/>&nbsp; Refresh</>}</Button> : null}
          {
            (error && JSON.stringify(error) !== '{}') && <div style={{
              textAlign: 'center',
              width: '100%',
              marginTop: '13px'
            }}>
                  <Link onClick={(e) => setShowError(!showError) }>
                    {!showError ? 'Show' : 'Hide'} Error
                  </Link>
              </div>
          }
          {
            (JSON.stringify(error) !== '{}' && showError) && <Card variant={'flat'} style={{marginTop: '10px', padding: '10px', borderRadius: '50px'}}>
              <Card.Body>
                  <h3>The following error occurred</h3>
                  <code>{JSON.stringify(error)}</code>
              </Card.Body>
              </Card>
          }
        </Card.Body>
      </Card>
    </></div>
  )
}

export default PageError