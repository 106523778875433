import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Timeline: No outlines and Glass effect',
      documentationURL: 'https://www.notion.so/einsteinindustries/Timeline-No-outlines-and-Glass-effect-596f1278e46848559c19258aabab8709?pvs=4'
    },
    notion_IID: '49',
    allowedSections: [],
    css: `
    @media (min-width: 1024px) {
    section[data-lucid-group="Timeline (2)"] .timeline-item-container:not(.quote-container) {
        padding: 3rem;
        position: relative;
        backdrop-filter: blur(6px);
        background-color: #b5b5b533;
        box-shadow: 0px 10px 20px 0px #4242425c;
        max-width: calc(40% - 100px);
    }
}
section[data-lucid-group="Timeline (2)"] .timeline-item-container:not(.quote-container) {
    backdrop-filter: blur(6px);
    background-color: #fff3;
    box-shadow: 0px 10px 20px 0px #4242425c;
}

@media (min-width: 1024px) {
    body[data-uri="/"] [data-lucid-group="Timeline (1)"] .section-custom-background {
        background-attachment: fixed;
    }
    section[data-lucid-group="Timeline (2)"] .timeline-item-container:not(.quote-container) {
        padding: 3rem;
        position: relative;
        max-width: calc(40% - 100px);
    }
    section[data-lucid-group="Timeline (2)"] .timeline-item:first-child, section[data-lucid-group="Timeline (2)"] .timeline-item:nth-child(2) {
        flex: 0 0 40%;
        max-width: 40%;
        margin-top: 0;
    }
}

section[data-lucid-group="Timeline (2)"].timeline-section .row {
    padding-left: 0;
}

section[data-lucid-group="Timeline (2)"] .timeline-wrapper:after, section[data-lucid-group="Timeline (2)"] .timeline-item-container:before, section[data-lucid-group="Timeline (2)"] .timeline-wrapper:before {
    display: none;
}

section[data-lucid-group="Timeline (2)"] .timeline-item:first-child .timeline-item-container, section[data-lucid-group="Timeline (2)"] .timeline-item:nth-child(2) .timeline-item-container {
    max-width: 100% !important;
}
    `
  },
  {
    display: {
      name: 'Timeline: Slide-In',
      documentationURL: 'https://www.notion.so/einsteinindustries/Timeline-Slide-In-bb84ec545bd444a881c1272676b79c96?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/timeline-IID-60.gif'
    },
    notion_IID: '60',
    allowedSections: [],
    css: `
    .timeline-section .section-custom-background { background-attachment: fixed; }
.timeline-item-image {
    width: 60px;
    height: 60px;
    padding: 5px;
    left: -30px;
}

@media only screen and (max-width:1024px){ /* Queries */
    .timeline-item-image { left: auto; margin-top: 35px; margin-right: 35px; }
    .timeline-item-container::before, .timeline-wrapper::after { bottom: -70px; height: 70px; }
    .timeline-item:last-child .timeline-item-container::before { display: none; }
    .timeline-item-content { margin-top: -35px; }
}

.animated.effect-fade-in-up .timeline-item:nth-child(odd) .timeline-item-container { animation: 3s timeline-slideFromRight; } /* Animations */
.animated.effect-fade-in-up .timeline-item:nth-child(even) .timeline-item-container { animation: 3s timeline-slideFromLeft; }
.timeline-item-container::before { animation: 3s timeline-fadeInSlow; }

@keyframes timeline-slideFromRight { 
\tfrom { left: 100%; opacity: 0; } 
\tto { left: 0%; opacity: 1; } 
}
@keyframes timeline-slideFromLeft { 
\tfrom { right: 100%; opacity: 0; } 
\tto { right: 0%; opacity: 1; } 
}
@keyframes timeline-fadeInSlow {
    0% { opacity: 0; }
    80% { opacity: 0; }
    100% { opacity: 0.4; }
}
    `
  }
]
