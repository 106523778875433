import React, {useEffect, useMemo, useState} from 'react'
import {ColorSwatch} from 'iconsax-react'
import {Badge} from '@nextui-org/react'
import {ColorSchemeValues} from '@/components/managers'
import {
  SectionMeta,
  StyleCoreComponentName,
  StyleCoreHelpers,
  StyleCoreOverload,
  StyleCoreTarget,
  useStyleCoreDispatcher,
  useStyleCoreMap,
  useStyleCorePreRendered,
  useStyleCoreReceiver
} from '@/components/shared/StyleCore'
import {useLucidContext} from '@/src/state/ServerSideStore'
import {
  CMSHiding,
  CssOverridesContainer,
  OverridesContainerAwareness,
  StyleCoreFormInnerSettings,
  StylesModalContent,
  SwitchColorScheme
} from '@/components/sections/shared/CMSSection'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {BackgroundType} from './SectionBackground'
import {useColorScheme} from './useColorScheme'
import {useOverridesWindowState} from './useOverridesWindowState'
import dynamic from 'next/dynamic'
import StyleManager, {preprocessCss} from './StyleManager'
import createColorSchemeCSS from '@/components/sections/shared/CSSColorScheme'
import styled from 'styled-components'


const DynamicSectionBackground = dynamic(() => import('./SectionBackground'), {
  ssr: false
})

interface SectionProps {
  colorSchemeId?: string // DELETE
  color_scheme_id_override: string | null
  children: React.ReactNode
  className?: string
  name: string
  style?: React.CSSProperties
  cms: boolean
  css_overrides?: string
  // TODO: all sections must eventually pass both of these params. For now, we're keeping them optional (section_id, lucid_page_section_id)
  section_id?: string
  // TODO: We might want to consider converting this to page_section_id,
  //  once we have a better idea of how we're going to handle the page_section_id vs section_id
  lucid_page_section_id?: string,
  meta: SectionMeta,
  section_background?: {
    image?: string
    video?: string
    type?: BackgroundType
  }
}

const StyledSection = styled.div<ColorSchemeValues>`
  ${createColorSchemeCSS}
`

/**
 *
 * TODO: We have to update this to allow for full rendering without useState
 *  For SSG, we need to be able to render pages without any front end loading
 *  We need to come back here and clean every async call/load after page render
 *
 */
export default function Section({...props}: SectionProps) {
  const {
    children,
    className = '',
    name,
    lucid_page_section_id,
    css_overrides,
    color_scheme_id_override,
    cms,
    section_background
  } = props

  const [{schemes}] = useLucidContext(cms)

  const styleCoreTarget: StyleCoreTarget = {
    componentName: name as StyleCoreComponentName,
    identifier: lucid_page_section_id
  }

  const sectionClassName = [`${name}-section`, className, StyleCoreHelpers.selectors.getSelectorID(styleCoreTarget)].join(' ').trim()

  const styleCoreMap = useStyleCoreMap(cms)
  const styleCoreDispatcher = useStyleCoreDispatcher(cms)
  const meta = Object.assign({}, props.meta)

  if (typeof meta?.style === 'undefined') {
    meta.style = {
      config: {},
      css: '',
      selectors: ''
    }
  }
  meta.style.config = useStyleCoreReceiver(styleCoreTarget, meta?.style.config, cms)

  const prerenderedStyles = useStyleCorePreRendered()
  const [pageSectionBackground, setPageSectionBackground] = useState<string>(section_background?.image ?? '')
  const [backgroundType, setBackgroundType] = useState<BackgroundType>(section_background?.type ?? 'image')

  const [currentScheme, changeColorScheme] = useColorScheme(schemes, color_scheme_id_override, cms)
  const {display, overridesModalIsOpened, onActive, onBlur, setOverrideModalIsOpened} = useOverridesWindowState(cms, lucid_page_section_id)

  useEffect(() => {
    if (typeof lucid_page_section_id !== 'undefined' && cms && !styleCoreMap.get(styleCoreTarget) && styleCoreMap.getRoot(styleCoreTarget)) {
      styleCoreDispatcher({
        target: styleCoreTarget,
        overloads: [{}]
      })
    }
  }, [])

  useMemo(() => {
    function setInitialValues() {
      if (styleCoreMap.get(styleCoreTarget)) {
        const initialValues = styleCoreMap.getSectionsInitialValues(styleCoreTarget)
        styleCoreDispatcher({
          target: styleCoreTarget,
          overloads: [initialValues as StyleCoreOverload]
        })
      }
    }

    if (typeof lucid_page_section_id !== 'undefined' && styleCoreMap.get(styleCoreTarget) && cms) {
      setInitialValues()
    }
  }, [styleCoreTarget.identifier, styleCoreTarget.componentName])

  useEffect(() => {
    if (backgroundType === 'video') setPageSectionBackground(section_background?.video ?? '')
    if (backgroundType === 'image') setPageSectionBackground(section_background?.image ?? '')
  }, [section_background?.video, section_background?.image, backgroundType])

  const cn = `${name}-${Math.random().toString(36).slice(2)}`
  const prefixCss = preprocessCss(css_overrides, cn)

  return (
    <>
      <StyledSection
        onMouseEnter={onActive}
        onMouseLeave={onBlur}
        className={sectionClassName + ` ${cn}`}
        {...currentScheme as ColorSchemeValues}
      >
        <DynamicSectionBackground
          meta={meta}
          className={cn + ' section-image-background'}
          backgroundType={backgroundType}
          pageSectionBackground={pageSectionBackground}
          cms={cms}
        />
        <CMSHiding cms={cms}>
          <CssOverridesContainer
            display={display || overridesModalIsOpened}
            fields={[
              {
                label: '',
                name: 'overrides_container_awareness',
                component: () => <OverridesContainerAwareness setIsMounted={setOverrideModalIsOpened}/>
              },
              {
                label: 'Color Scheme Override',
                name: 'color_scheme_id_override',
                component: (props) => <SwitchColorScheme
                  {...props}
                  onChange={changeColorScheme} cms={cms}
                  color_scheme_id_override={color_scheme_id_override}
                  activeColorScheme={currentScheme}/>
              },
              {
                label: 'Background',
                name: 'section_background',
                component: 'group',
                fields: [
                  {
                    label: 'Background Type',
                    name: 'type',
                    component: 'select',
                    options: [
                      {label: 'Image', value: 'image'},
                      {label: 'Video', value: 'video'}
                    ],
                    parse: (value: BackgroundType) => {
                      setBackgroundType(value)
                      return value
                    }
                  },
                  {
                    label: 'Section Background Image',
                    name: 'image',
                    component: 'image',
                    clearable: true,
                    parse: (media) => {
                      if (!media) return
                      const assetUrl = constructAssetUrl(media.id)
                      setPageSectionBackground(assetUrl)
                      return assetUrl
                    },
                    previewSrc: (src: string) => src
                  },
                  {
                    name: 'image',
                    key: 'src.external',
                    label: 'External Image',
                    component: 'text',
                    description: 'Or enter an external Image URL',
                  },
                  {
                    label: 'Section Background Video',
                    name: 'video',
                    component: 'text',
                    parse: (url: string) => {
                      setPageSectionBackground(url)
                      return url
                    }
                  }
                ]
              },
              {
                label: 'Styles',
                name: 'styles',
                component: (props) => <StyleCoreFormInnerSettings
                  {...props}
                  cms={cms}
                  styleCoreElement={styleCoreMap.get(styleCoreTarget)}
                  formManagerTarget={{
                    page_section_id: lucid_page_section_id
                  }}/>
              },
              {
                label: 'CSS Overrides',
                name: 'css_overrides',
                component: (props) => <StylesModalContent
                  target={styleCoreTarget}
                  {...props} colorSchemeOverride={color_scheme_id_override}
                  activeColorScheme={currentScheme}/>,
              }
            ]}
          >
            <span>
            <div style={{all: 'unset'}}>
              <Badge style={{
                position: 'absolute',
                zIndex: 99,
                top: 14,
                left: -10,
                transform: 'translate(25%, -70%)',
                pointerEvents: 'none'
              }} size="md" enableShadow disableOutline color="primary">
              <ColorSwatch/>
            </Badge>
            </div>
          </span>
          </CssOverridesContainer>
        </CMSHiding>
        {children}
        <StyleManager
          cms={cms}
          meta={meta}
          styleCoreTarget={styleCoreTarget}
          prefixCss={prefixCss}
          prerenderedStyles={prerenderedStyles}
        />
      </StyledSection>
    </>
  )
}

function prependClassNameToCSS(cn: string, cms: boolean, css_overrides?: string): string {
  if (!css_overrides) {
    return ''
  }
  const lines = css_overrides.split('\n')
  const updatedLines: string[] = []
  let insideAtRule = false
  let atRuleContent = ''

  for (const line of lines) {
    const trimmedLine = line.trim()

    if (trimmedLine.startsWith('@')) {
      // Handle @ rules (e.g., @media, @keyframes)
      updatedLines.push(trimmedLine)
      insideAtRule = true
      atRuleContent = ''
    } else if (insideAtRule) {
      // Inside an @ rule, accumulate the content
      atRuleContent += line + '\n'

      if (trimmedLine.endsWith('}')) {
        // End of the @ rule content
        insideAtRule = false

        // Process the @ rule content and prepend the class name
        const updatedAtRuleContent = prependClassNameToCSS(cn, cms, atRuleContent)
        updatedLines.push(updatedAtRuleContent)
      }
    } else if (trimmedLine) {
      // Handle regular CSS rules
      const parts = trimmedLine.split('{')
      if (parts.length === 2) {
        const selectors = parts[0].split(',')
        const rules = parts[1]
        const updatedSelectors = selectors.map(selector => {
          // Trim each selector and prepend the class name
          const trimmedSelector = selector.trim()
          const space = trimmedSelector.trimStart().startsWith(':') ? '' : ' '
          return `.${cn}${space}${trimmedSelector}`
        })
        updatedLines.push(`${updatedSelectors.join(', ')} {${rules}`)
      } else {
        if (cms) {
          console.warn(`Invalid CSS rule at ${line}. Skipping...`)
        }
        // Invalid CSS rule, just push it as is
        updatedLines.push(line)
      }
    }
  }

  return updatedLines.join('\n')
}
