import SectionImage from '../shared/SectionImage'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

// TODO: Use actual `cms`

const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  menuphoto: {
    ...baseItem,
    label: 'Menu Photo Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/420/420',
        alt: '',
      },
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Pick Image from the Assets Manager',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              return constructAssetUrl(media.id)
            },
            previewSrc: (src) => {
              return src
            },
          },
          {
            name: 'src',
            key: 'src.external',
            label: 'External Image',
            component: 'text',
            description: 'Or enter an external Image URL',
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

export const MENU_PHOTO_ITEM_BLOCKS = {
  menuphoto: {
    Component({_name, index, data}) {
      return (
        <>
          <div className="menu column">
            <ItemWrapper index={index}>
              <div className="menu-photo-item">
                <div className="photo-box">
                  <div>
                    {data?.image?.src ? (
                      <SectionImage
                        name="data.image.src"
                        src={data?.image?.src}
                        alt={data?.image?.alt}
                        width={data?.image?.width}
                        height={data?.image?.height}
                        priority={data.image?.priority}
                        sizes={data.image?.sizes}
                        cms={data?.cms}
                      />
                    ) : null}
                  </div>
                </div>
                <h3 style={{textAlign: 'center'}}>
                  <span className="menu-item-title menu-photo-item-title heading-3">
                    <SectionText name="data.title" text={data.title} cms={data.cms}/>
                  </span>
                  <br/>
                  <span className="menu-item-subtitle menu-photo-item-subtitle heading-4">
                    <SectionText name="data.subtitle" text={data.subtitle} cms={data.cms}/>
                  </span>
                </h3>
                <p>
                  <RichtextWithVariables
                    name="data.description"
                    richtext={data.description}
                    cms={data.cms}
                  />
                </p>

              </div>
            </ItemWrapper>
          </div>
          <div className="menu-item-break"></div>
        </>
      )
    },
    template: itemTemplates.menuphoto,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      cms={props.cms}
      index={props.index}
      focusRing={{offset: -9, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </LucidBlocksControls>
  )
}

