import {
  FieldConfig,
  FieldConfigForStyleCoreOptionals,
  FieldConfigForStyleCoreOptionalsValidatorReturn, FieldOption,
  FormConfig,
  FormState,
  ListenerTargetInner
} from '@/components/shared/externalTypes'
import {Badge, Button, Card, Collapse, Dropdown, Grid, Input, Loading, Switch, Text} from '@nextui-org/react'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ColorResult, SketchPicker} from 'react-color'
import {
  mergeOverloads,
  StyleCoreElement,
  StyleCoreHelpers as StyleCoreHelpers, StyleCoreOverload,
  useStyleCoreDispatcher, useStyleCoreReceiver
} from '@/components/shared/StyleCore'
import Helpers from '@/src/utils/shared/helpers'
import type {Field as TinaField, FormOptions} from '@einsteinindustries/tinacms'
import * as Icons from 'iconsax-react'
import {ColorSwatch, Edit2, Flash, Global, Lock} from 'iconsax-react'
import {LucidSiteContext} from '@/src/state/site/Store'
import {ColorSchemeResponse} from '@/components/managers'
import {PresetColor} from 'react-color/lib/components/sketch/Sketch'
import styled from 'styled-components'
import {StyleCoreContext} from '@/src/state/site/StyleCoreStore'
import {TypographyGroupMap} from '@/components/editor/typography/types'
import {TypographyFormElementListElement, TypographyModal} from '@/components/editor/typography/TypographyModal'
import {extractFormManagerValues, initialValuesFromContent} from '@/src/utils/formManagerHelper'
import {BlockContents} from '@/components/shared/types'
import {useFormDirty} from '@/components/shared/useFormDirty'

type NextUISelectOptionKV = {
  value: string,
  label: string
}

export type NextUIFieldProps = TinaField & {
  options?: NextUISelectOptionKV[] | string[]
} & FieldConfigForStyleCoreOptionals

type NextUIFormFieldProps = {
  field: NextUIFieldProps,
  onChange: (value: any) => void,
  initialValue: any,
  options?: TargetedNextUIFieldProps | NextUIFormOptions,
  wrapWithGrid?: boolean,
  enableInheritanceVisuals?: boolean,
  currentExternalState?: FormState,
  currentExternalStateLevel?: FormState
}

type NextUISelectOptionsFormatted = {
  key: string,
  name: string,
}

type TinaCustomFormType = FormOptions<any> & {
  onSubmit: (values: any) => void,
  onChange: (values: any) => void,
}

type TargetedNextUIFieldProps =
  NextUINumberFieldProps
  | NextUITextFieldProps
  | NextUIToggleFieldProps
  | NextUISelectFieldProps
  | NextUIColorFieldProps
  | NextUIGroupFieldProps
  | NextUIGroupFormProps

type NextUINumberFieldProps = {
  component: 'number',
}

type NextUITextFieldProps = {
  component: 'text',
  placeholder?: string
}

type NextUIToggleFieldProps = {
  component: 'toggle',
}

type NextUISelectFieldProps = {
  component: 'select',
}

type NextUIColorFieldProps = {
  component: 'color',
}

type NextUIGroupFieldProps = {
  component: 'group',
  startUnfolded?: boolean,
  startUnfoldedParentGroupOnly?: boolean,
}

type NextUIGroupFormProps = {
  component: 'form',
  showTitle?: boolean,
  description?: string,
  buttons?: {
    submit?: string,
  }
}

type NextUIFormOptions = {
  targeted?: TargetedNextUIFieldProps[],
  untargeted?: {
    [key: string]: any
  }
}

type NextUIFormGroupStateChange = {
  [key: string]: string | NextUIFormGroupStateChange
}

export type NextUITextFieldHelperPropsState = 'default' | 'error' | 'success' | 'warning'

export type NextUITextFieldHelperProps = {
  message: string,
  state: NextUITextFieldHelperPropsState,
  block?: boolean
}

type NextUIFieldOverrideButtonProps = {
  removeOverrides: () => void,
  overriden: boolean
}

type NextUIFieldWrapperProps = {
  overridden: boolean,
  incomingProps: NextUIFormFieldProps,
  currentFieldState: string | null,
  options?: NextUIFormOptions,
  removeOverrides: () => void,
  field: NextUIFieldProps,
  currentExternalState?: FormState,
  children: React.ReactNode,
  incomingValue: string | number | boolean | null,
}

const TinaFormRescribe = {
  active: undefined,
  dirty: false,
  dirtyFields: {},
  dirtyFieldsSinceLastSubmit: {},
  dirtySinceLastSubmit: false,
  error: undefined,
  errors: undefined,
  hasSubmitErrors: false,
  hasValidationErrors: false,
  initialValues: [],
  invalid: false,
  modifiedSinceLastSubmit: false,
  pristine: false,
  submitError: undefined,
  submitErrors: undefined,
  submitFailed: false,
  submitSucceeded: false,
  submitting: false,
  valid: false,
  validating: false
}

const STEPPER_FACTOR_MULTIPLIER = 3

const STANDARD_FIELD_WIDTH = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 3
}

const FieldMap = [
  {
    supportedComponentTypes: ['text', 'number', 'range'],
    component: TextField
  },
  {
    supportedComponentTypes: ['color'],
    component: ColorField
  },
  {
    supportedComponentTypes: ['toggle'],
    component: ToggleField
  },
  {
    supportedComponentTypes: ['select'],
    component: SelectField
  },
  {
    supportedComponentTypes: ['typography'],
    component: TypographySelectionField,
    width: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12
    }
  }
]

const overrideButtonStates: {
  [key: string]: {
    scale: number,
    text: string,
    flat: boolean,
    disabled: boolean,
    color: 'error' | 'primary' | 'success' | 'secondary' | 'warning',
    icon: React.ReactElement
  }
} = {
  hover: {
    scale: 1.09,
    text: 'Clear and Inherit',
    color: 'secondary',
    flat: true,
    disabled: false,
    icon: <Icons.ArrowDown size={16}/>
  },
  default: {
    scale: 0.97,
    text: 'Inherited',
    color: 'primary',
    flat: true,
    disabled: true,
    icon: <Icons.ArrowDown size={16}/>
  },
  overriden: {
    scale: 1.03,
    text: 'Overridden',
    color: 'secondary',
    flat: false,
    disabled: false,
    icon: <Icons.ArrowUp size={16}/>
  }
}

type GroupParentCollapseWrapperProps = {
  startUnfoldedParentGroupOnly: boolean | undefined,
  startUnfolded: boolean | undefined,
  title: string
  children: React.ReactNode
}

function GroupParentCollapseWrapper({startUnfoldedParentGroupOnly, startUnfolded, title, children}: GroupParentCollapseWrapperProps) {
  return <Collapse.Group>
    <Collapse
      style={{
        background: 'rgba(245,245,245,0.52)',
        borderRadius: 20,
        paddingLeft: 20,
        paddingRight: 20,
      }}
      expanded={startUnfoldedParentGroupOnly ?? startUnfolded ?? false}
      title={title}
    >
      {children}
    </Collapse>
  </Collapse.Group>
}

function InlineValueDisplay({value, field, flat}: { value: string, field: NextUIFieldProps, flat: boolean }) {
  const preamble = 'Rendered Value: '
  const color = flat ? 'default' : 'secondary'
  const variant = flat ? 'flat' : 'default'
  switch (field.component) {
    case 'color':
      if (!value.startsWith('#')) {
        return <Badge variant={variant} color={color}>{preamble}Color Scheme</Badge>
      } else {
        return <Badge variant={variant} color={color} style={{backgroundColor: value, color: getColorByBgColor(value)}}>{preamble}{value}</Badge>
      }
    case 'select':
      const foundOptionSet = (field.options as FieldOption[]).find((option: FieldOption) => option.value === value)?.label
      const displayValue = foundOptionSet ? `${value} (${foundOptionSet})` : value
      return <Badge variant={variant} color={color}>{preamble}{displayValue}</Badge>
    case 'toggle':
      return <Badge variant={variant} color={value === 'true' ? 'success' : 'error'}>{preamble}{value === 'true' ? 'Enabled' : 'Disabled'}</Badge>
    default:
      return <Badge variant={variant} color={color}>{preamble}{value}</Badge>
  }
}

function FieldMessage({message, state}: NextUITextFieldHelperProps) {
  return <Card variant="flat" style={{width: '100%', marginTop: 10}} color={state}>
    <Card.Body>
      <Text color={state} b>{message}</Text>
    </Card.Body>
  </Card>
}

function StepperModule(
  {onChange, value, step, min, max, unit, isValueUnset, disabled}: {
    onChange: (value: number | undefined) => void,
    value: string | number,
    step: number,
    min?: number,
    max?: number,
    unit?: string,
    isValueUnset: boolean,
    disabled?: boolean
  }) {

  const stepperEnableUnits = unit && !isValueUnset
  const minBoundaryEnabled = typeof min !== 'undefined' && !isValueUnset && !isNaN(Number(value))
  const maxBoundaryEnabled = typeof max !== 'undefined' && !isValueUnset && !isNaN(Number(value))

  const stepperButtons = {
    fastDown: {
      disabled: minBoundaryEnabled && (Number(value) - step * STEPPER_FACTOR_MULTIPLIER) < min,
      clickHandler: () => {
        const valueSet = (isValueUnset ? min : Number(value) - step * STEPPER_FACTOR_MULTIPLIER)
        onChange(valueSet)
      }
    },
    down: {
      disabled: minBoundaryEnabled && (Number(value) - step) < min,
      clickHandler: () => {
        const valueSet = (isValueUnset ? min : Number(value) - step)
        onChange(valueSet)
      }
    },
    up: {
      disabled: maxBoundaryEnabled && (Number(value) + step) > max,
      clickHandler: () => {
        const valueSet = (isValueUnset ? step : Number(value) + step)
        onChange(valueSet)
      }
    },
    fastUp: {
      disabled: maxBoundaryEnabled && (Number(value) + step * STEPPER_FACTOR_MULTIPLIER) > max,
      clickHandler: () => {
        const valueSet = (isValueUnset ? step : Number(value) + step * STEPPER_FACTOR_MULTIPLIER)
        onChange(valueSet)
      }
    },
  }

  return <>
    <Text h1 color={isValueUnset ? '$gray500' : 'secondary'}>
      {isValueUnset ? 'Not Set' : value}
      {stepperEnableUnits &&
          <Text b color={'$gray500'} size={'md'}>{unit}</Text>
      }
    </Text>
    <Button.Group size={'md'} color={'secondary'} style={{width: '100%', padding: 0}}>
      <Button flat disabled={disabled || stepperButtons.fastDown.disabled} onPress={stepperButtons.fastDown.clickHandler} style={{width: '20%'}}><Icons.ArrowLeft2/></Button>
      <Button disabled={disabled || stepperButtons.down.disabled} onPress={stepperButtons.down.clickHandler} style={{width: '30%'}}><Icons.ArrowLeft/></Button>
      <Button disabled={disabled || stepperButtons.up.disabled} onPress={stepperButtons.up.clickHandler} style={{width: '30%'}}><Icons.ArrowRight/></Button>
      <Button flat disabled={disabled || stepperButtons.fastUp.disabled} onPress={stepperButtons.fastUp.clickHandler} style={{width: '20%'}}><Icons.ArrowRight2/></Button>
    </Button.Group>
  </>
}

function TextField({field, onChange, initialValue, options}: NextUIFormFieldProps) {
  const [value, setValue] = useState(initialValue ?? '')
  const [helper, setHelper] = useState<NextUITextFieldHelperProps>(
    {
      message: '',
      state: 'default'
    }
  )

  // Standard onChange callback to pass values up
  useEffect(() => {
    onChange(`${value}`)
  }, [value])

  // OPTIMIZATION: Only update value if initialValue changes
  useMemo(() => {
    setValue(initialValue)
  }, [initialValue])

  function setValueWithMiddleware(value: string | number | undefined) {
    if (typeof value === 'undefined') {
      setValue('')
    } else {
      setValue(validateMiddleware(`${value}`))
    }
  }

  function validateMiddleware(valueToValidate: string) {
    const result = validateTextFieldMiddleware(valueToValidate, String(field.component), field?.validation, {min: field?.min, max: field?.max})
    setHelper(result)
    if (!result.block) {
      return valueToValidate
    } else {
      return value
    }
  }

  const fieldOptions = options as NextUITextFieldProps
  const isValueUnset = value === ''
  const stepperEnabled = typeof field?.step !== 'undefined' && field?.step !== 0 && field?.component === 'number'

  return <>
    <div style={{display: (stepperEnabled ? 'none' : 'block')}}>
      <Input
        width="100%"
        size={'lg'}
        disabled={field.locked ?? false}
        labelRight={field?.unit}
        status={helper?.state ?? 'default'}
        helperText={helper?.message}
        helperColor={helper?.state ?? 'default'}
        placeholder={fieldOptions?.placeholder}
        onChange={
          (e) => {
            setValueWithMiddleware(e.target.value)
          }
        }
        initialValue={initialValue}
      />
    </div>
    {(stepperEnabled) &&
        <StepperModule
            onChange={setValueWithMiddleware}
            value={value}
            step={field.step!}
            max={field.max}
            min={field.min}
            unit={field.unit}
            disabled={field.locked ?? false}
            isValueUnset={isValueUnset}
        />
    }
  </>
}

/**
 * ToggleField
 * A toggle field is a switch that can be toggled on or off
 *
 * Note: This component will always encode the value as a string
 * to allow the handlers to use only one type of value
 * @param field
 * @param initialValue
 * @param onChange
 * @param options
 * @constructor
 */
function ToggleField({field, initialValue, onChange, options}: NextUIFormFieldProps) {
  const [value, setValue] = useState( '')

  // Standard onChange callback to pass values up
  useEffect(() => {
    if (isValueSet) {
      onChange(`${value}`)
    }
  }, [value])

  // OPTIMIZATION: Only update value if initialValue changes
  useMemo(() => {
    if (initialValue === '') {
      setValue('')
    }
    setValue(initialValue)
  }, [initialValue])

  const isValueSet = typeof value !== 'undefined' && value !== '' && value !== 'undefined'
  const fieldOptions = options as NextUIToggleFieldProps
  return <Grid.Container gap={0.5}>
    <Grid>
      <Switch disabled={field.locked ?? false} checked={value === 'true'} name={field.label} onChange={(e) => {
        setValue(`${e.target.checked}`)
      }}/>
    </Grid>
  </Grid.Container>
}

function TypographySelectionPanel({onChange, groups, show, initialValue} : {onChange: (value: string) => void, groups: TypographyGroupMap, show: boolean, initialValue: string}) {
  const [value, setValue] = useState(initialValue ?? '')
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState<TypographyGroupMap>()
  const [searchResultsLoading, setSearchResultsLoading] = useState(false)

  const [fonts, setFonts] = useState<TypographyGroupMap>(groups)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    onChange(value)
    setFonts(groups)
  }, [value, groups])

  useEffect(() => {
    if (search === '') {
      setSearchResults(undefined)
      return
    }
    setSearchResultsLoading(true)
    const results = new Map()
    fonts.forEach((group, key) => {
      if (group.name.toLowerCase().includes(search.toLowerCase())) {
        results.set(key, group)
      }
    })
    setSearchResults(results)
    setSearchResultsLoading(false)
  }, [search])

  if (!show) return null

  return <Card
    style={{width: '100%', padding: '20px'}}
  >
    {searchResultsLoading ? <Loading/> : <></>}
    <Grid.Container gap={1}>
      <Grid xs={4}>
        <div style={{
          width: '100%',
        }}>
          <TypographyEditorModal show />
        </div>
      </Grid>
      <Grid xs={8}>
    <Input
      width="100%"
      size={'lg'}
      placeholder={'Search'}
      onChange={
        (e) => {
          setSearch(e.target.value)
        }
      }
    />
      </Grid>

    </Grid.Container>
    <Grid.Container gap={1}>
      {
        Array.from((searchResults ?? fonts).values()).map((group) => {
          return <Grid key={group.id}>
            <Card
              isPressable
              isHoverable
              style={{width: '100%', padding: '15px'}}
              onPress={(e) => {
                setValue(group.id)
              }}
            >
              <Text h3>{group.name}</Text>
              <Text small>{group.description}</Text>
              <div style={{
                transform: 'scale(0.9)',
              }}>
                <Grid.Container gap={0.5}>
                  {
                    group.elements.map((element, k) => {
                      return <Grid key={k}><TypographyFormElementListElement
                        disabled
                        element={element}
                        onSelect={(value) => {}}
                      /></Grid>
                    })
                  }
                </Grid.Container>
              </div>
            </Card>
          </Grid>
        })
      }
    </Grid.Container>

  </Card>
}

function TypographyEditorModal({show} : {show: boolean}) {
  const [isOpen, setIsOpen] = useState(false)
  if (!show) return null
  return <>
    <TypographyModal isOpen={isOpen} inModal={true} onClose={() => {setIsOpen(false)}} />
    <Button onPress={() => setIsOpen(true)} flat size={'lg'} style={{width: '100%'}}><Edit2/> &nbsp; Edit Groups</Button>
  </>
}

function TypographySelectionField({field, initialValue, onChange, options}: NextUIFormFieldProps) {
  const [selectedTypographyGroup, setSelectedTypographyGroup] = useState(initialValue ?? '')
  const [selectionPanelOpen, setSelectionPanelOpen] = useState(false)
  const [selectionUnavailable, setSelectionUnavailable] = useState(false)

  const [{StyleCore}] = useContext(StyleCoreContext)

  // Standard onChange callback to pass values up
  useEffect(() => {
    if (typeof selectedTypographyGroup !== 'undefined' && selectedTypographyGroup !== '') {
      onChange(selectedTypographyGroup)
    }
  }, [selectedTypographyGroup])

  // OPTIMIZATION: Only update selectedTypographyGroup if initialValue changes
  useEffect(() => {
      setSelectedTypographyGroup(initialValue)
  }, [initialValue])

  const fieldOptions = options as NextUISelectFieldProps
  const isValueUnset = selectedTypographyGroup === ''

  useEffect(() => {
    if (StyleCore.typography.map.has(selectedTypographyGroup) || isValueUnset) {
      setSelectionUnavailable(false)
    } else {
      setSelectionUnavailable(true)
    }
  }, [StyleCore?.typography])

  function toggleSelectionPanel() {
    setSelectionPanelOpen(!selectionPanelOpen)
  }

  function setSelection(e: string) {
    if (e !== selectedTypographyGroup) {
      setSelectedTypographyGroup(e)
    }
    setSelectionPanelOpen(false)
  }

  const defaultTheme = Array.from(StyleCore?.typography?.map ?? [])?.find(([,e]) => e.isDefault)
  let defaultThemeName = ''
  if (typeof defaultTheme !== 'undefined') {
    defaultThemeName = ` (${defaultTheme[1].name})`
  }

  let buttonText = isValueUnset ? `Inherited${defaultThemeName}` : StyleCore?.typography?.map.get(selectedTypographyGroup)?.name ?? 'Unknown'
  if (selectionUnavailable) {
    buttonText = 'Selection Unavailable'
  }
  return <>
    <Button size={'md'} color={selectionUnavailable ? 'error' : 'secondary'} onPress={toggleSelectionPanel} style={{width: '100%', padding: 0}}>
      {buttonText}
    </Button>
    <TypographySelectionPanel
      show={selectionPanelOpen}
      initialValue={selectedTypographyGroup}
      groups={StyleCore?.typography?.map ?? new Map()}
      onChange={setSelection}
    />
  </>
}

function SelectField({field, initialValue, onChange, options}: NextUIFormFieldProps) {

  const [value, setValue] = useState(initialValue ?? '')

  // Standard onChange callback to pass values up
  useEffect(() => {
      onChange(value?.currentKey ?? value)
  }, [value])

  // OPTIMIZATION: Only update value if initialValue changes
  useMemo(() => {
    setValue(initialValue)
  }, [initialValue])

  function getLabel() {
    if (isValueUnset) return 'No Selection'
    if (typeof field?.options?.at(0) === 'string') {
      return value
    } else {
      const fieldOptions = field?.options as NextUISelectOptionKV[] | undefined
      // If the options are an array of key value pairs, then we need to find the label by the value
      // Fall back to the value if the label is not found or, if the value is unset, show 'No Selection'
      return fieldOptions?.find((e) => (e?.value === value?.currentKey || e?.value === value))?.label ?? (isValueUnset ? 'No Selection' : value)
    }
  }

  const fieldOptions = options as NextUISelectFieldProps
  const isValueUnset = value === ''

  return <Dropdown>
    <Dropdown.Button color={'secondary'} disabled={field.locked ?? false} size={'lg'} flat={isValueUnset}>
      {getLabel()}
    </Dropdown.Button>
    <Dropdown.Menu
      selectedKeys={value}
      selectionMode={'single'}
      variant={'solid'}
      onSelectionChange={(value) => {
        setValue(value)
      }}
      items={field.options?.map((e) => {
        if (typeof e === 'string') {
          return {
            name: e,
            value: e,
          }
        } else {
          return {
            name: e.label,
            value: e.value,
          }
        }
      })}
      aria-label="Value Types">
      {
        // Had to add TS ignore here because the type of the Dropdown.Item is not correct. It should be a K/V set not just an object
        // @ts-ignore
        (item: object) => (<Dropdown.Item key={item.value}>{item.name}</Dropdown.Item>)
      }
    </Dropdown.Menu>
  </Dropdown>
}

function ColorField({field, initialValue, onChange, options}: NextUIFormFieldProps) {

  const fieldOptions = options as NextUIColorFieldProps
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [color, setColor] = useState(initialValue)
  const [presetColors, setPresetColors] = useState<PresetColor[]>([])
  const [{schemes}] = useContext(LucidSiteContext)

  // Standard onChange callback to pass values up
  useEffect(() => {
      onChange(color)
  }, [color])

  // OPTIMIZATION: Only update value if initialValue changes
  useMemo(() => {
    setColor(initialValue)
  }, [initialValue])

  // Set the preset colors from the color schemes
  useEffect(() => {
    const presetColors = getColorSchemeColorOptions(schemes)
    setPresetColors(presetColors)
  },[schemes])

  function handleToggleColorPicker() {
    setIsColorPickerOpen(!isColorPickerOpen)
  }

  function handleColorPickerChange(color: ColorResult | null) {
    if (color !== null) {
      setColor(color.hex)
    }
  }

  const DivForColorPicker = styled.div`
    z-index: 9999;
  `

  const isValueUnset = color === ''

  return <Grid.Container justify={'center'}>
    <Grid xs={12}>
      <Button
        disabled={field.locked ?? false}
        onPress={handleToggleColorPicker}
        icon={<ColorSwatch variant={'Bulk'}/>}
        color={'secondary'}
        size={'lg'}
        style={{
          backgroundColor: (isValueUnset ? '#b6b6b6' : color),
          boxShadow: (isValueUnset ? '' : `0px 7px 12px -3px ${color}64`),
          transition: 'all 0.2s ease-in-out',
          color: getColorByBgColor(color),
          width: '100%',
          minWidth: '25px'
        }}>
        {isValueUnset ? 'Not Set' : color}
      </Button>
    </Grid>
    {isColorPickerOpen &&
      <DivForColorPicker>
        <SketchPicker
          presetColors={presetColors}
          color={color ?? '#000000'}
          onChange={handleColorPickerChange}
        />
      </DivForColorPicker>}
  </Grid.Container>
}

const LockedChip = () => {
  const style = {
    width: '100%',
    transform: 'scale(0.97)',
    paddingRight: '0.5rem',
    fontWeight: 600,
    marginBottom: '1rem',
  }
  return <Button size={'xs'} disabled style={style} rounded>
    <Lock size={16}/> &nbsp; Locked
  </Button>
}

const ExternallyModifiedChip = () => {
  const style = {
    width: '100%',
    transform: 'scale(0.97)',
    paddingRight: '0.5rem',
    fontWeight: 600,
    marginBottom: '1rem',
  }
  return <Button size={'xs'} style={style} color="warning" rounded>
    <Global size={16}/> &nbsp; Changed
  </Button>
}

const PostProcessingFunctionChip = ({active} : {active:boolean}) => {
  const style = {
    width: '100%',
    transform: 'scale(0.97)',
    paddingRight: '0.5rem',
    fontWeight: 600,
    marginBottom: '1rem',
  }
  return <Button size={'xs'} style={style} color="secondary" flat={!active} rounded>
    <Flash size={16} variant={active ? 'TwoTone' : 'Outline'}/> &nbsp; Post Processing
  </Button>
}

function OverrideButton({removeOverrides, overriden}: NextUIFieldOverrideButtonProps) {
  const [selectedState, setSelectedState] = useState(overrideButtonStates.default)

  useEffect(() => {
    setSelectedState(overriden ? overrideButtonStates.overriden : overrideButtonStates.default)
  }, [overriden])

  function handleMouseOver() {
    if (overriden) {
      setSelectedState(overrideButtonStates.hover)
    } else {
      setSelectedState(overrideButtonStates.default)
    }
  }

  function handleMouseOut() {
    if (overriden) {
      setSelectedState(overrideButtonStates.overriden)
    } else {
      setSelectedState(overrideButtonStates.default)
    }
  }

  function handleOnClick() {
    if (overriden) {
      removeOverrides()
    }
  }

  const OverrideDivContainer = styled.div`
    width: 100%;
  `

  return <OverrideDivContainer
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}>
    <Button size={'xs'}
            flat={selectedState.flat}
            disabled={selectedState.disabled}
            style={{
              width: '100%',
              transform: `scale(${selectedState.scale})`,
              transition: 'transform 0.2s ease-in-out',
              paddingRight: '0.5rem',
              fontWeight: 600,
              marginBottom: '1rem',
            }}
            onPress={handleOnClick}
            rounded
            color={selectedState.color}>
      {selectedState.icon} {selectedState.text}
    </Button>
  </OverrideDivContainer>
}


/**
 * Get the background color feature for the field background.
 * Allows easier discerment between inherited and overridden fields
 * as well as adding
 * @param overridden Whether the field is overridden
 * @param props The props of the field
 * @param currentFieldState The current form state
 */
function getBackgroundFeature(overridden: boolean, props: NextUIFormFieldProps, currentFieldState: string | null) {
  const baseStyle = {
    marginTop: 10,
    padding: 25,
    transition: 'all 0.2s ease-in-out',
    outline: '0px solid rgba(23,201,100,0.3)'
  }

  if (overridden) {
    const isOverwritten = {
      ...baseStyle,
      outline: '3px solid rgba(151,80,221,0.4)',
      background: 'rgba(151,80,221,0.1)'
    }
    if (props.field?.component === 'color') {
      return {
        ...isOverwritten,
        background: `${currentFieldState}10`,
        outline: `3px solid ${currentFieldState}20`
      }
    }
    if (props.field?.component === 'toggle') {
      return {
        ...isOverwritten,
        background: currentFieldState === 'true' ? 'rgba(23,201,100,0.2)' : 'rgba(151,80,221,0.1)',
      }
    }
    return isOverwritten
  }
  return {
    ...baseStyle
  }
}

function processIncomingValue(
    {currentFieldState, currentExternalState, field, incomingValue} : NextUIFieldWrapperProps
): [string, boolean] {
  let incomingValueProcessed = String(incomingValue)
  let processingAffected  = false
  if (currentFieldState !== '') {
    incomingValueProcessed = `${currentFieldState}${field.unit ?? ''}`
  }
  if (typeof field.postProcessing === 'function' && typeof currentExternalState !== 'undefined') {
    const processed = field.postProcessing(currentExternalState, String(incomingValueProcessed))
    if (processed !== incomingValueProcessed) {
      incomingValueProcessed = processed
      processingAffected = true
    }
  }
  return [incomingValueProcessed, processingAffected]
}

/**
 * The wrapper for the field that handles the surrounding card and the override button if applicable
 * @param props The props of the field, which includes the field itself
 * @constructor
 */

function FieldWrapper(props: NextUIFieldWrapperProps) {
  const {overridden, field, removeOverrides, options, incomingProps, currentFieldState, children, incomingValue, currentExternalState} = props
  const [incomingValueProcessed, processingAffected] = processIncomingValue(props)
  return <Card variant={'bordered'} style={{
    ...getBackgroundFeature(overridden, incomingProps, currentFieldState)
  }}>
    <Grid.Container>
      <Grid>
        {options?.untargeted?.handleInheritance &&
            <OverrideButton removeOverrides={removeOverrides} overriden={overridden}/>
        }
      </Grid>
        {
          field.locked && <Grid><LockedChip/></Grid>
        }
        {
          typeof field.fieldValue !== 'undefined' && <Grid><ExternallyModifiedChip/></Grid>
        }
      {
          typeof field.postProcessing !== 'undefined' && <Grid><PostProcessingFunctionChip active={processingAffected}/></Grid>
      }
    </Grid.Container>
    <Text b h4 style={{paddingBottom: field?.description ? 15 : 0}}>
      {field?.nameOverride ?? field.label}
      {field?.description &&
          <Text size={'$sm'} color={'$gray800'}>{field?.description}</Text>
      }
    </Text>
    {children}
    {
      field?.message && <FieldMessage {...field.message}/>
    }
    {
      incomingValue &&
        <div style={{
            width: '100%',
            textAlign: 'center',
            paddingTop: 15
          }}
        >
          <InlineValueDisplay value={String(incomingValueProcessed)} flat={!overridden} field={field}/>
        </div>
    }
  </Card>
}

function FieldNotFound(props: { field: NextUIFieldProps }) {
  return <Card variant={'bordered'} style={{
    marginTop: 10,
    padding: 25
  }}>
    <Text color={'error'}>
      <Grid.Container gap={0.2} alignItems={'center'}>
        <Grid xs={12} style={{
          textAlign: 'center'
        }}>
          <Icons.Warning2 variant={'Bulk'} size={'32px'}/>
        </Grid>
        <Grid xs={12}>
          <Text b color={'error'} style={{
            textAlign: 'center'
          }}>
            Unsupported Field Type
          </Text>
        </Grid>
        <Grid xs={12}>
          <Text size={'$sm'} color={'$gray600'}>
            The component <code>{props.field.component}</code> does not exist yet
            for FormManager or is not yet enabled
            for this form renderer.
          </Text>
        </Grid>
      </Grid.Container>
    </Text>
  </Card>
}

export function Field(props: NextUIFormFieldProps) {
  const [currentFieldState, setCurrentFieldState] = useState(props.initialValue === null || props.initialValue === 'null' ? '' : props.initialValue)
  const [field, setField] = useState(props.field)

  useEffect(() => {
    props.onChange((currentFieldState === '' || currentFieldState === null) ? '' : `${handleUnitUpstream(currentFieldState, props.field?.unit)}`)
    handleFieldListener()
  }, [currentFieldState])

  useEffect(handleFieldListener, [currentFieldState, props.currentExternalState])

  useMemo(() => {
    setCurrentFieldState(props.initialValue === null || props.initialValue === 'null' || typeof props.initialValue === 'undefined' ? '' : props.initialValue)
  }, [props.initialValue])

  function handleFieldListener() {
    try {
      if (typeof props.field.listener !== 'undefined' && typeof props.currentExternalState !== 'undefined') {
        const listenerResult = props.field.listener(props.currentExternalState as FormState, currentFieldState, props.field)
        if (typeof listenerResult !== 'undefined') {
          setField(Object.assign({}, props.field, listenerResult))
          if (typeof listenerResult.fieldValue !== 'undefined') {
            setCurrentFieldState(listenerResult.fieldValue)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  function handleUnitUpstream(value: string, unit?: string) {
    if (!unit) return value
    return `${value.replaceAll(unit, '')}${props.field?.unit ?? ''}`
  }

  if (field.hidden) return null

  const FoundField = FieldMap.find(fieldFind => fieldFind.supportedComponentTypes.includes(String(field.component)))
  let fieldRendered = <FieldNotFound field={field}/>
  if (FoundField) {
    const foundFieldOptions = (props?.options as NextUIFormOptions)?.targeted?.find((option) => option.component === props.field.component)
    const overridden = props.initialValue !== null && currentFieldState !== ''
    let fieldInitialValue = currentFieldState === '' ? currentFieldState : (props.initialValue === null ? '' : props.initialValue?.replaceAll(props.field?.unit ?? '', ''))
    if (field?.fieldValue) {
      fieldInitialValue = field.fieldValue
    }
    fieldRendered =
      <FieldWrapper
      overridden={(props?.enableInheritanceVisuals ?? true) && overridden}
      incomingProps={props}
      currentFieldState={currentFieldState}
      currentExternalState={props.currentExternalState}
      options={props?.options as NextUIFormOptions}
      removeOverrides={() => {
        setCurrentFieldState('')
      }}
      field={field}
      incomingValue={String(props.currentExternalStateLevel)}
      >
      <FoundField.component
        initialValue={fieldInitialValue}
        options={foundFieldOptions}
        field={field}
        onChange={(e) => {
          setCurrentFieldState(e)
        }}/>
    </FieldWrapper>
  }
  if (props?.wrapWithGrid ?? true) {
    fieldRendered = <Grid {...(FoundField?.width ?? STANDARD_FIELD_WIDTH)}>
      {fieldRendered}
    </Grid>
  }
  return fieldRendered
}

function Group(props: {
  fields: TinaField[],
  placeholder?: string,
  onChange: (value: any) => void,
  initialValues: { [key: string]: any },
  options?: NextUIFormOptions,
  createAsParent?: boolean,
  parentName?: string,
  currentExternalState?: FormState,
  currentExternalStateLevel: FormState
}) {
  const [currentFieldState, setCurrentFieldState] = useState(props.initialValues ?? {})
  const groupOptions = props.options?.targeted?.find((field) => {
    return field.component === 'group'
  }) as NextUIGroupFieldProps ?? {}
  useEffect(() => {
    props.onChange(currentFieldState)
  }, [currentFieldState])
  const structure = []

  function handleGroupChange(value: NextUIFormGroupStateChange, name: string) {
    setCurrentFieldState({
      ...currentFieldState,
      [String(name)]: value
    })
  }

  function handleFieldChange(value: string | null, name: string) {
    if (!((value === '') && (['', null].includes(props.initialValues[name])))) {
      setCurrentFieldState({
        ...currentFieldState,
        [String(name)]: (value === '' ? null : value)
      })
    }
  }

  // We build the structure of the group. If the field is a group itself, we call the group component again
  // If the field is a normal field, we call the field component
  for (const [key, config] of Object.entries(props.fields)) {
    const initialValue = props.initialValues[config.name]
    const currentExternalStateLevel = props.currentExternalStateLevel[config.name.slice(config.name.lastIndexOf('>') + 1)] ?? {}
    if (typeof config.fields !== 'undefined' && config.fields.length > 0) {
      structure.push(
        <Collapse
          shadow
          key={key}
          expanded={groupOptions?.startUnfolded ?? false}
          style={{
            width: '100%',
            marginTop: 10,
          }}
          title={config.label}>
          <Group
            fields={config.fields}
            options={props.options}
            onChange={(v) => handleGroupChange(v, config.name)}
            initialValues={initialValue ?? {}}
            currentExternalState={props.currentExternalState}
            currentExternalStateLevel={currentExternalStateLevel}
          />
        </Collapse>)
    } else {
      const fieldTyped = config as NextUIFieldProps
      // if (fieldTyped?.hidden) continue
      structure.push(
          <Field
            key={key}
            initialValue={initialValue === null ? '' : initialValue}
            options={props.options}
            field={fieldTyped}
            onChange={(v) => handleFieldChange(v, fieldTyped.name)}
            currentExternalState={props.currentExternalState}
            currentExternalStateLevel={currentExternalStateLevel}
          />
       )
    }
  }

  // We create the final group component and package the above generated elements into it
  const GroupFinal = <Collapse.Group>
    <Grid.Container gap={1}>
      {
        structure.map((item, index) => {
          return item
        })
      }
    </Grid.Container>
  </Collapse.Group>

  // If the group is a parent group, we wrap it in parent group wrapper
  if (props.createAsParent) {
    return <GroupParentCollapseWrapper
      startUnfoldedParentGroupOnly={groupOptions.startUnfoldedParentGroupOnly}
      startUnfolded={groupOptions.startUnfolded}
      title={props.parentName ?? 'Group'}
    >
      {GroupFinal}
    </GroupParentCollapseWrapper>
  } else {
    return GroupFinal
  }
}

function validateTextFieldMiddleware(value: string, componentType: string, customValidator?: (value: string) => FieldConfigForStyleCoreOptionalsValidatorReturn, boundaries?: {
  min?: number,
  max?: number
}): NextUITextFieldHelperProps {
  if (['number', 'range'].includes(componentType)) {
    if (isNaN(Number(value))) {
      return {
        message: 'This field should be a number',
        state: 'error',
        block: true
      }
    }
    // Now we do the same for the optional min and max
    if (typeof boundaries?.min !== 'undefined' && Number(value) < boundaries?.min) {
      return {
        message: `This field should be at least ${boundaries?.min}`,
        state: 'error',
        block: true
      }
    }
    if (typeof boundaries?.max !== 'undefined' && Number(value) > boundaries?.max) {
      return {
        message: `This field should be at most ${boundaries?.max}`,
        state: 'error',
        block: true
      }
    }
  }

  if (componentType === 'text') {
    if (typeof boundaries?.min !== 'undefined' && value.length < boundaries?.min) {
      return {
        message: `This field should be at least ${boundaries?.min} characters long`,
        state: 'error',
        block: true
      }
    }
    if (typeof boundaries?.max !== 'undefined' && value.length > boundaries?.max) {
      return {
        message: `This field should be at most ${boundaries?.max} characters long`,
        state: 'error',
        block: true
      }
    }
  }

  if (customValidator) {
    const result = customValidator(value)
    if (typeof result?.block !== 'undefined' && result?.block) {
      return {
        block: true,
        message: result?.message,
        state: result?.state,
      }
    } else {
      return {
        block: false,
        message: result?.message,
        state: result?.state,
      }
    }
  } else {
    return {
      message: '',
      state: 'default',
      block: false,
    }
  }
}

function getColorSchemeColorOptions(schemes: ColorSchemeResponse[]) {
  const presetColors: PresetColor[] = []
  const doSchemes = (scheme: ColorSchemeResponse, name: string = '') => {
    scheme.components.forEach((component) => {
      if (component.type === 'HEX') {
        presetColors.push({
          title: `${name} → ${component.label}`,
          color: component.component_content.hex?.toUpperCase() ?? '#000000'
        })
      }
    })
    if (typeof scheme?.children_color_schemes !== 'undefined') {
      for (const child of scheme.children_color_schemes) {
        doSchemes(child, `${name} → ${child.name}`)
      }
    }
  }
  for (const scheme of schemes) {
    doSchemes(scheme, scheme.name)
  }
  return presetColors
}

function getColorByBgColor(bgColor: string) {
  if (!bgColor) { return '' }
  return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff'
}

/**
 * This function should be used to merge the form state with the initial values.
 * It should combine the two objects, but if a field is present in both, the form state should be used.
 * If a field is not present in the form state, it should be taken from the initial values.
 * If a field is present in the form state, it should be taken from the form state.
 * If a field is null in the form state, it should be taken from the form state.
 * if a field is not present in the initial values, it should be taken from the form state.
 * It should do this recursively for all fields.
 * @param formState
 * @param initialValues
 * @param mergedObject
 */
function mergeFormStateWithInitialValuesRecursively(formState: { [key: string]: any }, initialValues: { [key: string]: any }, mergedObject: { [key: string]: any } = {}) {
  if (Object.entries(initialValues).length === 0) return formState
  for (const [key, value] of Object.entries(initialValues)) {
    if (typeof formState[key] !== 'undefined') {
      if (typeof formState[key] === 'object' && formState[key] !== null && formState[key] !== '' && formState[key]?.length !== 0) {
        mergedObject[key] = mergeFormStateWithInitialValuesRecursively(formState[key], value)
      } else {
        if (formState[key] === null || formState[key] === 'null' || formState[key] == '' || formState[key]?.length === 0 || typeof formState[key] === 'undefined' || formState[key] === 'undefined') {
          mergedObject[key] = null
        } else {
          mergedObject[key] = formState[key]
        }

      }
    } else {
      mergedObject[key] = value
    }
  }
  return mergedObject
}

function FormLoadingPlaceholder() {
  return <Card color={'$gray900'} variant="flat" style={{
    paddingTop: 50,
    paddingBottom: 50
  }}>
    <Grid.Container gap={2} justify={'center'} alignItems={'center'}>
      <Grid>
        <Loading/>
      </Grid>
      <Grid>
        <Text b style={{
          paddingBottom: 0
        }}>One moment...</Text>
        <Text size="$xs" color={'$gray800'}>Fetching form...</Text>
      </Grid>
    </Grid.Container>
  </Card>
}

export function FormManagerFormNextUI({initialFormData, formConfig, options}: {
  initialFormData: BlockContents,
  formConfig: FormConfig,
  options: NextUIFormOptions
}) {

  const [currentFormState, setCurrentFormState] = useState(formConfig.initialValues as { [key: string]: any } ?? {})
  const [initialValues, setInitialValues] = useState(formConfig.initialValues as { [key: string]: any } ?? {})
  const [{site, page_build_id, site_build_id}] = useContext(LucidSiteContext)
  const formManagerForm = Helpers.tina.forms.create(formConfig, initialFormData, false, {
    site_id: site?.id as string,
    page_build_id: page_build_id as string,
    site_build_id
  })

  const formOptions = options?.targeted?.find((option) => option.component === 'form') as NextUIGroupFormProps

  useEffect(() => {
    setCurrentFormState(formManagerForm.initialValues as { [key: string]: any } ?? {})
    setInitialValues(formManagerForm.initialValues as { [key: string]: any } ?? {})
  }, [options])

  useEffect(() => {
    if (typeof formManagerForm.onChange !== 'undefined') {
      formManagerForm.onChange({
        values: mergeFormStateWithInitialValuesRecursively(currentFormState, initialValues),
        ...TinaFormRescribe
      })
    }
  }, [currentFormState])

  const structure: JSX.Element[] = []
  const currentExternalState = mergeOverloads([options?.untargeted?.globalState ?? {}, extractFormManagerValues(currentFormState)].reverse())
  if (typeof formManagerForm.fields !== 'undefined') {
    for (const config of formManagerForm.fields) {
      if (typeof config.fields !== 'undefined' && config.fields.length > 0) {
        if (config.component === 'group') {
          // I just refactored this to use proper state setting.
          // This should be extracted out of here
          structure.push(
            <div key={config.label} style={{paddingTop: 20, paddingBottom: 20}}>
              <Group
                createAsParent
                parentName={config.label}
                options={options}
                onChange={e => {
                  setCurrentFormState((prevState) => ({...prevState, [config.name]: e}))
                }}
                currentExternalState={currentExternalState}
                currentExternalStateLevel={options?.untargeted?.globalState[config.name.slice(config.name.lastIndexOf('>') + 1)] ?? {}}
                initialValues={initialValues[config.name] ?? {}}
                fields={config.fields}
              />
            </div>
          )
        }
      }
    }
  }

  async function onSubmit() {
    const mergedFormState = mergeFormStateWithInitialValuesRecursively(currentFormState, initialValues)
    await formManagerForm.onSubmit(mergedFormState)
  }

  return <div>
    {(formOptions?.showTitle ?? true) && <Text h2>{formConfig.title}</Text>}
    {formOptions?.description && <Text>{formOptions.description}</Text>}
    {
      structure.length > 0 ? structure.map((item, index) => {
        return item
      }) : <FormLoadingPlaceholder/>
    }
    <Button style={{
      width: '100%'
    }
    } size={'lg'} disabled={structure.length < 0} onPress={onSubmit}>{formOptions?.buttons?.submit ?? 'Save'}</Button>
  </div>
}

export function StyleCoreForm({styleCoreElement, formManagerTarget, onSubmit, cms}: {
  styleCoreElement: StyleCoreElement,
  formManagerTarget: ListenerTargetInner,
  onSubmit?: (e: any) => void,
  cms: boolean
}) {
  const [{section_contents}] = useContext(StyleCoreContext)
  const targetId: string = styleCoreElement?.target?.identifier ?? ''
  const styleCoreDispatcher = useStyleCoreDispatcher(cms)
  const formDirty = useFormDirty()
  const currentStyles = useStyleCoreReceiver( styleCoreElement.target, {}, cms)
  const formConfig: FormConfig = {
    title: 'Style Core',
    content: styleCoreElement.config as FieldConfig,
    id: StyleCoreHelpers.formManager.convertTargetToFormID(styleCoreElement.target),
    listeners: {
      target: formManagerTarget,
      preventContentTableUpsert: false,
      onSubmit: (value) => {
        if (Object.keys(value).length > 0) {
          styleCoreDispatcher({
            target: styleCoreElement.target,
            overloads: [
              value
            ] as StyleCoreOverload[]
          })
        }
        formDirty()
        onSubmit?.(Object.assign({}, value))
      }
    }
  }

  const initialValues = initialValuesFromContent(formConfig, section_contents[targetId] ?? [])
  return <FormManagerFormNextUI initialFormData={initialValues} formConfig={formConfig} options={{
    targeted: [
      {
        component: 'text',
        placeholder: 'Inherited'
      },
      {
        component: 'group',
        startUnfolded: false,
        startUnfoldedParentGroupOnly: false
      },
      {
        component: 'form',
        showTitle: false,
        buttons: {
          submit: 'Update'
        }
      }
    ],
    untargeted: {
      handleInheritance: true,
      globalState: currentStyles
    }
  }}/>
}
