import {parseContentVariables} from '@/src/utils/shared/parseContentVariables'
import type {InlineTextProps} from '@einsteinindustries/react-tinacms-inline'
import React, {useEffect, useState} from 'react'
import {useLucidContext} from '@/src/state/ServerSideStore'

interface InlineTextWithVariablesProps extends InlineTextProps {
  value: string
  cms: boolean
}

export function InlineTextWithVariables({cms, value, ...props}: InlineTextWithVariablesProps) {
  const [{site_contents}] = useLucidContext(cms)
  const finalText = (
    <>{parseContentVariables(value, site_contents)}</>
  )

  const [editable, setEditability] = useState(false)
  const [inlineTextComponent, setInlineTextComponent] = useState<JSX.Element>(finalText)

  useEffect(() => {
    async function loadTina() {
      const {InlineText} = await import('@einsteinindustries/react-tinacms-inline')
      setInlineTextComponent(<InlineText {...props} />)
    }

    if (cms) {
      loadTina()
    }
  }, [])

  function makeEditable() {
    setEditability(true)
  }

  function makeUneditable() {
    setEditability(false)
  }

  const component = editable ? inlineTextComponent : finalText

  return cms ? (
    <span
      onBlur={makeUneditable}
      onMouseDown={makeEditable}
    >
      {component}
    </span>
  ) : component
}

interface RichtextWithVariablesProps {
  richtext: string
  cms: boolean
  name: string
}

export function RichtextWithVariables({cms, ...props}: RichtextWithVariablesProps) {
  const [{site_contents}] = useLucidContext(cms)

  const [displayableText, setDisplayableText] = useState(parseContentVariables(props.richtext, site_contents))
  const [richTextComponent, setRichTextComponent] = useState<JSX.Element | null>(null)

  useEffect(() => {
    async function loadTina() {
      const SectionRichtext = (await import('./SectionRichtext')).default
      setRichTextComponent(
        <SectionRichtext name={props.name} richtext={displayableText} />
      )
    }

    if (cms) {
      loadTina()
    }
  }, [displayableText])

  function makeEditable() {
    setDisplayableText(props.richtext)
  }

  function makeUneditable() {
    setDisplayableText(parseContentVariables(props.richtext, site_contents))
  }

  return cms ? (
    <span
      onBlur={makeUneditable}
      onMouseDown={makeEditable}
    >
      {richTextComponent}
    </span>
  ) : <span dangerouslySetInnerHTML={{__html: parseContentVariables(props.richtext, site_contents)}}/>
}
