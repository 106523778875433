import {Menu, MenuItem, Page, Route} from '@/src/components/shared/types'
import {PressEvent} from '@nextui-org/react'
import {CSSProperties} from 'react'

export const types = ['group', 'link', 'pages'] as const
export const itemCategoryAndColorMap = {
  live: 'success',
  syndicated: 'warning',
  draft: 'primary',
  group: 'secondary',
  modified: 'default',
  link: 'error',
} as const

export const specialPages = ['404', '500', 'site map', 'thank you']

export type Filters =
  | 'group'
  | 'link'
  | 'pages'
  | 'draft'
  | 'modified'
  | 'live'
  | 'syndicated'

export const filterArr: Filters[] = [
  'group',
  'link',
  'pages',
  'draft',
  'modified',
  'live',
  'syndicated',
]

export const MENU_EDITOR_GROUP_NAME = 'menu-editor'

type NextUIEventPressCallback = (event: PressEvent) => void

export interface MenuEditorProps extends MenuCommonProps {
  hasChanged: boolean
  selectedMenu: Menu | null
  siteBuildId: string
  templateMenuItems: MenuItem[]
  loading: boolean
  menus: Menu[]
  isLocked: boolean
  onChangeSelectedMenu: (keys: any) => any
}

export interface MenuEditorHeaderProps {
  hasChanged: boolean
  selectedMenu: string | null
  menu_options: string[]
  onMenuChange: onMenuChangeAction,
  onChangeSelectedMenu: (keys: any) => any
}

type MenuCommonProps = {
  onMenuChange: onMenuChangeAction
  pages: Page[]
}

export type MenuFilterProps = {
  isLocked: boolean
  menus: Menu[]
  sortable_items: SortableListItem[]
  onMenuChange: onMenuChangeAction
  parentInfo: ParentItemIdInfo
}

export type onMenuChangeActionProps = {type: 'add' | 'addGroup' | 'addLink' | 'delete' | 'update' | 'deleteMenu', payload: any}
export type onMenuChangeAction = (changes: onMenuChangeActionProps) => void

export type SortableListItem = {
  id: string | null
  is_page: boolean
  menu_id: string | null
  site_build_id?: string
  is_new: boolean
  label: string
  category: keyof typeof itemCategoryAndColorMap
  sort_order: number
  original_sort_order: number
  route?: Route
  updated_at: string
  parent_menu_item_id?: string | null
  uses?: number
  group_menu_items?: SortableListItem[]
}

export type SortableListProps = {
  isLocked?: boolean
  group_name: string
  sortable_items: SortableListItem[]
  style?: CSSProperties
  parentInfo: ParentItemIdInfo
  level?: number
  behaviour?: 'move' | 'copy' | 'drop-zone' | 'contain'
  acceptDrops: boolean
  onMenuChange: onMenuChangeAction
}

export type ParentItemIdInfo = {menu_id?: string, parent_menu_item_id?: string}

export type ToolbarProps = {
  onMenuChange: onMenuChangeAction
}

export type DnDPayload = {
  newItems: MenuItem[]
  originalItems: SortableListItem[]
}
