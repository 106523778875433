import {Plugin, PluginKey, Transaction} from 'prosemirror-state'
import {SourceMenuView} from './SourceMenuView'
import ReactDOM from 'react-dom'

const sourcePluginKey = new PluginKey('sourceMode')

const sourcePlugin = new Plugin({
  key: sourcePluginKey,
  state: {
    init: () => false,
    apply: (tr: Transaction, value: boolean) => {
      if (tr.getMeta(sourcePluginKey)) {
        return !value
      }
      return value
    },
  },
  view: (editorView) => {
    const update = () => {
      const state = sourcePluginKey.getState(editorView.state)
    }

    const onClick = () => {
      const tr = editorView.state.tr
      tr.setMeta(sourcePluginKey, true)
      editorView.dispatch(tr)
    }
    const menuViewElement = document.createElement('div')
    ReactDOM.render(<SourceMenuView onClick={onClick} />, menuViewElement)

    editorView.dom.parentNode?.insertBefore(menuViewElement, editorView.dom)

    return {
      update,
      destroy: () => {
        ReactDOM.unmountComponentAtNode(menuViewElement)
        menuViewElement.remove()
      }
    }
  }
})

export default sourcePlugin
