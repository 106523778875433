import {PageBuild, User} from '../../../components/shared/types'
import {
  DialogReducerAction,
  getAction,
} from '../../../components/managers/PageManager/dialogReducer'
import {Dispatch} from 'react'
import {PROJECTS} from '../../../components/managers/PageManager/constants'
import {TinaCMS} from '@einsteinindustries/tinacms'
import LucidAlert from '../../shared/LucidAlert'

/**
 * Dialog open/close functions
 */
export const openEditDialog = (
  page: PageBuild,
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('EditDialog', 'Show', page))
}

export const closeEditDialog = (
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('EditDialog', 'Hide'))
}

export const openPublishWizard = (
  pages: PageBuild[],
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('PublishDialog', 'Show', pages))
}

export const closePublishWizard = (
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('PublishDialog', 'Hide'))
}

export const closePublishProjectWizard = (
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('PublishProjectDialog', 'Hide'))
}

export const handleGroupActionModal = (
  action: string,
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('AssignUserGroupDialog', 'Show', action))
}

export const closeAssignUserGroupDialog = (
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('AssignUserGroupDialog', 'Hide'))
}

export const openRejectDialog = (
  payload: [PageBuild[], User],
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('RejectDialog', 'Show', payload))
}

export const closeRejectDialog = (
  dispatchAction: Dispatch<DialogReducerAction>
) => {
  dispatchAction(getAction('RejectDialog', 'Hide'))
}

export const handleCreateProject = (
  dispatchAction: Dispatch<DialogReducerAction>,
  projects: string[] = PROJECTS,
  cms: TinaCMS
) => {
  const lucidAlert = new LucidAlert(cms)
  if (projects.length <= 2) {
    dispatchAction(getAction('ProjectDialog', 'Show'))
  } else {
    lucidAlert.error('There is already an open project, please delete or publish your current project to be able to create a new one')
  }
}

export const openActionsDialog = (
  action: string,
  dispatchAction: Dispatch<DialogReducerAction>
) => dispatchAction(getAction('ActionsGroupDialog', 'Show', action))
