import {useEffect, useState} from 'react'
import {BaseTextField, Select} from '@einsteinindustries/tinacms-fields'
import {InputLabel} from './styles'

function RedirectRouteForm(props) {
  const {routes, handleFormChange, setAllowNext} = props
  const [redirect, setRedirect] = useState({fromUrl: null, targetUrl: null})

  function checkFormValues(e) {
    handleFormChange(e)
    setRedirect(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  useEffect(() => {
    if (redirect.targetUrl && redirect.fromUrl) {
      setAllowNext(true)
    } else {
      setAllowNext(false)
    }
    return () => {
      setAllowNext(true)
    }
  }, [redirect, setAllowNext])

  const routesOptions = [
    {
      label: 'Select a route',
      value: null,
    },
    ...routes.map((route) => {
      return {
        label: route.url,
        value: route.url,
      }
    }),
  ]

  return (
    <div>
      <h5>Redirect Route</h5>
      <InputLabel>Redirect From</InputLabel>
      <BaseTextField
        name="fromUrl"
        className="route-manager-dialog-field"
        type="text"
        placeholder="/my-new-redirect"
        onChange={checkFormValues}
      />
      <InputLabel>To</InputLabel>
      <Select
        input={{
          name: 'targetUrl',
          className: 'route-manager-dialog-field',
          onChange: checkFormValues,
        }}
        options={routesOptions}
      />
    </div>
  )
}

export default RedirectRouteForm
