import React from 'react'
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

type Props = {
  title: string
  src: string
  poster: string
  size: number
}

export default function SectionVideo({title, src, poster, size}: Props) {
  if (!src) {
    return <p>Sorry, no video source was provided.</p>
  }
  
  return <section>
    <Plyr
      source={{
        type: 'video',
        title: title,
        sources: [
          {
            src: src,
            type: 'video/mp4',
            size: size,
          },
        ],
        poster: poster,
      }}
    />
  </section>
}
