import {enumStagesOrder} from './constants'
import {FilterState, PageBuild, SectionFieldInputType} from '@/components/shared/types'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import LucidAlert from '@/components/shared/LucidAlert'
import {KeyedMutator} from 'swr'
import {toast} from 'react-toastify'

export const applyFiltering = (
    pageBuilds: PageBuild,
    filterState: FilterState | null
) => {
  if (!filterState) return true
  const {filterStatus, filterAssignee} = filterState
  const isSelectedStageInPage =
      filterStatus === 'all' ? true : pageBuilds.status === filterStatus
  const isSelectedAssigneeInPage =
    filterAssignee === 'all'
      ? true
      : pageBuilds.assigned_to_user?.name === filterAssignee
  return isSelectedStageInPage && isSelectedAssigneeInPage
}

// return true if object values contain input
// only works with number and string
// NOT recursive
// check for user.name if object passed is a page
export const genericSearch = (obj: { [key: string]: any }, input: string) => {
  // we consider only string and numbers
  const values = Object.values(obj).filter(
    (value) => typeof value === 'string' || typeof value === 'number'
  )
  // check for assigned_to_user.name and for page.name if object passed is a pageBuild
  const filteredValues = [
    ...values,
    obj.assigned_to_user?.name || '',
    obj.page?.name || '',
  ]
  const strPage = filteredValues.map((value) =>
    typeof value === 'string' ? value.toLowerCase() : value.toString()
  )
  return strPage.some((fieldValue) => fieldValue.includes(input.toLowerCase()))
}

export const genericSort = (
  a: any,
  b: any,
  fieldToBeSorted: string,
  direction: 'ASC' | 'DESC' = 'ASC'
) => {
  const isAsc = direction === 'ASC'
  let firstElem = a[fieldToBeSorted]
  let secondElem = b[fieldToBeSorted]

  if (firstElem > secondElem) {
    return isAsc ? 1 : -1
  }
  if (firstElem < secondElem) {
    return isAsc ? -1 : 1
  }
  return 0
}

// todo: a and b should be of type Page
export const pageSort = (
  a: any,
  b: any,
  fieldToBeSorted: keyof PageBuild,
  direction: 'ASC' | 'DESC' = 'ASC'
) => {
  if (!Object.keys(a).includes(fieldToBeSorted))
    throw new TypeError(
      `fieldToBeSorted (${fieldToBeSorted}) should be an existing key of Page`
    )
  const asc = direction === 'ASC'
  let firstElem = a[fieldToBeSorted]
  let secondElem = b[fieldToBeSorted]
  if (fieldToBeSorted === 'status') {
    firstElem = enumStagesOrder[firstElem as keyof typeof enumStagesOrder]
    secondElem = enumStagesOrder[secondElem as keyof typeof enumStagesOrder]
  }

  if (firstElem < secondElem) {
    return asc ? 1 : -1
  }
  if (firstElem > secondElem) {
    return asc ? -1 : 1
  }
  return 0
}

// TODO: Can this be refactored using only page_id -> does not matter if pages are children or parent
//  all we want to know is if the pageBuilds are linked to the same page.
// LUC-303: A page should only be able to have one non-published copy at one time.
export const isCopyAllowed = (
  pageBuildToBeCopied: PageBuild,
  pageBuilds: PageBuild[]
) => {
  const isChild = (currentPageParentId?: string) =>
    pageBuildToBeCopied.id === currentPageParentId

  const isSibling = (currentPageParentId?: string) =>
    pageBuildToBeCopied.source_page_build_id !== null &&
    pageBuildToBeCopied.source_page_build_id === currentPageParentId

  const siblingsAndChildren = pageBuilds.filter(
    (page) =>
      isChild(page.source_page_build_id) || isSibling(page.source_page_build_id)
  )
  return siblingsAndChildren.length
    ? !siblingsAndChildren.some(
        (child) => child.id !== child.page?.live_page_build_id
      )
    : true
}

// flatmap to filter possible undefined
export const getPagesFromId = (
  ids: string[],
  pages: PageBuild[]
): PageBuild[] =>
  ids.flatMap((id) => pages.find((page) => page.id === id) || [])

export const selectedAll = (
  itemIds: string[],
  setSelectedItems: (items: string[]) => void,
  selectedItems: { [key: string]: any }[]
) => {
  if (!itemIds.length) {
    setSelectedItems(
      selectedItems.reduce<string[]>((acc, curr) => [...acc, curr.id], [])
    )
  } else {
    setSelectedItems([])
  }
}

export function handleSelectItem<T>(
  itemId: T,
  selectedItems: T[],
  setSelectedItems: (item: T[]) => void
) {
  if (selectedItems.includes(itemId)) {
    setSelectedItems([...selectedItems.filter((id) => id !== itemId)])
  } else {
    setSelectedItems([...selectedItems, itemId])
  }
}

function allProgress(
  proms: Promise<any>[],
  progressAction: (progress: number) => void
) {
  let d = 0
  progressAction(0)
  for (const p of proms) {
    p.then(() => {
      d++
      progressAction(d / proms.length)
    })
  }
  return Promise.all(proms)
}

function isIterable(input: any): input is Variable[] {
  if (input === null || input === undefined) {
    return false
  }
  return typeof input[Symbol.iterator] === 'function'
}

type Variable = { [key: string]: any }
type Message = { error: string; success: string }
export type CustomGraphQLStateHandler = {
  error: (message: string) => void,
  success: (message: string) => void,
}

export async function handleMutation(
  mutation: string,
  variable: Variable | Variable[],
  message: Message,
  mutate?: KeyedMutator<any>
) {
  const variables = isIterable(variable)
    ?
      Array.from(variable)
    : Array.from([variable])

  if (!variables.length) return

  const {success, error} = message
  let percent = 0
  await toast.promise(
    allProgress(
        variables.map(variable => lucidDataFetcherV2(mutation, variable)),
        (progress) => {
          // keep track of progress for batch mutations
          percent = progress * 100
        }
    ),
    {
      pending: `Saving to database ${percent}%`,
      success,
      error,
    }
  )

  if (mutate) mutate()
}

export async function handleGraphqlMutation(
  mutation: string,
  variables: Object,
  lucidAlert?: LucidAlert,
  message?: Message,
  mutate?: KeyedMutator<any>,
  customStatusHandler?: CustomGraphQLStateHandler
) {
  try {
    const res = await lucidDataFetcherV2(mutation, variables)
    if (res.errors) {
      console.error(res)
      if (lucidAlert && message) lucidAlert.error(message.error)
      if (customStatusHandler && message) customStatusHandler.error(message.error)
    } else {
      if (lucidAlert && message) lucidAlert.success(message.success)
      if (customStatusHandler && message) customStatusHandler.success(message.success)
      if (mutate) mutate()
      return res
    }
  } catch (e) {
    console.error(e)
    if (lucidAlert && message) lucidAlert.error(message.error)
    if (customStatusHandler && message) customStatusHandler.error(message.error)
  }
}

export const FIELD_PROPERTIES_MAP: {[fieldName: string]: {type: SectionFieldInputType, default_value: string | Record<string, string>}} = {
  subtitle: {type: 'TEXT', default_value: 'Subtitle for $sectionName'},
  title: {type: 'TEXT', default_value: 'Title for $sectionName'},
  intro: {type: 'TEXTAREA', default_value: 'Intro text for $sectionName...'},
  outro: {type: 'TEXTAREA', default_value: 'Outro text for $sectionName...'},
  tagline: {type: 'TEXTAREA', default_value: 'Tagline for $sectionName...'},
  subline: {type: 'TEXTAREA', default_value: 'Subline for $sectionName...'},
  label: {type: 'TEXT', default_value: 'Label for $sectionName'},
  richtext: {
    type: 'RICHTEXT',
    default_value: '<p>Rich text for $sectionName...</p>',
  },
  button: {
    type: 'BUTTON',
    default_value: '',
  },
  video: {
    type: 'VIDEO',
    default_value: ''
  },
  images: {
    type: 'IMAGE_GROUP',
    default_value: '',
  },
  quotes: {type: 'QUOTE_GROUP', default_value: ''},
  src: {type: 'IMAGE', default_value: ''},
  alt: {type: 'TEXT', default_value: 'Alt Text for $sectionName'},
  disclaimer: {
    type: 'TEXTAREA',
    default_value: 'Disclaimer for $sectionName...',
  },
  year: {type: 'DATE', default_value: '2023-01-01'},
  date: {type: 'DATE', default_value: '2023-01-01'},
  caption: {type: 'TEXTAREA', default_value: 'Caption for $sectionName...'},
  image: {type: 'IMAGE', default_value: {
    src: '/img/NoImagePlaceholder.png',
  }},
  description: {
    type: 'TEXTAREA',
    default_value: 'Description for $sectionName...',
  },
  cliffhanger: {type: 'TEXT', default_value: 'Cliffhanger for $sectionName'},
  navigationStyleId: {type: 'TEXT', default_value: 'defaultStyle'},
  name: {type: 'TEXT', default_value: 'Name for $sectionName'},
  poster: {type: 'IMAGE', default_value: ''},
  iframe: {type: 'TEXTAREA', default_value: '<iframe src="#"></iframe>'},
  profession: {type: 'TEXT', default_value: 'Profession for $sectionName'},
  value: {type: 'TEXT', default_value: 'Value for $sectionName'},
  quote: {type: 'TEXT', default_value: 'Quote for $sectionName'},
  cite: {type: 'TEXT', default_value: 'Citation for $sectionName'},
  text: {type: 'TEXT', default_value: 'Text for $sectionName'},
  link: {type: 'TEXT', default_value: ''},
  icon: {type: 'TEXT', default_value: 'Icon for $sectionName'},
  textblock: {
    type: 'RICHTEXT',
    default_value: '<p>Rich text block for $sectionName...</p>',
  },
  content: {
    type: 'RICHTEXT',
    default_value: '<p>Content for $sectionName...</p>',
  },
  phrase: {type: 'TEXT', default_value: 'Phrase for $sectionName'},
  size: {type: 'NUMBER', default_value: '0'},
} as const
