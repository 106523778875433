import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {MENU_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'

export default function MenuBlockPortrait(props) {
  const {
    title,
    subtitle,
    intro,
    // items,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section menu-block-portrait-section back-stack color-scheme menu-section with-title gradient-over-image'}
      name="menublockportrait"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2 style={{textAlign: 'center'}}><span className="title color-scheme-title heading-1">
              <InlineTextWithVariables cms={cms} name="title" value={title}/>
            </span>
              <span className="subtitle color-scheme-subtitle heading-2">
                <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
              </span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
                </div>
              </div>
            </div>
          </div>
          <LucidInlineBlocks
            name="menu_items"
            blocks={MENU_ITEM_BLOCKS}
            itemProps={props}
            className={`row menus menu-block-portrait-items item-count-${props?.menu_items?.length ?? 0}`}
          />
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
              </div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <SectionButton name='button' href={button?.url} label={button?.label} cms={cms}/>
          </div>
        </div>
      </div>


    </Section>
  )
}

MenuBlockPortrait.StyleCore = styleCore
