import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Closer/presets'

const Closer = (props) => {
  const {
    title,
    subtitle,
    description,
    image,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section closer-section'}
      name="closer"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row">
            <div className="column closer-image">
              <SectionImage
                name="image"
                src={image?.src}
                alt={image?.alt}
                width={image?.width}
                height={image?.height}
                priority={image?.priority}
                sizes={image?.sizes}
                cms={cms}
              />
            </div>
            <div className="column">
              <div className="section-heading">
                <h2>
                  <span className="heading-1">
                    <InlineTextWithVariables
                      cms={cms}
                      name="title"
                      value={title}
                    />
                  </span>
                  <span className="heading-2">
                    <InlineTextWithVariables
                      cms={cms}
                      name="subtitle"
                      value={subtitle}
                    />
                  </span>
                </h2>
              </div>
              <div className="section-richtext paragraph">
                <RichtextWithVariables
                  cms={cms}
                  name="description"
                  richtext={description}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

Closer.StyleCore = styleCore
Closer.presets = presets

export default Closer
