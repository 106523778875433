import {
  StyleCoreComponentName,
  StyleCoreElement,
  StyleCoreTarget,
  useStyleCorePresets,
  useStyleCoreRenderer
} from '@/components/shared/StyleCore'
import React, {Key, ReactNode, useEffect, useMemo, useState} from 'react'
import {Badge, Button, Card, Dropdown, Grid, Input, Link, Loading, Modal, Spacer, Text} from '@nextui-org/react'
import {Add, Paintbucket, SearchNormal, Trash, Warning2} from 'iconsax-react'
import styled from 'styled-components'
import {ListenerTargetInner} from '@/components/shared/externalTypes'
import {StyleCoreForm} from '@/src/utils/shared/FormManagerFormNextUI'
import {useDebounce} from 'react-use'
import CodeMirror from '@uiw/react-codemirror'
import {css} from '@codemirror/lang-css'
import {useLucidContext} from '@/src/state/ServerSideStore'
import {ColorSchemeResponse} from '@/components/managers'
import {Preset} from '@/components/shared/types'
import LoadingSpinner from '@/components/managers/MenuManager/LoadingSpinner'
import {type InlineFieldRenderProps} from '@einsteinindustries/react-tinacms-inline'
import type {Field} from '@einsteinindustries/tinacms'

type CssOverridesContainerProps = {
  display: 'visible' | 'hidden' | 'locked',
  fields: (
    Field & {
    previewSrc?: (src: string) => string,
    clearable?: boolean
    options?: {
      [key: string]: any
    }
  })[],
  children: React.ReactNode
}

const StyleCoreNotAvailableMessage = () => {
  return <Text color={'error'}>
    <Grid.Container gap={0.2}>
      <Grid>
        <Warning2 size={'32px'}/>
      </Grid>
      <Grid xs={10}>
        <Text b color={'error'}>StyleCore Not Available</Text>
      </Grid>
      <Grid xs={12}>
        <Text size={'$sm'}>StyleCore has not been enabled for this section yet. <br/> To enable it, read the <Link
          isExternal href={'https://www.notion.so/einsteinindustries/StyleCore-b9b1fef4352b4e10a523517d6e4b7d62?pvs=4'}>documentation
          here</Link>.</Text>
      </Grid>
    </Grid.Container>
  </Text>

}
const StyleCoreFormWrapper = ({children, inModal}: { children: ReactNode, inModal: boolean }) => {
  const [open, setOpen] = useState(false)
  // TODO: Modal problems causes the modal to not open programmatically. Need to fix this. For now the inModal is set to false for all usages.
  return <>
    <Spacer y={2}/>
    <Text size={'$sm'} b>Style Overrides</Text>
    <Spacer y={0.3}/>
    <Card style={{
      padding: 20,
      overflow: 'visible'
    }}>
      {inModal && <>
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={open}
          width={'100%'}

        >
          <Modal.Header>
            <Text id="modal-title" size={24}>
              <Paintbucket size={24} style={{marginRight: 10}}/>
              StyleCore
              <Text b size={24}>
                Editor
              </Text>
            </Text>

          </Modal.Header>

          <Modal.Body>
            {children}
          </Modal.Body>
        </Modal>
        <Button size="lg" onClick={() => setOpen(true)}><Paintbucket style={{marginRight: 5}}/> Open StyleCore
          Editor</Button>
      </>}
      {!inModal && children}
    </Card></>
}

export function StyleCoreFormInnerSettings(
  {
    styleCoreElement,
    formManagerTarget,
    cms,
  }: { styleCoreElement: StyleCoreElement, formManagerTarget: ListenerTargetInner, cms: boolean }
) {
  return useMemo(() => {
    if (typeof styleCoreElement === 'undefined') {
      return <StyleCoreFormWrapper inModal={false}><StyleCoreNotAvailableMessage/></StyleCoreFormWrapper>
    }
    return <StyleCoreFormWrapper inModal={true}>
      <StyleCoreForm
        cms={cms}
        styleCoreElement={styleCoreElement}
        formManagerTarget={formManagerTarget}
      />
    </StyleCoreFormWrapper>
  }, [styleCoreElement, styleCoreElement?.target?.identifier, styleCoreElement?.target?.componentName])
}

export function StyleCoreCSSComponent({target, cms}: { target: StyleCoreTarget, cms: boolean }) {
  const [, StyleCoreCSS] = useStyleCoreRenderer(target, cms)
  return <style jsx>{`${StyleCoreCSS}`} </style>
}

export function CMSHiding({cms, children}: {
  cms: boolean,
  children: React.ReactNode
}) {
  if (!cms) {
    return null
  } else {
    return <>{children}</>
  }
}

/**
 * I'm just here to make the overrides container aware that the modal has been opened
 * If I wasn't here, the overrides container would close
 * when we move the mouse away from the screen.
 */
export function OverridesContainerAwareness({setIsMounted}: {
  setIsMounted: React.Dispatch<React.SetStateAction<boolean>>
}) {
  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  })
  return null
}

export function CssOverridesContainer(
  {
    display,
    fields,
    children
  }: CssOverridesContainerProps) {

  const [renderedComponent, setRenderedComponent] = useState(<LoadingSpinner/>)

  useEffect(() => {
    const setInlineSettings = async () => {
      const {InlineSettings} = await import('@einsteinindustries/react-tinacms-inline')
      setRenderedComponent(
        <InlineSettings fields={fields}/>
      )
    }
    setInlineSettings()
  }, [])

  if (display === 'hidden') {
    return null
  }

  if (display === 'locked') {
    return <CssOverridesContainerStyles>
      <Badge size="md" enableShadow disableOutline color="warning">
        <Warning2/>
        <Spacer x={0.5}/>
        Styles Available After Save
      </Badge>
    </CssOverridesContainerStyles>
  }
  return (
    <CssOverridesContainerStyles>
      <>
        {renderedComponent}
        {children}
      </>
    </CssOverridesContainerStyles>
  )
}

export function StylesModalContent(props: { input: InlineFieldRenderProps, target: StyleCoreTarget }) {
  return <>
    <Spacer y={2}/>
    <Text size={'$sm'} b>CSS Override</Text>
    <Spacer y={0.1}/>
    <StylesIDE {...props}/>
  </>
}

export function SwitchColorScheme({input, color_scheme_id_override, activeColorScheme, onChange, cms}: {
  input: InlineFieldRenderProps,
  color_scheme_id_override: string | null,
  activeColorScheme: string,
  onChange: (id: string) => void,
  cms: boolean
}) {
  const [lucidSiteStore] = useLucidContext(cms)
  const [currentScheme, setCurrentScheme] = useState<ColorSchemeResponse>(lucidSiteStore.schemes.filter((scheme) => `${scheme.id}` === color_scheme_id_override)[0] ?? lucidSiteStore.schemes.filter((scheme) => scheme.active)[0])
  const [selected, setSelected] = useState<Set<Key>>(new Set([color_scheme_id_override ?? 'unset']))
  useEffect(() => {
    const dropdownSelection = selected.entries().next().value[0]
    if (dropdownSelection === color_scheme_id_override || (color_scheme_id_override === null && dropdownSelection === 'unset')) {
      return
    }
    input.onChange(dropdownSelection)
    onChange(dropdownSelection === 'unset' ? null : dropdownSelection)
    if (dropdownSelection === 'unset') {
      setCurrentScheme(lucidSiteStore.schemes.filter((scheme) => scheme.active)[0])
    } else {
      setCurrentScheme(lucidSiteStore.schemes.filter((scheme) => `${scheme.id}` === dropdownSelection)[0])
    }
  }, [selected])

  let colorSchemeOverrideName = 'Default'
  let colorSchemeOverrideInUse = color_scheme_id_override !== null
  return <FullWidthDropdown>
    <Text h4 b>Overrides<Text>Override the default styles for this section</Text></Text>
    <hr/>
    <Spacer y={1}/>
    <Spacer y={0.2}/>
    <Text size={'$sm'} b>Color Scheme Override <Badge {...(colorSchemeOverrideInUse ? {
      color: 'success'
    } : {
      color: 'primary',
      variant: 'flat'
    })}>{colorSchemeOverrideInUse ? 'Applied' : 'Unused'}</Badge></Text>
    <Spacer y={0.2}/>
    <Dropdown>
      <Dropdown.Button flat={!colorSchemeOverrideInUse} size={'lg'} style={{
        width: '100%'
      }}>{!colorSchemeOverrideInUse ? 'No Override' : currentScheme.name}</Dropdown.Button>
      <Dropdown.Menu
        aria-label="Single selection actions"
        color="secondary"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selected}
        onSelectionChange={(keys: any) => {
          setSelected(keys)
          return keys
        }}
      >
        <Dropdown.Section title="Color Schemes">
          {lucidSiteStore.schemes.map((scheme) => {
            return <Dropdown.Item key={scheme.id}
                                  command={scheme.active ? colorSchemeOverrideName : undefined}>{scheme.name}</Dropdown.Item>
          })}
        </Dropdown.Section>
        <Dropdown.Section title="Actions">
          <Dropdown.Item
            key="unset"
            color="error"
            icon={<Trash size={22}/>}
          >
            No Override
          </Dropdown.Item>
        </Dropdown.Section>
      </Dropdown.Menu>
    </Dropdown>
    <Spacer y={1}/>
  </FullWidthDropdown>
}

function StylesIDE({input, target}: { input: InlineFieldRenderProps, target: StyleCoreTarget }) {
  const [cssOverrides, setCssOverrides] = useState<string | undefined>()
  /**
   * Note: Dynamic style jsx is slow
   *  We need to prevent the onChange from firing a rerender everytime as that would cause sluggishness
   *  That is the reason for this debounce, it's just wrapping the default behavior of final form and tina.
   */
  useDebounce(() => {
    if (typeof cssOverrides !== 'undefined') {
      input.onChange(cssOverrides)
    }
  }, 1000, [cssOverrides])

  return (
    <>
      <CodeMirror
        {...input}
        height="200px"
        extensions={[css()]}
        onChange={setCssOverrides}
      />
      <CSSPresets target={target} onChange={(e) => {
        setCssOverrides(e + (cssOverrides ?? ''))
      }}/>
    </>
  )
}


const FullWidthDropdown = styled.div`
  width: 100%;
`
const CssOverridesContainerStyles = styled.div`
  position: absolute;
  left: 24px;
  top: 64px;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  z-index: 99;

  > svg {
    margin: 4px 0 0 6px;
    height: 32px;
    width: 32px;
  }

  > div {
    position: absolute;
    border-right: none !important;

    svg {
      height: 32px;
      width: 32px;
      fill: transparent;
    }
  }
`

function PresetCard({preset, onClick}: { preset: Preset, onClick: () => void }) {
  return <Card style={{borderRadius: '20px'}}>
    <Card.Body style={{padding: 25}}>
      {preset.display?.image && <img src={preset.display?.image} style={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: '20px',
        paddingBottom: '10px'
      }}/>}
      <Text b h4 style={{paddingTop: '10px'}}>{preset.display.name}</Text>
      <Text style={{paddingTop: 20}}>{preset.display?.description}</Text>
      <div>
        <Badge variant="flat" color={'default'} style={{marginRight: 5}}>Available in: </Badge>
        {
          preset.allowedSections.map((e, i) => (
            <Badge key={i} variant="flat" color={e === 'all' ? 'secondary' : 'primary'} style={{marginRight: 5}}>
              {e}
            </Badge>
          ))
        }
      </div>
    </Card.Body>
    <div style={{padding: 10}}>
      <Button size="md" style={{width: '100%', marginBottom: 5}} onPress={onClick}>Insert</Button>
      <Button size="md" style={{width: '100%'}} onPress={() => {
        window.open(preset.display.documentationURL, '_blank')
      }} disabled={!preset.display.documentationURL} flat>Show Documentation</Button>
    </div>
  </Card>
}

function CSSPresetsModal({onChange, target, open}: {
  open: boolean,
  onChange: (preset: Preset | undefined) => void,
  target: StyleCoreTarget
}) {
  const [presets] = useStyleCorePresets(target)
  const [search, setSearch] = useState('')
  return <Modal aria-labelledby="modal-title" open={open} fullScreen>
    <Modal.Header>
      <Text size={18}>CSS<Text b size={18}>Presets</Text></Text>
    </Modal.Header>
    <Modal.Body>
      <Input
        size="lg"
        contentLeft={<SearchNormal/>}
        placeholder="Search"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
      <Grid.Container gap={2}>
        {
          presets.filter((e) => {
            if (search === '') {
              return true
            }
            return e.display.name.toLowerCase().includes(search.toLowerCase()) || e.display?.description?.toLowerCase()?.includes(search.toLowerCase()) || e.allowedSections.includes(search.toLowerCase() as StyleCoreComponentName)
          }).map((preset, index) =>
            <Grid key={index} xs={4} md={3} xl={2}>
              <PresetCard preset={preset} onClick={() => {
                onChange(preset)
              }}/>
            </Grid>)
        }
      </Grid.Container>
    </Modal.Body>
    <Modal.Footer>
      <Button size="lg" color="error" flat style={{width: '100%', padding: 15, marginTop: 20}} onPress={() => {
        onChange(undefined)
      }}>Cancel</Button>
    </Modal.Footer>
  </Modal>
}

function formatPresetAsCSSInsert(preset: Preset) {
  return `
/* --- START PRESET: ${preset.display.name} --- */
/* --- DOCS: ${preset.display.documentationURL} --- */
 ${preset.css}
/* --- END PRESET: ${preset.display.name} --- */\n`
}

function CSSPresets({onChange, target}: { onChange: (e: string) => void, target: StyleCoreTarget }) {
  const [presets] = useStyleCorePresets(target)
  const [open, setOpen] = useState(false)
  const [inserting, setInserting] = useState(false)
  const ButtonText = () => {
    if (inserting) {
      return <Loading size="xs"/>
    } else {
      return <>
        <Add/>{presets.length === 0 ? 'No Presets Available' : `${presets.length} Preset${presets.length > 1 ? 's' : ''} Available`}</>
    }
  }
  return <>
    <CSSPresetsModal open={open} onChange={(preset) => {
      setOpen(false)
      setInserting(true)
      if (typeof preset !== 'undefined') {
        onChange(formatPresetAsCSSInsert(preset))
      }
      setTimeout(() => {
        setInserting(false)
      }, 1000)
    }} target={target}/>
    <Button style={{width: '100%', marginTop: '10px', marginBottom: '10px'}} flat size="md" onPress={() => {
      setOpen(true)
    }} disabled={presets.length === 0 || inserting}>
      <ButtonText/>
    </Button>
  </>
}
