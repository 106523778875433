import {Section} from '@/components/shared/types'
import {Box1, Box2, BoxAdd, BoxTick, EyeSlash} from 'iconsax-react'
import React from 'react'

export function sectionStatusFormat(section: Section | undefined) {
  let statusPill: {
    color: 'success' | 'error' | 'warning' | 'default' | undefined,
    text: string,
    icon: any
  } = {
    color: 'error',
    text: 'Unknown Status',
    icon: <Box1 size={'16px'}/>
  }

  if (typeof section !== 'undefined') {
    switch (section?.status) {
      case 'HIDDEN':
        statusPill = {
          color: undefined,
          text: 'Hidden',
          icon: <EyeSlash size={'16px'}/>
        }
        break
      case 'DISABLED':
        statusPill = {
          color: 'error',
          text: 'Disabled',
          icon: <Box2 size={'16px'}/>
        }
        break
      case 'ACTIVE':
        statusPill = {
          color: 'success',
          text: 'Active',
          icon: <BoxTick size={'16px'}/>
        }
        break
    }
  } else {
    statusPill = {
      color: 'default',
      text: 'New',
      icon: <BoxAdd size={'16px'}/>
    }
  }

  return statusPill
}
