import styled from 'styled-components'
import {InlineWysiwyg} from '@einsteinindustries/react-tinacms-editor'
import {useState} from 'react'
import sourcePlugin from './sourcePlugin'
import {SourceMenuView} from './SourceMenuView'
import SourceModeWrapper from './SourceModeWrapper'
import {InlineTextarea} from '@einsteinindustries/react-tinacms-inline'

const StyledDiv = styled.div`
  margin-bottom: 2rem;

  p {
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const StyledSourceText = styled.div`
  all: unset;
  font-family: 'Source Code Pro', monospace;
`

export default function SectionRichtext(
  {
    name,
    richtext
  }: {
    name: string,
    richtext: string
  }
) {
  const [isSourceMode, setIsSourceMode] = useState<boolean>(false)
  
  if (!richtext) return null

  const handleSourceToggle = () => {
    setIsSourceMode(!isSourceMode)
  }

  const sourcePluginWrapper = {
    name: 'sourcePlugin',
    MenuItem: () => {
      return <SourceMenuView onClick={handleSourceToggle} />
    },
    plugin: sourcePlugin
  }

  return isSourceMode ? (
    <SourceModeWrapper onExit={handleSourceToggle}>
      <StyledSourceText>
        <InlineTextarea name={name}>{richtext}</InlineTextarea>
      </StyledSourceText>
    </SourceModeWrapper>
  ) : (
    <InlineWysiwyg
      name={name}
      format="html"
      focusRing={{borderRadius: 4}}
      sticky={false}
      plugins={[
        sourcePluginWrapper
      ]}
    >
      <StyledDiv dangerouslySetInnerHTML={{__html: richtext}}/>
    </InlineWysiwyg>
  )
}
