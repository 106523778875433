import SectionImage from '../shared/SectionImage'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  affiliations: {
    ...baseItem,
    label: 'Affiliations Item',
    image: {
      src: '',
      alt: '',
    },
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: '',
        alt: '',
      },
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              return constructAssetUrl(media.id)
            },
            previewSrc: (src) => {
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
          {
            name: 'width',
            label: 'Image Width',
            component: 'text',
          },
          {
            name: 'height',
            label: 'Image Height',
            component: 'text',
          },
          {
            name: 'src',
            label: 'Image URL',
            component: 'text',
          }
        ],
      },
    ],
  },
}

export const AFFILIATIONS_ITEM_BLOCKS = {
  affiliations: {
    Component({_name, index, data, cms}) {
      return (
        <ItemWrapper index={index} cms={cms}>
          <SectionImage
            name="image"
            src={data.image.src}
            alt={data.image.alt}
            width={data.image.width}
            height={data.image.height}
            priority={data.image?.priority}
            sizes={data.image?.sizes}
            cms={cms}
          />
        </ItemWrapper>
      )
    },
    template: itemTemplates.affiliations,
  },
}

function ItemWrapper(props) {
  if (!props.cms) {
    return <div className="col-section-image col-section-image-container">
        {props.children}
    </div>
  }
  return (
    <div
      className="col-section-image col-section-image-container"
    >
      <LucidBlocksControls
        cms={props.cms}
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </LucidBlocksControls>
    </div>
  )
}
