import React, {ChangeEvent, Dispatch, SetStateAction, useContext, useState} from 'react'
import {Form, useCMS} from '@einsteinindustries/tinacms'
import {Page, PageBuild, PageBuildStatus, Site, User} from '../../shared/types'
import styled, {createGlobalStyle, css} from 'styled-components'
import {enumStagesOrder} from '@/components/managers/PageManager/constants'
import {Menu, MenuButton, MenuItem, MenuItems, MenuPopover,} from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import {LucidSiteContext} from '@/src/state/site/Store'
import {buttonModifier, roundClickable} from '@/src/styles/home'
import {useRouter} from 'next/router'
import {Button, FormElement, Input, Loading, Modal, Spacer, Text} from '@nextui-org/react'
import {RefreshCircle} from 'iconsax-react'
import useUserSearch from '@/src/utils/useUserSearch'
import {
  handlePageWorkflow,
  publishSite,
  updatePagesWorkflow
} from '@/components/managers/PageManager/components/AssignUserGroup'
import {mutate as globalMutate} from 'swr'
import {ALL_PAGES, PAGE_BUILD} from '@/graphql/queries'
import {lucidDataFetcherV2} from '@/graphql/fetchers'

/**
 * TODO:
 *  1. We need to build a system where we can get the previous page assignee to
 *     prevent the same user from approving its own work. (it could be part of the notification model. see below)
 *  2. We need to implement roles to only allow content admin or owners to approve work
 *  3. We need to store users who login in the users table
 *  4. We need state where we store the currently logged in user
 *  5. We need to be able to email the assigned user, as well as rejection/approval/request approval requests
 *  6. We need to a notification model to include messages within the requests
 */
interface WorkflowTasksProps {
  pageBuild: PageBuild
  pages: Page[]
  site: Site
}

interface ConfirmationModalPayload {
  display: boolean
  text: string
  onCancel: undefined | (() => void)
  onConfirm: undefined | (() => void)
}

const tinaButtonStyleDisable = css`
  filter: grayscale(25%);
  opacity: 0.6;
  cursor: default;
`

const StyledMenuItem = styled(MenuItem)`
  padding: 1rem;
`

const StyledSearchedOptions = styled.div`
  position: absolute;
  width: 100%;

  .styled-searched-divider {
    background-color: var(--nextui-colors-accents1);
    padding: 0 10px 2px;

    div {
      border-top: 1px solid var(--nextui-colors-accents5);
    }
  }

  ul {
    background-color: white;
    box-shadow: var(--nextui-shadows-sm);
    border-radius: 0 0 var(--nextui-space-6) var(--nextui-space-6);
    background: var(--nextui-colors-accents1);

    li {
      cursor: pointer;
      padding: var(--nextui-space-2) var(--nextui-space-5);

      :hover {
        background: var(--nextui-colors-accents5);
      }
    }
  }
`

export const CTA = styled.button<{ is_expanded?: string }>`
  ${roundClickable};
  ${(props) => props.disabled && tinaButtonStyleDisable}
  ${(props) => props.is_expanded === 'true' && 'border-bottom-left-radius: 0px'}
`

const StyledMenuItems = styled(MenuItems)`
  background-color: var(--tina-color-primary);
  border: none;
  width: 170px;
  padding: 0;
`

const StyledModalBody = styled.div<{ hasResults: boolean }>`
  position: relative;

  ${(props) => props.hasResults && css`
    height: 150px;`
  }
  > div {
    width: 100%;

    label {
      ${(props) => props.hasResults && css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `}
    }
  }
`

const StyledMenuButton = styled(MenuButton)<{ is_expanded: string }>`
  ${roundClickable};
  ${(props) => props.disabled && tinaButtonStyleDisable}
  width: 43px;
  background-color: var(--tina-color-primary);
  color: var(--tina-color-grey-0);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0 var(--tina-padding-small);

  ${(props) => props.is_expanded === 'true' && 'border-bottom-right-radius: 0px'};
`

const StyledMenuPopover = styled(MenuPopover)`
  border-top: 1px solid #ccc;
  z-index: 2501;
  color: var(--tina-color-grey-0);
`

const Container = styled.div`
  ${buttonModifier};
  z-index: 1;
  position: absolute;
  right: 60px;
`

export const statusButtons = {
  PROPOSED: {request: 'Created', accept: 'Submit'},
  IN_PROGRESS: {request: 'Request Approval', accept: 'Request Approval'},
  READY_FOR_REVIEW: {request: 'Approve/Reject', accept: 'Approve'},
  APPROVED: {request: 'Publish/Reject', accept: 'Publish'},
  PUBLISHING: {request: 'Publishing', accept: 'Submit'},
  PUBLISHED: {request: 'Published', accept: 'Submit'},
  PUBLISHED_MODIFIED: {request: 'Published/Modified', accept: 'Request Approval'},
} as const

const arrayStages: PageBuildStatus[] = Object.keys(enumStagesOrder) as PageBuildStatus[]
// Special case: published page can't go higher -> un_publish will bring back to in progress
export const getNextStage = (status: PageBuildStatus) =>
  status === 'APPROVED' || status === 'PUBLISHED_MODIFIED'
    ? 'IN_PROGRESS'
    : arrayStages[arrayStages.indexOf(status) + 1]

export default function WorkflowTasks({site, pages, pageBuild}: WorkflowTasksProps) {
  const [assignModel, setAssignModel] = useState({display: false, isChangeStatus: false})
  const [expended, setExpended] = useState(false)
  const {asPath} = useRouter()
  const [, , siteBuildVersion, siteId, pageBuildId, pageBuildVersion] = asPath.split('/')
  const [{isSaving},] = useContext(LucidSiteContext)
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalPayload & { publishing: boolean }>(
    {
      publishing: false,
      display: false,
      text: 'Are you ready to publish the entire site?',
      onCancel: undefined,
      onConfirm: undefined
    }
  )

  const cms = useCMS()
  const forms = cms.plugins.getType<Form>('form')
  const form = forms.all().length ? forms.all()[0] : null
  const currentState = form?.finalForm.getState()

  const hasProxyConfig = typeof site.proxy_configs.find(pc =>
    pc.site_build_version === siteBuildVersion && pc.page_build_version === pageBuildVersion
  ) !== 'undefined'

  const dispatchClick = () => {
    // emulate click on Tina's save button
    const saveButton =
      document.querySelectorAll<HTMLElement>('[class*="save"i]')[0]
    saveButton.click()
  }
  let saveButtonClass = isSaving || confirmationModal.publishing ? 'button-loading' : ''

  const excludedStages = ['PROPOSED']
  const canPublishSite = pages.filter(
    p => {
      return p[`${pageBuildVersion}_page_build` as 'live_page_build' | 'dev_page_build']?.status === 'APPROVED'
    }
  ).length === pages.length

  function onPublishSite() {
    setConfirmationModal((prev) => {
      return {
        ...prev,
        display: true,
        onCancel: () => {
          setConfirmationModal((prev) => {
            return {...prev, display: false}
          })
        },
        onConfirm: async () => {
          setConfirmationModal((prev) => {
            return {...prev, display: false, publishing: true}
          })
          await publishSite(siteBuildVersion, pageBuildVersion, pages[0].site_id)
          await globalMutate([ALL_PAGES, {site_id: pages[0].site_id}])
          await globalMutate([PAGE_BUILD, {id: pageBuild.id}])
        }
      }
    })
  }

  return (
    <Container id="lucid-save-button">
      <CTA
        className={saveButtonClass}
        style={{
          backgroundColor: 'var(--tina-color-primary)',
          color: 'var(--tina-color-grey-0)',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          padding: 0,
          width: '127px',
        }}
        onClick={dispatchClick}
        disabled={currentState?.pristine || isSaving}
        is_expanded={expended.toString()}
      >Save
      </CTA>
      <Menu>
        {({isExpanded}) => (
          <>
            {isExpanded ? setExpended(true) : setExpended(false)}
            <StyledMenuButton
              is_expanded={isExpanded.toString()}
              disabled={isSaving || !currentState?.pristine}
            >
              <span aria-hidden>▾</span>
            </StyledMenuButton>
            <StyledMenuPopover>
              <div className='workflow-menu'>
                <StyledMenuItems>
                  <StyledMenuItem
                    onSelect={() => setAssignModel({display: true, isChangeStatus: false})}
                  >Assign User
                  </StyledMenuItem>
                  {!excludedStages.includes(pageBuild.status) && (
                    <StyledMenuItem
                      onSelect={() => setAssignModel({display: true, isChangeStatus: true})}
                      disabled={pageBuild.status === 'APPROVED' && !hasProxyConfig}
                    >{statusButtons[pageBuild.status].request}
                    </StyledMenuItem>
                  )}
                  <StyledMenuItem
                    disabled={!hasProxyConfig || !canPublishSite}
                    onSelect={onPublishSite}
                  >Publish Site
                  </StyledMenuItem>
                </StyledMenuItems>
              </div>
              {' '}
            </StyledMenuPopover>
          </>
        )}
      </Menu>
      <AssignModal
        pageBuild={pageBuild}
        display={assignModel.display}
        isChangeStatus={assignModel.isChangeStatus}
        setAssignModel={setAssignModel}
        pages={pages}
      />
      {confirmationModal.display && <ConfirmationModal {...confirmationModal}/>}
      <GlobalStyle/>
    </Container>
  )
}

// Tina save button styling
const GlobalStyle = createGlobalStyle`
  [class*="SaveButton"][class*="ToolbarButton"] {
    background-color: var(--tina-color-grey-1) !important;
    width: 160px;
  }

  [class*="ExitButton"] {
    display: none;
  }
`

function AssignModal(
  {display, pages, pageBuild, setAssignModel, isChangeStatus}:
    {
      display: boolean,
      pages: Page[],
      pageBuild: PageBuild,
      isChangeStatus: boolean,
      setAssignModel: Dispatch<SetStateAction<{ display: boolean, isChangeStatus: boolean }>>
    }) {

  const {asPath} = useRouter()
  const [, , siteBuildVersion, , , pageBuildVersion] = asPath.split('/')
  const [assignedTo, setAssignedTo] = useState<User | undefined>(pageBuild.assigned_to_user)
  const [searchedFor, setSearchFor] = useState('')
  const [dirty, setDirty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [justGotPublished, setJustGotPublished] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalPayload>(
    {display: false, text: '', onCancel: undefined, onConfirm: undefined}
  )

  const [users] = useUserSearch(searchedFor)

  const thisPage = pages.find(({id}) => id === pageBuild.page_id)

  async function onClose() {
    if (justGotPublished) {
      await globalMutate([PAGE_BUILD, {id: pageBuild.id}])
    } else {
      setAssignModel({display: false, isChangeStatus: false})
      setAssignedTo(pageBuild.assigned_to_user)
    }
  }

  function onReject() {
    setConfirmationModal({
      display: true,
      text: 'Are you sure you want to reject and move page back to in progress?',
      onCancel: () => {
        setConfirmationModal((prev) => {
          return {...prev, display: false}
        })
      },
      onConfirm: async () => {
        setConfirmationModal((prev) => {
          return {...prev, display: false}
        })
        setIsLoading(true)
        await updatePagesWorkflow([pageBuild], assignedTo, 'IN_PROGRESS')
        await globalMutate([PAGE_BUILD, {id: pageBuild.id}])
      }
    })
  }

  async function onRefresh() {
    setIsLoading(true)
    const {data: {pageBuild: {status}}} = await lucidDataFetcherV2<PageBuild>(PAGE_BUILD, {id: pageBuild.id})
    if (status === 'PUBLISHED' || status === 'PUBLISHED_MODIFIED') {
      setJustGotPublished(true)
    }
    setIsLoading(false)
  }

  async function onConfirm() {
    setIsLoading(true)
    if (!isChangeStatus) {
      await updatePagesWorkflow([pageBuild], assignedTo)
    } else {
      await handlePageWorkflow(siteBuildVersion, pageBuildVersion, pageBuild, assignedTo)
    }
    await globalMutate([PAGE_BUILD, {id: pageBuild.id}])
  }

  function onSearchUser({target}: ChangeEvent<FormElement>) {
    setSearchFor(target.value)
  }

  function onSelectUser(user: User | undefined) {
    setAssignedTo(user)
    setSearchFor('')
    setDirty(true)
  }

  let secondaryButtonLabel = 'OK'
  if (dirty && assignedTo) {
    secondaryButtonLabel = 'Assign'
  }

  return (
    <div>
      <Modal
        scroll
        closeButton
        id='assign-modal'
        width="424px"
        aria-labelledby="Assign To User manage page status"
        open={display}
        onClose={onClose}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            <Text b size={18}>
              {`${thisPage?.name || '...'} assignment and status`}
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <StyledModalBody hasResults={users.length > 0}>
            <Spacer/>
            <Input
              onClearClick={() => onSelectUser(undefined)}
              clearable={searchedFor !== '' || typeof assignedTo !== 'undefined'}
              value={searchedFor !== '' ? searchedFor : assignedTo?.name || undefined}
              labelPlaceholder={assignedTo ? 'Assigned User' : 'No Assignee'}
              aria-label='Assign To User'
              onChange={onSearchUser}
            />
            <SearchedOptions users={users} onSelect={onSelectUser}/>
          </StyledModalBody>
          {(!justGotPublished && isChangeStatus && pageBuild.status === 'PUBLISHING') &&
            <div style={{margin: 'auto'}}>
              Deploying... <Loading/>
            </div>
          }
          {(justGotPublished || pageBuild.status === 'PUBLISHED') &&
            <Text color='success'>Page is live</Text>
          }
        </Modal.Body>
        <Modal.Footer>
          {isChangeStatus && pageBuild.status === 'PUBLISHING' &&
            <Button auto flat color="default" onPress={onRefresh}>
              <RefreshCircle size='30px'/>
            </Button>
          }
          <Button auto flat color="warning" onPress={onClose}>
            Cancel
          </Button>
          {isChangeStatus && ['READY_FOR_REVIEW', 'APPROVED'].includes(pageBuild.status) &&
            <Button disabled={isLoading} auto onPress={onReject} color="error">
              Reject
            </Button>
          }
          <Button disabled={isLoading || !dirty && !isChangeStatus} auto onPress={onConfirm}>
            {isChangeStatus ? statusButtons[pageBuild.status].accept : secondaryButtonLabel}
            {isLoading && <Loading color="currentColor" size="sm"/>}
          </Button>
        </Modal.Footer>
      </Modal>
      {confirmationModal.display && <ConfirmationModal {...confirmationModal}/>}
    </div>
  )
}

function ConfirmationModal({text, onCancel, onConfirm}: ConfirmationModalPayload) {
  return (
    <Modal blur open>
      <Modal.Header>
        <Text id="modal-title" size={18}>
          <Text b size={18}>
            {text}
          </Text>
        </Text>
      </Modal.Header>
      <Modal.Footer>
        <Button auto flat color="warning" onPress={onCancel}>
          Cancel
        </Button>
        <Button auto flat color="primary" onPress={onConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function SearchedOptions({users, onSelect}: { onSelect: (user: User) => void, users: User[] }) {
  if (users.length === 0) return null

  return (
    <StyledSearchedOptions>
      <section className='styled-searched-divider'>
        <div/>
      </section>
      <ul>
        {users.map(u => <li onClick={() => onSelect(u)} key={u.id}>{u.name}</li>)}
      </ul>
    </StyledSearchedOptions>
  )
}
