import {Button, Card, Tooltip} from '@nextui-org/react'
import {ArrowCircleLeft} from 'iconsax-react'
import {useRef, FocusEvent, useEffect} from 'react'
import styled from 'styled-components'

type SouceModeWrapperProps = {
  onExit: () => void
  children: React.ReactNode
}

const StyledButton = styled(Button)`
  z-index: var(--tina-z-index-5);
  border: none;
`

const SourceModeWrapper: React.FC<SouceModeWrapperProps> = ({onExit, children}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    wrapperRef.current?.focus()
  }, [])

  const handleFocusOut = (e: FocusEvent<HTMLDivElement>) => {
    if (!wrapperRef.current?.contains(e.relatedTarget as Node)) onExit()
  }

  return (
    <div tabIndex={0} onBlur={handleFocusOut} ref={wrapperRef}>
      <Tooltip content="Back to Editor" placement="top">
        <StyledButton
          auto
          size="sm"
          style={{marginBottom: '8px'}}
          onClick={onExit}
          icon={<ArrowCircleLeft size="sm" />}
          rounded
        />
      </Tooltip>
      <Card style={{padding: '10px'}}>
        {children}
      </Card>
    </div>
  )
}

export default SourceModeWrapper
