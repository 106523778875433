import {Media, MediaListOptions, MediaStore, MediaUploadOptions,} from '@einsteinindustries/tinacms-core'
import S3 from 'aws-sdk/clients/s3'
import STS from 'aws-sdk/clients/sts'
import {objectToMedia} from './utils'
import {MediaListResponse} from '@/components/shared/types'

export class NextS3MediaStore implements MediaStore {
  s3Bucket: string
  s3ReadUrl: string
  s3StsToken: STS.Types.GetFederationTokenResponse | null = null
  s3StsTokenCreatedAt: number | null = null
  accept = '*'
  readOnly: boolean

  constructor({
    s3Bucket,
    s3ReadUrl = '',
    readOnly = false,
  }: S3MediaStoreOptions) {
    this.s3Bucket = s3Bucket
    this.s3ReadUrl = s3ReadUrl || (`https//${s3Bucket}.${S3_DEFAULT_DOMAIN}` as string)
    this.readOnly = readOnly
  }

  async persist(
    files: MediaUploadOptions[],
    currentTab?: number
  ): Promise<Media[]> {
    if (this.readOnly) {
      throw new Error('Cannot persist to read-only media store')
    }
    const uploaded: Media[] = []

    const token = await this.getS3StsToken()
    for await (const {directory, file} of files) {
      try {
        const uploadResult: S3UploadObject = await uploadToS3(
          token,
          this.s3Bucket,
          directory,
          file
        )
        uploaded.push(objectToMedia(uploadResult, this.s3ReadUrl))
      } catch (e) {
        console.error(e)
      }
    }

    return uploaded
  }

  async previewSrc(filename: string) {
    return ''
  }

  async list(options?: MediaListOptions): Promise<MediaListResponse> {
    const directory = options?.directory ?? ''
    const offset = <number>options?.offset ?? 0
    const limit = 20
    const items: Media[] = []

    const token = await this.getS3StsToken()
    const listResult = await listInS3(token, this.s3Bucket, directory)
    // List child directories
    if (listResult.CommonPrefixes) {
      const prefixItems: S3.Types.CommonPrefixList = listResult.CommonPrefixes
      for (const prefixItem of prefixItems) {
        items.push(prefixToMedia(prefixItem))
      }
    }
    // List files in current directory
    if (listResult.Contents) {
      const resultItems: S3.Types.ObjectList = listResult.Contents
      for (const resultItem of resultItems) {
        if (resultItem.Size === 0) continue
        items.push(objectToMedia(resultItem, this.s3ReadUrl))
      }
    }

    return {
      items,
      offset,
      // nextOffset: nextOff,
      limit,
      totalCount: items.length,
    }
  }

  async delete(media: Media) {
    if (this.readOnly) {
      throw new Error('Cannot delete from read-only media store')
    }
    const token = await this.getS3StsToken()
    await deleteFromS3(token, this.s3Bucket, media.id)
  }

  private async getS3StsToken(): Promise<STS.Types.GetFederationTokenResponse> {
    if (isNonExpiredS3StsToken(this.s3StsToken, this.s3StsTokenCreatedAt)) {
      return this.s3StsToken as STS.Types.GetFederationTokenResponse
    }

    // There's no token in the state, or the token has expired (or is about to
    // expire), so request a new one
    const data = await getNewS3StsToken()

    this.s3StsToken = data.token
    this.s3StsTokenCreatedAt = data.createdAt

    return this.s3StsToken as STS.Types.GetFederationTokenResponse
  }
}

interface S3MediaStoreOptions {
  s3Bucket: string
  s3ReadUrl?: string
  readOnly?: boolean
}

interface S3UploadObject {
  Location: string
  ETag: string
  Bucket: string
  Key: string
}

interface StsTokenAndCreatedAt {
  token: STS.Types.GetFederationTokenResponse
  createdAt: number
}

const S3_DEFAULT_DOMAIN = process.env.NEXT_PUBLIC_S3_DEFAULT_DOMAIN

const getPrefix = (directory: string) => {
  const trimmedDirectory = directory.replace(
    /(^\/|\/$)/, // leading or trailing slash
    ''
  )
  return `${trimmedDirectory}${trimmedDirectory ? '/' : 'einstein-files/'}`
}

const listInS3 = async (
  token: STS.Types.GetFederationTokenResponse,
  bucket: string,
  directory: string
) => {
  const s3 = getS3(token)

  const params = {
    Bucket: bucket,
    Delimiter: '/',
    Prefix: getPrefix(directory),
  }
  const s3ListObjects = s3.listObjectsV2(params)
  return await s3ListObjects.promise()
}

const uploadToS3 = async (
  token: STS.Types.GetFederationTokenResponse,
  bucket: string,
  directory: string,
  file: File,
) => {
  const filename = encodeURIComponent(file.name)
  const s3 = getS3(token)
  const key = getPrefix(directory) + `${filename.replace(/\s/g, '-')}`

  const blob = await getFileContents(file)

  const params: S3.Types.PutObjectRequest = {
    Bucket: bucket,
    Key: key,
    Body: blob,
    CacheControl: 'max-age=630720000, public',
    ContentType: file.type,
  }

  const s3Upload = s3.upload(params)
  return await s3Upload.promise()
}

const deleteFromS3 = async (
  token: STS.Types.GetFederationTokenResponse,
  bucket: string,
  key: string
) => {
  const s3 = getS3(token)

  const params = {
    Bucket: bucket,
    Key: key,
  }

  const s3DeleteObject = s3.deleteObject(params)
  await s3DeleteObject.promise()
}

const getNewS3StsToken = async (): Promise<StsTokenAndCreatedAt> => {
  const res = await fetch('/api/s3-sts-token')
  const data = await res.json()

  if (data.error) {
    console.error(data.error)
    throw data.error
  }

  if (!data.token) {
    const msg = 'Failed to get S3 STS token'
    console.error(msg)
    throw new Error(msg)
  }

  return data
}

const isNonExpiredS3StsToken = (
  token: STS.Types.GetFederationTokenResponse | null,
  createdAt: number | null
): boolean => {
  return (
    token !== null &&
    createdAt !== null &&
    Math.floor((Date.now() - createdAt) / 1000) < 60 * 59
  )
}

const getS3 = (token: STS.Types.GetFederationTokenResponse) => {
  if (!token.Credentials) {
    // Should never happen, this is just to make typescript happy
    throw new Error('token is missing required Credentials attribute')
  }

  return new S3({
    credentials: {
      accessKeyId: token.Credentials.AccessKeyId,
      secretAccessKey: token.Credentials.SecretAccessKey,
      sessionToken: token.Credentials.SessionToken
    }
  })
}

const getFileContents = (file: File): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (readEvent) => {
      resolve(readEvent.target?.result)
    }
    reader.readAsArrayBuffer(file)
  })
}

const prefixToMedia = (item: S3.Types.CommonPrefix): Media => {
  if (!item.Prefix) {
    // Should never happen, this is just to make typescript happy
    throw new Error('item is missing required Prefix attribute')
  }

  const directory = item.Prefix.substring(0, item.Prefix.lastIndexOf('/'))

  return {
    id: directory,
    filename: directory,
    directory: '',
    type: 'dir',
  }
}
