import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Menu Photo: 4 column',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/menu_photo-IID-128.png',
      documentationURL: 'https://www.notion.so/einsteinindustries/Menu-Photo-4-column-f443f2048c6047119ea3320f70bf1af4?pvs=4'
    },
    allowedSections: [],
    notion_IID: '128',
    css: `
@media (min-width:1600px) {
body[data-uri="/dental-implants"] section[data-lucid-group="Menu Photo (1)"].menu-photo-section .menu-item-break {
  display: none;
}
body[data-uri="/dental-implants"] section[data-lucid-group="Menu Photo (1)"].menu-photo-section .menus > .menu {
  flex: 0 0 25%;
}
}
    `
  }
]
