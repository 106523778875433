import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Video Wide: Remove space between video wide sections and next section',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/video-IID-53.png',
      documentationURL: 'https://www.notion.so/einsteinindustries/Video-Wide-Remove-space-between-video-wide-sections-and-next-section-33919729b47343e0b248d117e47ba49c?pvs=4'
    },
    notion_IID: '156',
    allowedSections: ['videowide'],
    css: `
    section.section.video-looping-wide-section {
    padding-bottom: 0;
}
    `
  }
]
