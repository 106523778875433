import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Textblock Quote Carousel: Full-width Quote Carousel',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Quote-Carousel-Full-width-Quote-Carousel-b64a032c9a4f48fb96447cf5ce8f9e30?pvs=4'
    },
    notion_IID: '155',
    allowedSections: [],
    css: `
    @media (min-width:1024px) {
section.color-scheme-26 .swiper-pagination {
  position: static;
}

section.color-scheme-26 .section-richtext .testimonials .swiper-container {
  width: 100%;
}
section.color-scheme-26 .section-richtext .swiper-button-prev {
  grid-column: 1;
}


section.color-scheme-26 {
padding-top: 10rem;
padding-bottom: 10rem;
}


}
section.color-scheme-26 .testimonials blockquote {
  text-align: center;
}

section.color-scheme-26 .column.column-2 {
  display: none;
}
section.color-scheme-26 .swiper-pagination-bullet {
  background: #fff;
  border-radius: 0;
  width: 2rem !important;
  height: .5rem !important;
}
section.color-scheme-26 .swiper-pagination-bullet-active {

  background: #fff;
opacity: 1 !important;

}

    `
  }
]
