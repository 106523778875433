import React, {useContext, useEffect, useState} from 'react'
import Review from './Review'
import moment from 'moment'
import {mapReviewToFields} from './utils'
import {LucidSiteContext} from '@/src/state/site/Store'
import {ReviewsContext, YextReview} from '@/src/state/site/Reviews'
import {Grid} from '@nextui-org/react'

type ReviewPickerProps = {
    onChange: (data: any) => void,
    value: any
}

const ReviewPicker = ({onChange, value}: ReviewPickerProps) => {
  const [loading, setLoading] = useState(true)
  const [reviews, setReviews] = useState<YextReview[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [siteContext] = useContext(LucidSiteContext)
  const [yextReviews] = useContext(ReviewsContext)
  const eisAccount = siteContext.site?.client_account ?? ''

  useEffect(() => {
    async function fetchData() {
      if (!yextReviews.reviews.get(String(eisAccount))) {
        const data = await fetch('/api/yext/reviews', {
          body: JSON.stringify({
            accountId: String(eisAccount),
            limit: '100',
            date: moment().format('YYYYMMDD')
          }),
          method: 'POST',
          headers: [
            ['Content-Type', 'application/json'],
          ]
        })
        if (!data.ok) {
          setErrors([data.statusText])
          setLoading(false)
          return
        }
        const dataJson = await data.json() || []
        yextReviews.reviews.set(String(eisAccount), dataJson)
      }
      setLoading(false)
      const clientReviews = yextReviews.reviews.get(String(eisAccount)) || []
      if (clientReviews) {
        setReviews(clientReviews)
      }
    }
    fetchData()
  }, [eisAccount])

  const handleReviewClick = (id: string) => {
    const review = reviews.find((review) => id === review.id)
    const newFields = mapReviewToFields(review)
    onChange(newFields)
  }

  if (loading) {
    return (
      <div className="reviews-picker-loading">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }

  if (errors.length || !reviews.length) {
    return (
      <div className="reviews-picker-error">
        <p>No reviews found for client {eisAccount}</p>
      </div>
    )
  }

  return (
    <>
      <div className="reviews-picker">
        <Grid.Container gap={2}>
          {reviews.map((review) => (
            <Grid sm={12} md={6} lg={4} xl={3} key={review.id}>
              <Review
                {...review}
                handleReviewClick={handleReviewClick}
              />
            </Grid>
          ))}
        </Grid.Container>
      </div>
    </>
  )
}

export default ReviewPicker
