import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Wildcard/presets'

export default function Wildcard(props) {
  const {
    richtext,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' stack'}
      name="wildcard"
      colorSchemeId={color_scheme}
      section_background={section_background}
    >
        <div className="section-container">
            <div className="section-content">
                <div className="row expanded">
                    <div className="column">
                        <div className="section-richtext paragraph">
                            <RichtextWithVariables richtext={richtext} cms={cms} name={'richtext'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Section>
  )
}

Wildcard.StyleCore = styleCore
Wildcard.presets = presets
