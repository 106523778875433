import {MenuContainer} from '../menu/MenuEditor'
import SortableList from '../../SortableList'
import {MENU_EDITOR_GROUP_NAME, MenuFilterProps} from '../../utils/types'
import Toolbar from './Toolbar'
import styled from 'styled-components'


const Pane = styled.div<{ isDisplayed: boolean }>`
  ${(props) => (props.isDisplayed ? 'display: block' : 'display: none')}
`

const MenuFilter = ({isLocked, sortable_items, onMenuChange, parentInfo}: MenuFilterProps) => {

  // We use CSS to display/hide component because of
  // a React Dnd bug (conflict with click and drag leads to bug when unmounting )
  return (
    <div
      style={{
        borderLeft: '1px solid #979797',
      }}
    >
      <Pane isDisplayed={true}>
        <Toolbar
          onMenuChange={onMenuChange}
        />
        <div style={{backgroundColor: '#d8e1ef', height: '100%'}}>
          {sortable_items.length > 0 ? (
            <MenuContainer id="menu-container-2">
              <SortableList
                acceptDrops={false}
                isLocked={isLocked}
                parentInfo={parentInfo}
                onMenuChange={onMenuChange}
                sortable_items={sortable_items}
                group_name={MENU_EDITOR_GROUP_NAME}
                behaviour='copy'
              />
            </MenuContainer>
          ) : (
            <span>No items match your search</span>
          )}
        </div>
      </Pane>
    </div>
  )
}

export default MenuFilter
